@charset "UTF-8";

// common
.skip, .hide, .sr-only  {
    @include skip;
}

.cfix {
    display: block;
    @include clearfix;
}

// ie7 이하
* html .cfix {
    height: 1%;
}

// ie button click affect remove
.in_btn {
    position: relative;
}





@if ($pageStatus == 'sub') {

    .cover_txt {
        overflow: hidden;
        font-size: 0;
        text-indent: -9999px;
        line-height: 0;
    }

}
