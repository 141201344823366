@charset "UTF-8";

@import "../svg/sp_common.scss";

.c-pagination-slider {
    $c-pagination-slider: &;

    &--hide {
        #{$c-pagination-slider}__button {
            button {
                top: 0;
                width: 61px;
                height: 53px;
                margin-top: 0;
                background: transparent;
                box-shadow: none;
                border-radius: 0;

                &::before {
                    top: 50%;
                    z-index: 2;
                }
                &::after {
                    width: 61px;
                    height: 100%;
                    z-index: 1;
                }
                &:disabled {
                    display: none;
                }
                &:hover {
                    box-shadow: none;
                }
            }
        }
        #{$c-pagination-slider}__previous {
            left: 0;

            &::before {
                @include get-sprite-common("ic-arrow-b-left-nor", 8);
                left: 15px;
                margin: -6px 0 0 0;
            }
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 40.16%);
                content: "";
            }
            &:hover {
                &::before {
                    @include get-sprite-common("ic-arrow-b-left-on", 8);
                }
            }
        }
        #{$c-pagination-slider}__next {
            right: 0;

            &::before {
                @include get-sprite-common("ic-arrow-b-right-nor", 8);
                left: auto;
                right: 15px;
                margin: -6px 0 0 0;
            }
            &::after {
                position: absolute;
                top: 0;
                left: 0;
                background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 40.16%);
                content: "";
            }
            &:hover {
                &::before {
                    @include get-sprite-common("ic-arrow-b-right-on", 8);
                }
            }
        }
    }

    &__page {
        position: absolute;
        top: -48px;
        right: 0;
        color: $color-gray-09;
        font-family: $font-family-lato;
        font-size: 16px;
        line-height: 32px;
        vertical-align: middle;

        strong {
            color: $color-gray-02;
            font-size: 15px;
            font-weight: bold;

            &:after {
                display: inline-block;
                margin: 0 5px;
                color: $color-gray-09;
                font-family: $font-family;
                font-weight: normal;
                content: "/";
            }
        }
    }
    &__button {
        button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            width: 50px;
            height: 50px;
            margin-top: -25px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            background: $color-gray-14;
            z-index: 10;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -9px 0 0 -5px;
                content: "";
            }
            &:disabled {
                display: none;
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }
    &__previous {
        left: -25px;

        &::before {
            @include get-sprite-common("ic-arrow-b-left-nor", 10);
        }
        &:hover {
            &::before {
                @include get-sprite-common("ic-arrow-b-left-on", 10);
            }
        }
    }
    &__next {
        right: -25px;

        &::before {
            @include get-sprite-common("ic-arrow-b-right-nor", 10);
        }
        &:hover {
            &::before {
                @include get-sprite-common("ic-arrow-b-right-on", 10);
            }
        }
    }
}

.c-pagination-indicator {
    position: relative;
    height: 40px;
    box-sizing: border-box;
    text-align: center;

    &__inner {
        display: inline-block;
    }

    &__container {
        display: inline-block;
        button {
            overflow: hidden;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.1);
            line-height: 120px;
            vertical-align: top;
        }
    }

    &__item {
        display: inline-block;
        margin: 16px 3px 0 2px;
        vertical-align: middle;

        &.active {
            margin-top: 14px;

            button {
                width: 10px;
                height: 10px;
                background-color: #111;
            }
        }
    }

    &__button {
        position: relative;

        button {
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 40px;
            height: 40px;
            margin-top: -40px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            background: $color-gray-14;
            z-index: 10;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -6px 0 0 -3px;
                transform: translateZ(0);
                content: "";
            }

            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }

    &__previous {
        position: absolute;
        left: -56px;

        &::before {
            @include get-sprite-common("ic_arrow_left", 8);
        }
        &:hover {
            &::before {
                @include get-sprite-common("ic_arrow_left_on", 8);
            }
        }

        &:disabled {
            &::before {
                @include get-sprite-common("ic_arrow_left_dim", 8);
            }
        }
    }

    &__next {
        position: absolute;
        right: -56px;

        &::before {
            @include get-sprite-common("ic_arrow_right", 8);
            content: "";
        }

        &:hover {
            &::before {
                @include get-sprite-common("ic_arrow_right_on", 8);
            }
        }

        &:disabled {
            &::before {
                @include get-sprite-common("ic_arrow_right_dim", 8);
            }
        }
    }
}

.c-pagination-billboard {
    position: absolute;
    right: 0;
    bottom: 25px;
    height: 45px;
    border-radius: 23px;
    background: rgba(0, 0, 0, 0.2);

    button {
        float: left;
        position: relative;
        width: 46px;
        height: 45px;
        overflow: hidden;
        line-height: 300px;

        &:before {
            position: absolute;
            top: 11px;
            content: "";
        }
    }

    &__previous {
        &:before {
            left: 16px;
            @include get-sprite-common("ic_billborad_previous", 24);
        }
    }

    &__next {
        &:before {
            right: 16px;
            @include get-sprite-common("ic_billborad_next", 24);
        }
    }
}

.c-pagination-indicate {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 25px;
    right: 102px;
    width: 131px;
    height: 45px;
    line-height: 45px;
    padding: 0 0 0 21px;
    box-sizing: border-box;
    border-radius: 23px;
    background: rgba(0, 0, 0, 0.2);

    &__page {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 17px;
        color: $color-gray-14;
        font-family: $font-family-lato;
        text-align: center;
        margin-right: 15px;
    }

    &__current {
        font-weight: bold;
    }

    &__line {
        margin: 0 7px;
        opacity: 0.6;
    }

    &__total {
        opacity: 0.6;
    }

    &__more {
        position: relative;
        width: 24px;
        min-width: 24px;
        height: 45px;
        margin-right: 10px;
        overflow: hidden;
        line-height: 300px;

        &::before {
            position: absolute;
            left: 0px;
            top: 11px;
            @include get-sprite-common("ic_billborad_more", 24);
            content: "";
        }
    }
}

.c-pagination-controll {
    position: absolute;
    right: 243px;
    bottom: 25px;
    border-radius: 23px;
    background: rgba(0, 0, 0, 0.2);

    button {
        position: relative;
        width: 45px;
        height: 45px;
        overflow: hidden;
        line-height: 300px;

        &:before {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -12px 0 0 -12px;
            content: "";
        }
    }

    &__stop {
        &:before {
            @include get-sprite-common("ic_billborad_stop", 24);
        }
    }

    &__play {
        &:before {
            @include get-sprite-common("ic_play", 24);
        }
    }
}

.c-pagination-button {
    position: absolute;
    right: 102px;
    bottom: 25px;
    height: 45px;
    display: flex;

    .c-pagination-indicate {
        position: static;
        width: auto;
        min-width: 101px;

        &__page {
            &:last-child {
                margin-right: 21px;
            }
        }
    }

    .c-pagination-controll {
        position: static;
        margin-right: 10px;
    }
}
