[data-type="PC_GridList_Partners"] {
    max-width: 1240px;
    min-height: 120px;
    margin: 0 auto;

    .c-list {
        @include clearfix;

        &__container {
            @include flex-list-gap-container(26px);
            flex-wrap: wrap;
            margin-top: -10px;
        }

        &__item {
            @include flex-list-gap-item(12.5%, 26px);
            width: 132px;
            height: 50px;
            padding-top: 10px;
        }
    }
}
