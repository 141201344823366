@charset "UTF-8";

@import "../../common/sprites/_sp_myhistory_2x";
@import "../../eui/component/c-ad-box";

.b_myhistory {
    width: 1240px;
    margin: 0 auto 30px;
    .c-starrate {
        margin-top: 6px;
    }

    .c-card-item__counsel {
        margin-top: 4px;
    }
}
.c_myhistory_title {
    @include clearfix;
    margin: 0 0 17px 0;

    .title {
        float: left;
        font-size: 20px;
        font-family: $font-family;
        color: #000;
    }
    .text {
        float: left;
        margin: 5px 0 0 10px;
        font-size: 14px;
        color: #999;
        font-family: $font-family;
    }
}
.c_myhistory_tab {
    ul {
        @include clearfix;
        border-left: 1px solid #eee;
        border-right: 1px solid #eee;
    }
    li {
        float: left;
        width: 413px;

        &:first-child {
            width: 412px;

            .tab {
                border-left: none;
            }
        }
        .tab {
            display: block;
            position: relative;
            height: 59px;
            background: #fafafa;
            font-size: 17px;
            line-height: 59px;
            text-align: center;
            border-left: 1px solid #eee;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee;
            font-family: $font-family;

            &:hover {
                text-decoration: none;
            }
        }
        span.tab {
            color: #999;
        }
        &.active {
            .tab {
                color: $color-11st-red;
                font-weight: bold;
                background: #fff;
                border-bottom: none;

                &:before {
                    position: absolute;
                    left: -1px;
                    right: -1px;
                    top: -1px;
                    height: 2px;
                    background: $color-11st-red;
                    z-index: 5;
                    content: "";
                }
            }
        }
    }
}
.c_myhistory_tab_content {
    @include clearfix;
    display: flex;
    border: 1px solid #eee;
    border-top: none;
    padding: 21px 0 27px 0;
    box-sizing: border-box;
    background: #fff;
}
.c_myhistory_product {
    position: relative;
    float: left;
    width: 270px;
    height: 316px;
    margin-top: -3px;
    text-align: center;

    &:after {
        position: absolute;
        right: 0;
        top: 5px;
        width: 1px;
        height: calc(100% - 5px);
        background: #eee;
        content: "";
    }
    .c_pagination {
        margin: 0 0 10px 0;
    }
    .c-card-item {
        width: 158px;
        height: 272px;
        margin: 0 0 0 55px;
        text-align: left;

        .c-card-item__thumb {
            position: relative;
            margin: 0 0 13px 0;
            width: 158px;
            height: 158px;

            .division {
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 1;
                padding: 0 5px;
                border: 1px solid $color-11st-red;
                color: $color-11st-red;
                font-size: 11px;
                line-height: 20px;
                font-family: $font-family;
                background: #fff;
            }
            .delete {
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                overflow: hidden;
                line-height: 200px;
                background: #111;
                z-index: 5;
                @include ico-cancel(9px, 1px, #fff, 7px);

                &:focus {
                    opacity: 1;
                }
            }
            a {
                &:focus + .delete {
                    opacity: 1;
                }
            }
            &:hover {
                a {
                    position: relative;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        border: 1px solid #111;
                        z-index: 5;
                        content: "";
                    }
                    & + .delete {
                        opacity: 1;
                    }
                }
            }
        }
        .c-card-item__name {
            overflow: hidden;
            height: 44px;
            margin: 0 0 4px 0;
            font-size: 15px;
            line-height: 22px;
            color: #111;
        }
        .c-card-item__price {
            font-size: 12px;
            line-height: 22px;
            font-family: $font-family;

            > .value {
                font-size: 16px;
                line-height: 22px;
            }
        }
        &__price-info {
            @include clearfix();
            margin-top: 4px;
        }
        &__rate {
            margin-right: 4px;
            font-size: 16px;
            line-height: 22px;

            .unit {
                font-size: 14px;
            }
        }
    }
}
.c_myhistory_pagination {
    position: relative;
    display: inline-block;
    padding: 0 38px;
    box-sizing: border-box;
    margin: 0px 0 16px 0;

    .page {
        display: inline-block;
        font-size: 15px;
        font-family: $font-family-number-lato;
        line-height: 28px;
        color: #717171;

        strong {
            color: #111;

            &:after {
                display: inline-block;
                margin: 0 6px;
                color: #717171;
                content: "/";
            }
        }
    }
    .navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 0;
            width: 28px;
            height: 28px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
            background: #fff;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                content: "";
            }
            &.previous {
                left: 0;

                &::before {
                    margin: -6px 0 0 -5px;
                    @include sprite-retina($sp_myhistory_2x_arrow_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_myhistory_2x_arrow_left_on);
                    }
                }
            }
            &.next {
                right: 0;

                &::before {
                    margin: -6px 0 0 -3px;
                    @include sprite-retina($sp_myhistory_2x_arrow_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_gnb_2x_arrow_right_on);
                    }
                }
            }
            &:disabled {
                &.previous {
                    &::before {
                        @include sprite-retina($sp_myhistory_2x_arrow_left_dim);
                    }
                }
                &.next {
                    &::before {
                        @include sprite-retina($sp_myhistory_2x_arrow_right_dim);
                    }
                }
                &:hover {
                    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
                }
            }
            &:hover {
                box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            }
        }
    }
}
.c_myhistory_recommend {
    position: relative;
    float: left;
    width: 890px;
    margin: 0 0 0 39px;

    .subject {
        margin: 0 0 14px 0;
        font-size: 16px;
        font-weight: bold;
        font-family: $font-family;
        color: #333;
    }
}
.c_myhistory_list {
    position: relative;
    height: 283px;

    ul {
        @include clearfix;
        height: 283px;
        display: flex;
    }
    li {
        float: left;
        margin: 0 0 0 25px;

        &:first-child {
            margin-left: 0;
        }
    }
    .c-card-item__info {
        padding: 15px;
    }
    .c-card-item {
        width: 158px;
        height: 100%;
        border-radius: 4px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.28), 0 2px 10px -2px rgba(0, 0, 0, 0.06);
        background: #ffffff;

        .c-ad-box {
            @include set-c-ad-box(type3);
            position: absolute;
            top: calc(158px - 16px);
            left: 0;
            z-index: 3;

            .c-ad-layer {
                top: 16px;
                left: 0;
                width: 274px;

                &__body {
                    font-size: 15px;
                }

                &__close {
                    line-height: 200px;
                }
            }
        }

        .c-card-item__thumb {
            width: 158px;
            height: 158px;
        }
        .c-card-item__name {
            overflow: hidden;
            height: 44px;
            margin: 0 0 4px 0;
            font-size: 15px;
            line-height: 22px;
            color: #111;
            font-family: $font-family;
        }
        .c-card-item__price {
            font-size: 12px;
            line-height: 22px;
            font-family: $font-family;

            > .value {
                font-size: 16px;
                line-height: 22px;
            }
        }
        &__price-info {
            @include clearfix();
            margin-top: 4px;
        }
        &__rate {
            margin-right: 4px;
            font-size: 16px;
            line-height: 22px;

            .unit {
                font-size: 14px;
            }
        }
        .c-card-item__counsel {
            font-size: 15px;
            line-height: 18px;
        }
    }
    .pagination {
        position: absolute;
        top: -35px;
        right: 0;
        font-size: 15px;
        font-family: $font-family-number-lato;
        color: #666;

        strong {
            color: #111;

            &:after {
                display: inline-block;
                margin: 0 5px;
                color: #666;
                content: "/";
            }
        }
    }
    .navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 84px;
            width: 50px;
            height: 50px;
            line-height: 300px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
            background: #fff;
            z-index: 10;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -9px 0 0 -5px;
                content: "";
            }
            &.previous {
                left: -25px;

                &::before {
                    @include sprite-retina($sp_myhistory_2x_arrow2_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_myhistory_2x_arrow2_left_on);
                    }
                }
            }
            &.next {
                right: -25px;

                &::before {
                    @include sprite-retina($sp_myhistory_2x_arrow2_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_myhistory_2x_arrow2_right_on);
                    }
                }
            }
            &:disabled {
                display: none;
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }
}
.c_myhistory_keyword {
    @include clearfix;
    margin: 16px 0 30px 0;
    padding: 14px 20px 13px 20px;
    border: 1px solid #eee;
    background: #f6fbff;

    .subject {
        float: left;
        font-size: 14px;
        color: #111;
        line-height: 21px;
        font-family: $font-family;
    }
    ul {
        float: left;
        width: 1060px;
        margin: 0 0 0 20px;
        vertical-align: top;

        li {
            float: left;
            margin: 0 10px 0 0;
        }
        a {
            color: #666;
            font-size: 14px;
            line-height: 21px;
            font-family: $font-family;
        }
    }
    .delete {
        display: inline-block;
        position: relative;
        margin: 1px 0 0 0;
        @include ico-cancel(10px, 1px, #999, 5px);
        vertical-align: top;

        &:focus {
            opacity: 1;
        }
    }
}
.c_myhistory_no_data {
    height: 324px;
    padding: 143px 0 0 0;
    box-sizing: border-box;
    text-align: center;
    font-size: 15px;
    font-family: $font-family;
    color: #111;
}
.c_myhistory_loading {
    position: relative;
    height: 324px;

    .loading {
        overflow: hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        margin: -30px 0 0 -30px;
        background-color: #fff;
        border: solid 1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        line-height: 200px;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 28px;
            height: 28px;
            margin: -14px 0 0 -14px;
            background: url("/img/common/v2/loading_04.gif") no-repeat 0 0;
            background-size: 100%;
            content: "";
        }
    }
}
