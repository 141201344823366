@charset "UTF-8";

@mixin c-p-info($fontSize, $fontColor, $lineHeight) {
    font-size: $fontSize;
    color: $fontColor;
    line-height: $lineHeight;
}

@mixin c-p-info-list($gap: 4px, $bulletColor: #666) {
    &.c-p-info__list--bullet {
        .c-p-info__list-item {
            &::before {
                background-color: $bulletColor;
            }
        }
    }
    .c-p-info__list-item {
        margin-top: $gap;

        &:first-child {
            margin-top: 0;
        }
    }
}

.c-p-info {
    //폰트사이즈, 폰트칼라, lineheight
    //@include c-p-info(13px, $color-grayscale-04, $lineHeight);

    &__title {
        color: $color-grayscale-02;
        font-size: inherit;
        font-weight: bold;

        &:not(:first-child) {
            margin-top: 20px;
        }
    }

    &__sentence {
        position: relative;
        margin-top: 4px;
        color: inherit;
        font-size: inherit;
        line-height: inherit;
        text-align: inherit;

        &:first-of-type {
            margin-top: 10px;
        }

        &::before {
            display: none;
            position: absolute;
            top: 9px;
            left: 5px;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background-color: #666;
            content: "";
        }

        &--bullet {
            padding-left: 13px;

            &::before {
                display: block;
            }

            &-emphasis {
                padding-left: 13px;
                color: $color-11st-red;

                &::before {
                    display: block;
                    background-color: $color-11st-red;
                }
            }

            &-highlight {
                padding-left: 13px;
                color: $color-11st-blue;

                &::before {
                    display: block;
                    background-color: $color-11st-blue;
                }
            }
        }
    }

    &__list {
        margin-top: 10px;
        color: inherit;
        font-size: inherit;
        line-height: inherit;

        &:first-child {
            margin-top: 0;
        }

        @include c-p-info-list($gap: 4px, $bulletColor: #666);

        &-item {
            position: relative;
            color: inherit;
            font-size: inherit;
            line-height: inherit;

            &--emphasis {
                color: $color-11st-red;

                &::before {
                    background-color: $color-11st-red;
                }
            }

            &--highlight {
                color: $color-11st-blue;

                &::before {
                    background-color: $color-11st-blue;
                }
            }
        }

        &--bullet {
            .c-p-info__list-item {
                padding-left: 13px;

                &::before {
                    position: absolute;
                    top: 9px;
                    left: 5px;
                    display: block;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    content: "";
                }

                &--emphasis {
                    color: $color-11st-red;

                    &::before {
                        background-color: $color-11st-red;
                    }
                }

                &--highlight {
                    color: $color-11st-blue;

                    &::before {
                        background-color: $color-11st-blue;
                    }
                }
            }
        }
    }

    &__text {
        &--emphasis {
            color: $color-11st-red;
        }
        &--highlight {
            color: $color-11st-blue;
        }
    }

    &--center {
        text-align: center;
    }
}
