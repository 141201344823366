@charset "UTF-8";

.c_input_item {
    display: inline-block;
    height: 48px;
    padding: 0 16px;
    line-height: 46px;
    border: 1px solid #eee;
    box-sizing: border-box;
    font-size: 15px;
    color: #111;
    letter-spacing: 0;

    &::placeholder {
        color: #999;
    }
}
.c_input_item_help {
    display: inline-block;
}
.c_input_item_at {
    display: inline-block;
    margin: 0 8px;
}
.c_input_item_message {
    color: $color-11st-red;
}

// checkbox
.c_checkbox {
    position: relative;
    display: inline-block;

    > span:nth-of-type(1),
    > .lab:nth-of-type(1) {
        position: relative;
        display: inline-block;
        height: 22px;
        padding-left: 32px;
        vertical-align: middle;
        line-height: 22px;
        font-size: 15px;
        color: #666;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            background-color: #fff;
            border-radius: 100%;
        }

        &:after {
            content: "";
            position: absolute;
            top: 6px;
            left: 5px;
            display: block;
            width: 10px;
            height: 6px;
            border: 1px solid #ddd;
            border-width: 0 0 1px 1px;
            transform: rotate(-45deg);
        }
    }
    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }
    input:checked {
        & + span:before,
        & + .lab:before {
            border: 0;
            background: $color-11st-red;
        }
        & + span:after,
        & + .lab:after {
            border-color: #fff;
        }
    }
    input:focus-visible {
        & + span:before,
        & + .lab:before {
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        }
    }
    input:disabled {
        & + span:before,
        & + .lab:before {
            border: 0;
            background-color: #eee;
        }
        & + span:after,
        & + .lab:after {
            border-color: #fff;
        }
    }
    input[type="checkbox"]:focus {
        & + span {
            &:before {
                box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            }
        }
    }

    &--simple {
        > .lab:nth-of-type(1) {
            &::before {
                display: none;
            }
            &::after {
                border-color: $color-gray-09;
            }
        }

        input:checked {
            & + span:after,
            & + .lab:after {
                border-color: $color-11st-red;
            }
        }
    }
}

// c_radiobox
.c_radiobox {
    position: relative;
    display: inline-block;

    > span:nth-of-type(1) {
        position: relative;
        display: inline-block;
        height: 22px;
        padding-left: 32px;
        vertical-align: middle;
        line-height: 22px;
        font-size: 15px;
        color: #666;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 24px;
            height: 24px;
            box-sizing: border-box;
            border: 1px solid #ccc;
            background-color: #fff;
            background-image: radial-gradient(closest-side, #ddd 47%, #fff 52%);
            border-radius: 100%;
        }
    }
    input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;
    }
    input:checked {
        & + span:before {
            border-color: $color-11st-red;
            background-image: radial-gradient(closest-side, #fff 47%, $color-11st-red 52%);
        }
    }
    input:focus-visible {
        & + span:before {
            box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
        }
    }
    input:disabled {
        & + span:before {
            border-color: #eee;
            background-image: radial-gradient(closest-side, #fff 47%, #eee 52%);
        }
        & + span {
            color: #ccc;
        }
    }
    input[type="radio"]:focus {
        & + span {
            &:before {
                box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
            }
        }
    }
}
