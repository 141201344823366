.c-header {
    &--shooting {
        --header-key-color: #ff0038;
        --header-sub-color: #f70d8b;
        --header-font-color: #fff;
    }

    min-width: 1240px;
    height: 70px;
    background: linear-gradient(90deg, var(--header-key-color) 0%, var(--header-sub-color) 100%);

    &__inner {
        position: relative;
        width: 1240px;
        margin: 0 auto;
    }
}

.c-header-category {
    position: relative;
    width: 224px;

    &--active {
        &:before {
            position: absolute;
            right: 0;
            top: 0;
            width: 3000px;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            content: "";
        }

        .c-header-category__button {
            &:after {
                transform: rotate(180deg);
            }
        }

        .c-header-category__box {
            display: flex;
        }
    }

    &__button {
        position: relative;
        z-index: 10;
        width: 224px;
        height: 70px;
        padding: 0 40px 0 0;
        color: var(--header-font-color);
        text-align: left;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        font-size: 17px;
        font-weight: bold;

        &:after {
            content: "";
            position: absolute;
            right: 17px;
            top: 33px;
            width: 0;
            border-style: solid;
            border-width: 6px 5px 0;
            border-color: var(--header-font-color);
            border-right-color: transparent;
            border-left-color: transparent;
        }
    }

    &__box {
        display: none;
        position: absolute;
        top: 70px;
        left: 0;
        z-index: 20;
        background: $color-gray-14;
        border-radius: 0 0 4px 4px;
        box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
    }

    &__depth1 {
        position: relative;
        width: 448px;
        max-height: calc(100vh - 316px); //11gnb 120, 유틸리티 66, 슈팅배송 70, 하단갭 60px
        box-sizing: border-box;
        //box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.08), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);

        & > .c-header-list {
            border-radius: 0px 0px 4px 4px;
            border: 1px solid var(--header-key-color);
            border-top: none;
            background-color: $color-gray-14;
            box-sizing: border-box;
        }
    }

    &__depth2 {
        display: none;
        position: absolute;
        top: 0;
        left: 224px;
        bottom: 0;
        width: 224px;
        background-color: $color-gray-14;
        border-radius: 0 0 4px 0;
        box-sizing: border-box;

        &--active {
            display: block;
        }

        .c-header-list {
            width: 224px;
            box-sizing: border-box;

            &__link {
                position: relative;
                display: block;
                padding: 14px 20px;
                color: $color-gray-02;
                font-family: $font-family-lato;
                font-size: 15px;
                line-height: 15px;
                word-wrap: break-word;

                &:hover {
                    color: var(--header-key-color);
                    font-weight: 700;
                    text-decoration: none;
                }
            }
        }
    }
}

.c-header-list {
    overflow-y: auto;
    overscroll-behavior: contain;
    max-height: 100%;
    width: 224px;
    border-top: none;
    box-sizing: border-box;

    &__item {
        &:hover,
        &--active {
            & > .c-header-list__link {
                color: var(--header-key-color);
                font-weight: 900;
                text-decoration: none;

                &::after {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: var(--header-key-color);
                    opacity: 0.08;
                    content: "";
                }
            }
        }

        &--active {
            & > .c-header-category__depth2 {
                display: block;
            }
        }
    }

    &__link {
        position: relative;
        display: block;
        padding: 14px 20px;
        color: $color-gray-02;
        font-family: $font-family-lato;
        font-size: 15px;
        line-height: 15px;
        word-wrap: break-word;
    }
}

.c-header-service {
    position: absolute;
    left: 228px;
    top: 0;
    height: 70px;

    &__list {
        display: flex;
        height: 100%;
        align-items: center;
    }
    &__item {
        position: relative;
        padding: 0 20px;
        line-height: 16px;

        &:not(:first-child) {
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                width: 1px;
                height: 100%;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
                content: "";
            }
        }
    }
    &__link {
        color: var(--header-font-color);
        font-size: 16px;
        font-weight: 700;
    }
}

.c-header-util {
    position: absolute;
    right: 0;
    top: 0;

    &__text {
        padding: 27px 0;
        color: var(--header-font-color);
        text-align: right;
        font-size: 16px;
        line-height: 16px;
    }
}
