// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sp_close_btn_ly_close: (0px, 0px, 0px, 0px, 34px, 34px, 34px, 34px, '/img/common/sprites/sp_close.png', 'sp_close_btn_ly_close', );
$spritesheet_sprites: ($sp_close_btn_ly_close, );
$spritesheet: (34px, 34px, '/img/common/sprites/sp_close.png', $spritesheet_sprites, );


