[data-type="PC_Banner_Promotion"] {
    height: 220px;
    .c-banner {
        height: 220px;

        &__link {
            display: block;
            height: inherit;
        }

        &__img {
            display: block;
            width: 1240px;
            height: inherit;
            margin: 0 auto;
        }
    }
}
