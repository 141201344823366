[data-type="PC_Tab_ImgText"] {
    width: 1240px;
    height: 90px;
    margin: 0 auto;

    .c-magnet-multiple--fixed {
        position: fixed;
        right: 0;
        left: 0;
        top: $pui-fixed-top;
        z-index: 100;
        width: 1240px;
        margin: 0 auto;
    }

    .c-list {
        position: relative;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset;
        background: #fff;

        &__container {
            display: flex;
        }

        &__item {
            min-width: 103.3px;
            height: 90px;
            box-sizing: border-box;
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }

    .c-icontext {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 44px;

        &__icon {
            position: absolute;
            top: 11px;
            width: 40px;
            height: 40px;

            img {
                width: 40px;
                height: 40px;
            }
        }

        &__text {
            position: relative;
            display: inline-block;
            max-width: 100%;
            height: 46px;
            padding: 0 3px;
            margin-top: 0;
            color: $color-gray-04;
            font-size: 14px;
            line-height: 46px;
            overflow: hidden;
            box-sizing: border-box;
            word-break: break-all;
        }
    }

    %c-icontext {
        .c-icontext__text {
            color: $color-11st-red;
            font-weight: 700;

            &::after {
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-11st-red;
                content: "";
            }
        }
    }

    .c-list__item {
        &.active {
            .c-icontext {
                @extend %c-icontext;
            }
        }

        .c-icontext {
            &:hover,
            &:focus {
                @extend %c-icontext;
            }
        }
    }
}
