[data-type="PC_Banner_Col2"] {
    height: 348px;

    .c-list {
        width: 1240px;
        margin: 0 auto;

        &__container {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 30px);
            margin-left: -15px;
        }
        &__item {
            flex-grow: 1;
            padding: 0 15px;
            box-sizing: border-box;
        }
    }

    .c-banner {
        border-radius: 4px;

        &__link {
            display: block;
            height: inherit;
        }

        &__img {
            display: block;
            width: 605px;
            height: inherit;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 4px;
        }
    }

    .c-headline {
        margin-bottom: 18px;

        &__text {
            flex: 0 1 auto;
            min-width: 0;
        }

        &__title {
            color: $color-gray-02;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }
    }
}
