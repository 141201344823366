@charset "UTF-8";

.ie-end-support {
    background: #0b2a98;
    width: 100%;
    height: 70px;

    &__info {
        background: url("/img/common/ie_end_support_2x.png") no-repeat 50%;
        background-size: 100% 100%;
        max-width: 1240px;
        height: 100%;
        margin: 0 auto;
    }

    &__link {
        display: block;
        width: 100%;
        height: 100%;
    }
}
