@import "../../common/mixins";
@import "../../common/mixins/flag";
@import "../../luxury/components/flag";
@import "../svg/sp_common.scss";

.c-flag {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    @include clearfix();

    &.c-flag--full {
        display: block;
        overflow: visible;
    }

    dt {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
        font-size: 6px;
        line-height: 1;
        white-space: nowrap;
    }

    dd {
        float: left;
        background-color: #fff;
    }

    @include icon-flag-size($height: 22px, $padding: 2px 4px 1px, $font-size: 12px, $gap: 2px);

    .c-flag__item {
        @include icon-flag();
        font-family: $font-family;

        > strong {
            line-height: 1.45;
        }
    }

    .c-flag__time {
        @include icon-flag();
        margin-top: 1px;
        font-family: $font-family-lato;
        line-height: 21px;
    }

    .c-flag__item--11festa {
        color: #fff;
        border: 1px solid transparent;
        border-color: #f44654;
        background-color: #f44654;
    }

    .c-flag__item--choice {
        color: $color-grayscale-13;
        border: 1px solid transparent;
        border-color: #232f3e;
        background: #232f3e;
    }

    .c-flag__item--gradient-red {
        color: $color-gray-14;
        border: 0;
        background: linear-gradient(114.84deg, #ff465d 31.64%, $color-11st-gradient-pink 100%);
        line-height: 16px;
    }

    .c-flag__item--ooah {
        position: relative;
        width: 37px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_ooah", 37);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }

    .c-flag__item--freshtable {
        position: relative;
        width: 51px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_freshtable", 51);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }

    .c-flag__item--shockingdeal {
        position: relative;
        width: 44px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_shocking", 44);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
    .c-flag__item--timedeal {
        position: relative;
        width: 57px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_timedeal", 57);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
    .c-flag__item--ootd {
        position: relative;
        width: 46px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_ootd", 47);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
    .c-flag__item--mart-plus {
        position: relative;
        width: 60px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("mart_plus", 60);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
    .c-flag__item--ssg-emart {
        position: relative;
        width: 66px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_ssg_emart", 66);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
    .c-flag__item--ssg-dawn {
        position: relative;
        width: 66px;
        padding: 0;
        border: none;
        background-color: transparent;
        line-height: 200px;

        &::before {
            @include get-sprite-common("bi_ssg_dawn", 66);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            content: "";
        }
    }
}

.c-flag-box {
    overflow: hidden;
    max-height: 49px;
    @include clearfix();
    dt {
        @include sr-only;
    }

    dd {
        display: inline;
    }

    .c-time {
        margin: 0 4px 4px 0;
    }

    .c-flag {
        overflow: initial;
        height: auto;

        .c-flag__item {
            float: left;
            margin: 0 4px 4px 0;

            &:last-child {
                margin-right: 0;
            }
        }

        .c-flag__time {
            float: left;
            margin: 1px 4px 4px 1px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

//높이 18 셋팅 - 전시 추후 변경 예정이라고함.
%style-c-flag-box {
    .c-flag-box {
        max-height: 44px;
        margin-bottom: 2px;

        &--luxury {
            margin-top: 6px;
            margin-bottom: 0;
        }

        .c-flag {
            &__item {
                height: 18px;
                padding: 0 5px;
                font-size: 11px;

                > strong {
                    line-height: 1.5;
                }
            }

            &__time {
                margin-top: 0;
                line-height: 18px;

                em {
                    font-weight: 700;
                }

                &--gradient-purple {
                    height: 18px;
                    padding: 0 6px 0 3px;
                    margin-top: 0;
                    color: $color-gray-14;
                    border: 0;
                    background: linear-gradient(90deg, rgba(120, 14, 255, 0.85) 0%, rgba(44, 89, 255, 0.85) 100%);
                    line-height: 18px;

                    &::before {
                        @include get-sprite-common("ic_time_14", 14);
                        display: inline-block;
                        margin: -1px 2px 0 0;
                        vertical-align: middle;
                        content: "";
                    }

                    [role="timer"] {
                        font-family: $font-family-lato;
                        font-size: 11px;
                        font-weight: 700;
                    }
                }
            }

            &__item--gradient-red {
                strong {
                    line-height: 18px;
                }
            }
        }

        .c-time-flag {
            .c-time {
                height: 18px;
                padding: 0 5px;
                text-align: center;
                line-height: 16px;

                &::before {
                    @include get-sprite-common("ic_time_14", 14);
                    display: inline-block;
                    margin: 0 2px 0 0;
                    vertical-align: middle;
                    content: "";
                }

                [role="timer"] {
                    font-family: $font-family-lato;
                    font-size: 11px;
                    font-weight: 700;
                }
            }
        }
    }
}
