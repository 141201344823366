$sprite-meta-common: (
    "name": 'common', // sprite 이름
    "width": 395, // sprite 판 너비
    "height": 390, // sprite 판 높이
);

$sprite-map-common: (
  'bi_11st': (
      "width": 94,
      "height": 40,
      "bg-top": -163 - (48 - 40)/2,
      "bg-left": 0 - (102 - 94)/2,
  ),
  'bi_amazon': (
      "width": 112,
      "height": 34,
      "bg-top": 0 - (42 - 34)/2,
      "bg-left": 0 - (120 - 112)/2,
  ),
  'bi_amazon_allwhite': (
      "width": 112,
      "height": 34,
      "bg-top": -42 - (42 - 34)/2,
      "bg-left": 0 - (120 - 112)/2,
  ),
  'bi_amazon_black': (
      "width": 112,
      "height": 34,
      "bg-top": 0 - (42 - 34)/2,
      "bg-left": -120 - (120 - 112)/2,
  ),
  'bi_amazon_icon': (
      "width": 14,
      "height": 14,
      "bg-top": -239 - (22 - 14)/2,
      "bg-left": -150 - (22 - 14)/2,
  ),
  'bi_amazon_white': (
      "width": 112,
      "height": 34,
      "bg-top": -42 - (42 - 34)/2,
      "bg-left": -120 - (120 - 112)/2,
  ),
  'bi_applecare': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -156 - (32 - 24)/2,
  ),
  'bi_emartmall': (
      "width": 84,
      "height": 20,
      "bg-top": -163 - (28 - 20)/2,
      "bg-left": -102 - (92 - 84)/2,
  ),
  'bi_freshtable': (
      "width": 52,
      "height": 12,
      "bg-top": -96 - (20 - 12)/2,
      "bg-left": -328 - (60 - 52)/2,
  ),
  'bi_luxury': (
      "width": 66,
      "height": 11,
      "bg-top": -80 - (19 - 11)/2,
      "bg-left": -240 - (74 - 66)/2,
  ),
  'bi_ooah': (
      "width": 36,
      "height": 12,
      "bg-top": -163 - (20 - 12)/2,
      "bg-left": -194 - (44 - 36)/2,
  ),
  'bi_ootd': (
      "width": 47,
      "height": 13,
      "bg-top": -218 - (21 - 13)/2,
      "bg-left": -240 - (55 - 47)/2,
  ),
  'bi_shocking': (
      "width": 44,
      "height": 18,
      "bg-top": -211 - (26 - 18)/2,
      "bg-left": -182 - (52 - 44)/2,
  ),
  'bi_shooting': (
      "width": 100,
      "height": 24,
      "bg-top": -84 - (32 - 24)/2,
      "bg-left": -118 - (108 - 100)/2,
  ),
  'bi_shooting_freeze': (
      "width": 84,
      "height": 20,
      "bg-top": -211 - (28 - 20)/2,
      "bg-left": 0 - (92 - 84)/2,
  ),
  'bi_shooting_fresh': (
      "width": 55,
      "height": 13,
      "bg-top": -75 - (21 - 13)/2,
      "bg-left": -328 - (63 - 55)/2,
  ),
  'bi_shooting_install': (
      "width": 82,
      "height": 20,
      "bg-top": -211 - (28 - 20)/2,
      "bg-left": -92 - (90 - 82)/2,
  ),
  'bi_shooting_plus': (
      "width": 85,
      "height": 15,
      "bg-top": -138 - (23 - 15)/2,
      "bg-left": -118 - (93 - 85)/2,
  ),
  'bi_shooting_seller': (
      "width": 66,
      "height": 15,
      "bg-top": -99 - (23 - 15)/2,
      "bg-left": -240 - (74 - 66)/2,
  ),
  'bi_sk': (
      "width": 110,
      "height": 71,
      "bg-top": -84 - (79 - 71)/2,
      "bg-left": 0 - (118 - 110)/2,
  ),
  'bi_sktpass': (
      "width": 70,
      "height": 20,
      "bg-top": -24 - (28 - 20)/2,
      "bg-left": -240 - (78 - 70)/2,
  ),
  'bi_sktpass_style1': (
      "width": 60,
      "height": 20,
      "bg-top": -263 - (28 - 20)/2,
      "bg-left": 0 - (68 - 60)/2,
  ),
  'bi_sktpass_style2': (
      "width": 69,
      "height": 20,
      "bg-top": -52 - (28 - 20)/2,
      "bg-left": -240 - (77 - 69)/2,
  ),
  'bi_ssg_dawn': (
      "width": 66,
      "height": 20,
      "bg-top": -122 - (28 - 20)/2,
      "bg-left": -240 - (74 - 66)/2,
  ),
  'bi_ssg_dawn_compact': (
      "width": 49,
      "height": 13,
      "bg-top": -174 - (21 - 13)/2,
      "bg-left": -328 - (57 - 49)/2,
  ),
  'bi_ssg_emart': (
      "width": 66,
      "height": 20,
      "bg-top": -150 - (28 - 20)/2,
      "bg-left": -240 - (74 - 66)/2,
  ),
  'bi_ssg_emart_compact': (
      "width": 49,
      "height": 13,
      "bg-top": -195 - (21 - 13)/2,
      "bg-left": -328 - (57 - 49)/2,
  ),
  'bi_t_membership': (
      "width": 96,
      "height": 14,
      "bg-top": -116 - (22 - 14)/2,
      "bg-left": -118 - (104 - 96)/2,
  ),
  'bi_t_membership_square': (
      "width": 18,
      "height": 19,
      "bg-top": -358 - (27 - 19)/2,
      "bg-left": -181 - (26 - 18)/2,
  ),
  'bi_t_sktpass': (
      "width": 59,
      "height": 20,
      "bg-top": -263 - (28 - 20)/2,
      "bg-left": -68 - (67 - 59)/2,
  ),
  'bi_timedeal': (
      "width": 57,
      "height": 14,
      "bg-top": -53 - (22 - 14)/2,
      "bg-left": -328 - (65 - 57)/2,
  ),
  'btn_play': (
      "width": 50,
      "height": 50,
      "bg-top": -116 - (58 - 50)/2,
      "bg-left": -328 - (58 - 50)/2,
  ),
  'flag_best': (
      "width": 59,
      "height": 45,
      "bg-top": 0 - (53 - 45)/2,
      "bg-left": -328 - (67 - 59)/2,
  ),
  'ic-arrow-b-left-nor': (
      "width": 10,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -142 - (18 - 10)/2,
  ),
  'ic-arrow-b-left-on': (
      "width": 10,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -160 - (18 - 10)/2,
  ),
  'ic-arrow-b-right-nor': (
      "width": 10,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -178 - (18 - 10)/2,
  ),
  'ic-arrow-b-right-on': (
      "width": 10,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -196 - (18 - 10)/2,
  ),
  'ic_ad': (
      "width": 34,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -100 - (42 - 34)/2,
  ),
  'ic_ad_type2': (
      "width": 41,
      "height": 23,
      "bg-top": -291 - (31 - 23)/2,
      "bg-left": -51 - (49 - 41)/2,
  ),
  'ic_ad_type3': (
      "width": 24,
      "height": 16,
      "bg-top": -239 - (24 - 16)/2,
      "bg-left": -72 - (32 - 24)/2,
  ),
  'ic_ad_type4': (
      "width": 43,
      "height": 23,
      "bg-top": -291 - (31 - 23)/2,
      "bg-left": 0 - (51 - 43)/2,
  ),
  'ic_arrow_left': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": 0 - (26 - 18)/2,
  ),
  'ic_arrow_left_dim': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": -26 - (26 - 18)/2,
  ),
  'ic_arrow_left_on': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": -52 - (26 - 18)/2,
  ),
  'ic_arrow_right': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": -78 - (26 - 18)/2,
  ),
  'ic_arrow_right_dim': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": -104 - (26 - 18)/2,
  ),
  'ic_arrow_right_on': (
      "width": 18,
      "height": 28,
      "bg-top": -322 - (36 - 28)/2,
      "bg-left": -130 - (26 - 18)/2,
  ),
  'ic_billborad_more': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -188 - (32 - 24)/2,
  ),
  'ic_billborad_next': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -220 - (32 - 24)/2,
  ),
  'ic_billborad_previous': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -252 - (32 - 24)/2,
  ),
  'ic_billborad_stop': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -284 - (32 - 24)/2,
  ),
  'ic_cart': (
      "width": 18,
      "height": 18,
      "bg-top": -291 - (26 - 18)/2,
      "bg-left": -214 - (26 - 18)/2,
  ),
  'ic_check': (
      "width": 14,
      "height": 8,
      "bg-top": -218 - (16 - 8)/2,
      "bg-left": -295 - (22 - 14)/2,
  ),
  'ic_check_disable': (
      "width": 14,
      "height": 14,
      "bg-top": -239 - (22 - 14)/2,
      "bg-left": -172 - (22 - 14)/2,
  ),
  'ic_delivery': (
      "width": 16,
      "height": 14,
      "bg-top": -138 - (22 - 14)/2,
      "bg-left": -211 - (24 - 16)/2,
  ),
  'ic_heart': (
      "width": 20,
      "height": 20,
      "bg-top": -358 - (28 - 20)/2,
      "bg-left": -125 - (28 - 20)/2,
  ),
  'ic_heart_red': (
      "width": 20,
      "height": 20,
      "bg-top": -358 - (28 - 20)/2,
      "bg-left": -153 - (28 - 20)/2,
  ),
  'ic_help': (
      "width": 15,
      "height": 15,
      "bg-top": -239 - (23 - 15)/2,
      "bg-left": -104 - (23 - 15)/2,
  ),
  'ic_official': (
      "width": 41,
      "height": 17,
      "bg-top": -263 - (25 - 17)/2,
      "bg-left": -135 - (49 - 41)/2,
  ),
  'ic_play': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -316 - (32 - 24)/2,
  ),
  'ic_search_gray_24': (
      "width": 24,
      "height": 24,
      "bg-top": -322 - (32 - 24)/2,
      "bg-left": -348 - (32 - 24)/2,
  ),
  'ic_search_white_24': (
      "width": 24,
      "height": 24,
      "bg-top": -358 - (32 - 24)/2,
      "bg-left": 0 - (32 - 24)/2,
  ),
  'ic_star': (
      "width": 64,
      "height": 12,
      "bg-top": -178 - (20 - 12)/2,
      "bg-left": -240 - (72 - 64)/2,
  ),
  'ic_star_on': (
      "width": 64,
      "height": 12,
      "bg-top": -198 - (20 - 12)/2,
      "bg-left": -240 - (72 - 64)/2,
  ),
  'ic_star_single': (
      "width": 12,
      "height": 12,
      "bg-top": -191 - (20 - 12)/2,
      "bg-left": -102 - (20 - 12)/2,
  ),
  'ic_thumb_heart': (
      "width": 24,
      "height": 24,
      "bg-top": -358 - (32 - 24)/2,
      "bg-left": -32 - (32 - 24)/2,
  ),
  'ic_thumb_heart_on': (
      "width": 24,
      "height": 24,
      "bg-top": -358 - (32 - 24)/2,
      "bg-left": -64 - (32 - 24)/2,
  ),
  'ic_time_14': (
      "width": 15,
      "height": 14,
      "bg-top": -239 - (22 - 14)/2,
      "bg-left": -127 - (23 - 15)/2,
  ),
  'ic_time_bold': (
      "width": 21,
      "height": 20,
      "bg-top": -358 - (28 - 20)/2,
      "bg-left": -96 - (29 - 21)/2,
  ),
  'icontext_more': (
      "width": 26,
      "height": 5,
      "bg-top": -272 - (13 - 5)/2,
      "bg-left": -328 - (34 - 26)/2,
  ),
  'mart_plus': (
      "width": 80,
      "height": 16,
      "bg-top": 0 - (24 - 16)/2,
      "bg-left": -240 - (88 - 80)/2,
  ),
  'notice': (
      "width": 48,
      "height": 48,
      "bg-top": -216 - (56 - 48)/2,
      "bg-left": -328 - (56 - 48)/2,
  ),
  'reloading': (
      "width": 14,
      "height": 14,
      "bg-top": -239 - (22 - 14)/2,
      "bg-left": -194 - (22 - 14)/2,
  ),
  'reviewevent': (
      "width": 64,
      "height": 16,
      "bg-top": -239 - (24 - 16)/2,
      "bg-left": 0 - (72 - 64)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-common($name, $width: null) {
    $sp-w: map-get($sprite-meta-common, "width");
    $sp-h: map-get($sprite-meta-common, "height");
    $img: map-get($sprite-map-common, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;
    background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
    background-size: #{$bgw}px #{$bgh}px;
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-common($name: null, $tmp-width: null) {
    display: inline-block;
    vertical-align: top;
    background: url("../../img/svg/sprites/sp_common_2025320_133158.svg") no-repeat;
    @if $name != null {
      @include get-sprite-position-common($name, $width: $tmp-width);
    }
}
