@charset "UTF-8";

// event layer
.main_event_layer {
    position: relative;
    z-index: 800;
    width: 1240px;
    margin: 0 auto;

    .inner {
        position: absolute;
        left: 50%;
        top: 0px;
        margin-left: -500px;
        padding: 9px;
        border: 1px solid #333;
        background: #fff;
        box-sizing: border-box;

        img {
            width: 980px;
            height: 648px;
        }
    }
    .inp_check {
        margin-right: 4px;
    }
    .tx {
        font-size: 12px;
        cursor: pointer;
    }
    .func {
        position: relative;
        height: 24px;
        padding: 8px 0 0 0;
    }
    .btn_close {
        position: absolute;
        right: 5px;
        bottom: 0px;
        width: 55px;
        height: 23px;
        background: url("/img/main/v2/sp_main.png") no-repeat -180px -110px;
        text-indent: -9999px;
    }
}
