@import "../../common/helpers/variables";
@import "../../common/mixins";

.c-iconinfo {
    position: relative;
    display: inline-block;
    @include size(14px);
    border: 0;
    padding: 0;
    background: transparent;
    text-indent: -9999px;
    vertical-align: middle;
    overflow: hidden;

    &:after {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        top: 0;
    }

    &--help {
        &:after {
            @include logo-svg($icon-help, 14px, 14px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &--info {
        &:after {
            @include logo-svg($icon-info, 14px, 14px);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
