@charset "UTF-8";

@import "../../common/sprites/sp_gnb_2x";
@import "../../common/sprites/sp_category_menu_2x";
@import "../svg/sp_common.scss";

// MPSR-59739 런칭 후 삭제예정 시작
#header {
    position: relative;
    background: #fff;
    z-index: 40;
}

// header search
.header_sch_area {
    width: 100%;
    border-bottom: 1px solid #e0e0e0;

    .inner {
        position: relative;
        width: 1200px;
        height: 73px;
        margin: 0 auto;
        padding: 35px 0 0 0;
        z-index: 1000;
        background: #fff;
    }

    .season {
        position: absolute;
        left: 157px;
        top: 0px;
        width: 250px;
        height: 108px;
        background-repeat: no-repeat;
    }
}

.header_sch {
    position: relative;
    width: 430px;
    height: 38px;
    margin: 0 auto;
    padding: 0 80px 0 58px;
    background: url("/img/common/v2/sp_header_search.png") no-repeat 0 0;

    .hd {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 54px;
        height: 38px;

        a {
            display: block;
            height: 100%;
            text-indent: -9999px;
        }
    }

    .hd_sub {
        position: absolute;
        left: 48px;
        top: 0px;
        width: 45px;
        height: 26px;

        a {
            display: block;
            height: 100%;
            text-indent: -9999px;
        }
    }

    .header_inp_txt {
        position: relative;
        top: -2px;
        display: block;
        width: 100%;
        height: 30px;
        margin: 0;
        padding: 0;
        font-size: 16px;
        line-height: 30px;
        color: #111211;
        border: none;
        outline: 0 none;
    }

    .btn_search {
        position: absolute;
        right: 24px;
        top: -8px;
        width: 42px;
        height: 40px;
        background: none;
        text-indent: -9999px;
    }

    .header_sch_autotree iframe {
        position: absolute;
        left: 57px;
        top: 37px;
        width: 492px;
    }
}

:root .header_sch .header_inp_txt {
    padding: 0;
}

.header_ad {
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.header_ad_view {
    overflow: hidden;
    width: 200px;
    height: 97px;

    img {
        width: 200px;
        height: 97px;
    }
}

.header_ad_btn {
    position: absolute;
    right: 20px;
    bottom: 10px;

    .btn {
        float: left;
        width: 16px;
        height: 16px;
        background: url("/img/common/v2/sp_header_search.png") no-repeat 0 -42px;
        text-indent: -9999px;

        &.btn_next {
            margin: 0 0 0 -1px;
            background-position: -15px -42px;
        }
    }

    .count {
        float: left;
        margin: 0 4px 0 0;
        font-size: 11px;
        font-family: Tahoma, sans-serif;
        color: #b2b2b2;

        strong {
            color: #333;
        }
    }
}

.header_sch_area .nav_benefit {
    position: absolute;
    left: 0px;
    bottom: 30px;
    padding: 0 0 0 2px;

    li {
        float: left;
        margin: 0 7px;
    }

    a {
        @include fontset;
        color: #333;
    }
}

// header gnb
.header_gnb {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #000;
    z-index: 500;
    @include fontset;

    .inner {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
    }

    .gnb_l {
        float: left;
    }

    .gnb_r {
        float: right;
    }

    .gnb_nav {
        float: left;

        > li {
            float: left;
        }
    }

    @if ($pageStatus == "main") {
        .gnb_btn_all_v3 {
            position: relative;
            display: block;
            width: 132px;
            height: 50px;
            background: url("/img/common/v2/sp_header_gnb_v2.png") no-repeat 0 -684px;
            z-index: 10;
            font-size: 0;
            line-height: 37px;
            cursor: default;
        }
    } @else {
        .gnb_btn_all_v3 {
            position: relative;
            display: block;
            width: 132px;
            height: 51px;
            background: $color-11st-red url("/img/common/v2/sp_header_gnb_v2.png") no-repeat 0 -630px;
            z-index: 10;
            font-size: 0;
            line-height: 51px;

            .in_btn {
                font-size: 0;
                line-height: 0;
                color: transparent;
            }
        }

        .on .gnb_btn_all_v3 {
            background: #282828 url("/img/common/v2/sp_header_gnb_v2.png") no-repeat -142px -630px;
        }
    }

    .gnb_nav_brand {
        padding-left: 10px;

        .link {
            position: relative;
            float: left;
            height: 50px;
            background: url("/img/common/v2/sp_header_gnb_v2.png") no-repeat 0 0;
            text-indent: -9999px;

            .line {
                display: none;
                position: absolute;
                left: 0px;
                bottom: -1px;
                width: 100%;
                height: 3px;
                background: #f53243;
            }

            &:hover .line {
                display: block;
            }
        }

        .shocking .link {
            width: 84px;
            background-position: -107px 0;
        }

        //MPSR-45480 런칭 후 삭제예정 시작 (나우배송 서비스종료)
        .nowdely {
            .link {
                width: 108px;
                margin-left: 10px;
                background-position: 0 0;
            }

            .ico_new {
                position: absolute;
                top: 10px;
                right: 0;
                width: 14px;
                height: 14px;
                background: url("/img/common/v2/sp_header_gnb.png") no-repeat;
                background-position: -200px 0;
                text-indent: -9999px;
            }
        }
        //MPSR-45480 런칭 후 삭제예정 끝 (나우배송 서비스종료)

        .biz11st .link {
            width: 100px;
            background-position: -218px 0;
        }

        .day11 .link {
            width: 69px;
            background-position: -322px 0;
        }
        .allprime .link {
            width: 100px;
            background-position: -195px -356px;
        }
    }

    .shop {
        float: left;
        width: 83px;
        height: 24px;
        margin: 13px 0 0 0;
        background: url("/img/common/v2/sp_header_gnb.png") no-repeat 0 -58px;
        text-indent: -9999px;
    }

    .gnb_nav_theme {
        li {
            position: relative;
            margin-left: 15px;
            font: 0/0 arial;
        }

        .link {
            float: left;
            height: 50px;
            background: url("/img/common/v2/sp_header_gnb.png") no-repeat 0 -86px;
            text-indent: -9999px;
        }

        .event {
            text-indent: 0;
            background: none;
        }

        .department {
            padding-left: 35px;
            background-position: -71px -86px;
        }

        .brand {
            padding-left: 36px;
            background-position: -118px -86px;
        }

        .fashion {
            padding-left: 24px;
            background-position: -164px -86px;
        }

        .beauty {
            padding-left: 24px;
            background-position: -199px -86px;
        }

        .living {
            padding-left: 23px;
            background-position: -234px -86px;
        }

        .leisure {
            padding-left: 23px;
            background-position: -268px -86px;
        }

        .digital {
            padding-left: 35px;
            background-position: -302px -86px;
        }

        .mart {
            padding-left: 24px;
            background-position: -349px -86px;
        }

        .global_direct {
            padding-left: 48px;
            background-position: -383px -86px;
        }

        .lk_phoneshop {
            padding-left: 47px;
            background-position: -305px -194px;
        }
    }

    .ico_new {
        display: block;
        width: 14px;
        height: 14px;
        margin: 10px -8px 0 2px;
        background: url("/img/common/v2/sp_header_gnb.png") no-repeat;
        background-position: -200px 0;
        text-indent: -9999px;
    }

    .gnb_nav_theme {
        .on .department,
        .department:hover {
            background-position: -71px -140px;
        }

        .on .brand,
        .brand:hover {
            background-position: -118px -140px;
        }

        .on .fashion,
        .fashion:hover {
            background-position: -164px -140px;
        }

        .on .beauty,
        .beauty:hover {
            background-position: -199px -140px;
        }

        .on .living,
        .living:hover {
            background-position: -234px -140px;
        }

        .on .leisure,
        .leisure:hover {
            background-position: -268px -140px;
        }

        .on .digital,
        .digital:hover {
            background-position: -302px -140px;
        }

        .on .mart,
        .mart:hover {
            background-position: -349px -140px;
        }

        .on .global_direct,
        .global_direct:hover {
            background-position: -383px -140px;
        }

        .on .lk_phoneshop,
        .lk_phoneshop:hover {
            background-position: -305px -248px;
        }
    }

    .gnb_bar {
        float: left;
        width: 1px;
        height: 19px;
        margin: 18px 0 0 15px;
        background: #e2e2e2;
    }

    .gnb_nav_vertical {
        li {
            position: relative;
            margin-left: 15px;
            font: 0/0 arial;
        }

        .link {
            float: left;
            height: 50px;
            background: url("/img/common/v2/sp_header_gnb.png") no-repeat 0 -86px;
            text-indent: -9999px;
        }

        .book {
            padding-left: 24px;
            background-position: 0 -194px;
        }

        .ticket {
            padding-left: 24px;
            background-position: -34px -194px;
        }

        .trip {
            padding-left: 58px;
            background-position: -68px -194px;
        }

        .gift {
            width: 49px;
            background-position: -102px -194px;
        }

        .lifeplus {
            padding-left: 68px;
            background-position: -140px -194px;
        }

        .lifeplus_v2 {
            padding-left: 34px;
            background-position: -267px -194px;
        }

        .book:hover {
            background-position: 0 -248px;
        }

        .ticket:hover {
            background-position: -34px -248px;
        }

        .trip:hover {
            background-position: -68px -248px;
        }
        .gift:hover {
            background-position: -102px -248px;
        }

        .lifeplus:hover {
            background-position: -140px -248px;
        }

        .lifeplus_v2:hover {
            background-position: -267px -248px;
        }
    }
}

.gnb_nav_category_v2 {
    position: relative;

    @if ($pageStatus == "main") {
        overflow: visible;
    } @else {
        overflow: hidden;
        top: -1px;
    }
}

.gnb_nav_category_v2.on {
    overflow: visible;

    .gnb_nav_category_layer_v2 {
        opacity: 1;
        filter: alpha(opacity = 100);
    }
}

.gnb_nav_category_layer_v2 {
    position: absolute;
    border: 0;
    border-top: 1px solid #000;
    background: none;
    transition: opacity 0.2s;

    @if ($pageStatus == "main") {
        top: 50px;
        opacity: 1;
        filter: alpha(opacity = 100);
    } @else {
        top: 51px;
        opacity: 0;
        filter: alpha(opacity = 0);
    }

    .btn_close {
        position: absolute;
        right: 1px;
        top: 1px;
        width: 36px;
        height: 37px;
        background: url("/img/main/v2/sp_main.png") no-repeat -15px 0;
        text-indent: -9999px;
    }

    .total_category {
        float: left;
        width: 990px;
        background: url("/img/common/v2/total_category_line.gif") repeat;
    }

    .row {
        clear: both;
    }
}

// 카테고리전체-LNB-layer
.ctgrlist_wrap {
    .main_category_v2 {
        position: relative;
        width: 132px;

        > ul > li {
            > a {
                position: relative;
                display: block;
                height: 36px;
                padding: 0 0 0 13px;
                line-height: 36px;
                font-size: 13px;
                color: #fff;
                background-color: #313131;
                background-color: rgba(0, 0, 0, 0.8);
                border-top: none;
                text-decoration: none;
                z-index: 10;

                .layer_tail {
                    display: none;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    width: 8px;
                    height: 12px;
                    margin: -6px 0 0 0;
                    background: url("/img/main/v2/sp_main.png") no-repeat 0 -15px;
                }
            }

            &.on {
                > a {
                    background: $color-11st-red;
                }

                a .layer_tail {
                    display: block;
                }
            }

            > a:hover {
                background: $color-11st-red;
            }

            &:first-child > a {
                height: 35px;
                line-height: 35px;
            }

            &.last > a {
                height: 33px;
            }

            &.recommand {
                &.on > a,
                > a:hover {
                    background: #00c6ad;
                }
            }
        }
    }

    .main_category_layer_v2 {
        display: none;
        position: absolute;
        left: 131px;
        top: -1px;
        height: 464px;
        border: 1px solid #000;
        border-left: 0;
        background: #fff;
    }

    li.on .main_category_layer_v2 {
        display: block;
    }

    .main_category_layer_v2 {
        .set_category {
            overflow: hidden;
            float: left;
            width: 680px;
            height: 100%;

            .col {
                float: left;
                height: 100%;
                border-left: 1px solid #dadada;
                &:first-child {
                    border-left: none;
                }
            }

            .box {
                position: relative;
                float: left;
                width: 169px;
                border-right: 1px solid #dadada;

                .subject {
                    overflow: hidden;
                    display: block;
                    height: 35px;
                    line-height: 34px;
                    margin-top: -1px;
                    padding: 0 0 0 20px;
                    border-bottom: 1px solid #dadada;

                    a {
                        font-size: 13px;
                        color: #111;
                    }
                }

                .ico_arrow {
                    display: inline-block;
                    width: 5px;
                    height: 9px;
                    margin: 0 0 0 4px;
                    background: url("/img/main/v2/sp_main.png") no-repeat;
                    vertical-align: 0px;
                }

                li {
                    overflow: hidden;
                    height: 20px;
                    padding: 0px 5px 0 20px;
                    line-height: 20px;

                    &:first-child {
                        padding-top: 4px;
                    }

                    a {
                        font-size: 13px;
                    }
                }
            }

            .box_list {
                overflow: hidden;
                border-bottom: 1px solid #dadada;
            }

            .box .more {
                position: absolute;
                left: 20px;
                bottom: 6px;

                a {
                    color: #999;
                    font-size: 12px;
                    line-height: 14px;
                    text-decoration: underline;
                }

                .n {
                    letter-spacing: 0;
                }
            }

            &.set_bt_row_default .box_list {
                height: 430px;
            }

            &.set_bt_row_0 .box_list {
                height: 197px;
            }

            &.set_bt_row_1 .box_list {
                height: 179px;
            }

            &.set_bt_row_2 .box_list {
                height: 162px;
            }

            &.set_bt_row_3 .box_list {
                height: 144px;
            }

            &.set_bt_row_4 .box_list {
                height: 127px;
            }

            &.set_bt_row_5 .box_list {
                height: 109px;
            }
        }

        .special_category {
            float: left;
            width: 339px;
            height: 465px;
            margin: 0 0 0 -1px;
            border-left: 1px solid #c6c6c6;
            background: #fcfcfc;

            .box {
                float: left;
                overflow: hidden;
                width: 169px;
                height: 100%;
                border-left: 1px solid #ededed;
                font-size: 12px;

                &:first-child {
                    border-left: none;
                }

                .subject {
                    display: block;
                    height: 34px;
                    line-height: 34px;
                    padding: 0 0 0 19px;
                    color: #111;
                    border-bottom: 1px solid #dadada;
                }
            }

            .recommand {
                li {
                    margin: 6px 0 0 0;
                    padding: 0 5px 0 0;
                    font-size: 13px;
                    line-height: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }

                .thumb {
                    margin: 0 7px 0 0;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .specialshop {
                .box_list {
                    padding-top: 3px;
                }

                li {
                    margin: 3px 0 0 0;
                    padding: 0 5px 0 0;
                    font-size: 13px;
                    line-height: 20px;
                }
            }
        }

        .btn_close {
            position: absolute;
            right: 1px;
            top: -1px;
            width: 35px;
            height: 35px;
            background: url("/img/main/v2/sp_main.png") no-repeat -15px 0;
            text-indent: -9999px;
        }
    }

    .main_recommand_layer {
        display: none;
        position: absolute;
        left: 131px;
        top: -1px;
        height: 464px;
        border: 1px solid #000;
        background: #fff;
    }

    li.on .main_recommand_layer {
        display: block;
    }

    .main_recommand_layer {
        .set_recommand {
            .box {
                float: left;
                height: 464px;
                border-left: 1px solid #dadada;

                &:first-child {
                    border-left: none;
                }
            }

            .cont {
                overflow: hidden;
                padding: 5px 0 0 0;
            }

            .box .subject {
                display: block;
                padding: 0 0 0 16px;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #dadada;
                background: #fcfcfc;
                font-size: 13px;
                color: #111;

                .point {
                    color: #00c6ad;
                }
            }

            .box_list {
                float: left;
                width: 180px;

                li {
                    overflow: hidden;
                    height: 30px;
                    padding: 5px 3px 6px 11px;
                    font-size: 13px;
                    white-space: nowrap;

                    .thumb {
                        display: inline-block;
                        margin: 0 7px 0 0;
                        vertical-align: middle;
                        text-align: center;

                        img {
                            max-width: 30px;
                            max-height: 30px;
                        }
                    }
                }
            }
        }

        .btn_close {
            position: absolute;
            right: 1px;
            top: 1px;
            width: 36px;
            height: 37px;
            background: url("/img/main/v2/sp_main.png") no-repeat -15px 0;
            text-indent: -9999px;
        }
    }

    .main_category_v2 {
        > ul > li {
            &.on > a,
            > a:hover {
                width: 119px;
                border-left: 1px solid #111;
                color: #111;
                background-color: #fff;
                background-color: rgba(255, 255, 255, 1);
            }

            &.recommand {
                &.on > a,
                > a:hover {
                    width: 119px;
                    border-left: 1px solid #111;
                    background: #fff;
                    border-bottom-color: #111;
                }
            }

            &.last > a {
                height: 33px;
                line-height: 33px;
                border-bottom: 1px solid #949daa;
                border-color: rgba(0, 0, 0, 0.1);
            }
        }

        .main_category_layer_v2 {
            min-width: 360px;

            .tit_category {
                display: block;
                overflow: hidden;
                width: 310px;
                height: 34px;
                margin: 30px 0 23px 30px;
                font-size: 0;
                line-height: 0;
                background: url("/img/main/menu/sp_tit_category.gif") no-repeat 0 0;
                text-indent: -9999px;
            }
        }

        .brand .tit_category {
            background-position: 0 0;
        }

        .cloth .tit_category {
            background-position: 0 -40px;
        }

        .beauty .tit_category {
            background-position: 0 -80px;
        }

        .leisure .tit_category {
            background-position: 0 -120px;
        }

        .food .tit_category {
            background-position: 0 -160px;
        }

        .baby .tit_category {
            background-position: 0 -200px;
        }

        .life .tit_category {
            background-position: 0 -240px;
        }

        .interior .tit_category {
            background-position: 0 -280px;
        }

        .digital .tit_category {
            background-position: 0 -320px;
        }

        .book .tit_category {
            background-position: 0 -360px;
        }

        .hobby .tit_category {
            background-position: 0 -400px;
        }

        .foreign .tit_category {
            background-position: 0 -440px;
        }

        .tit_sort {
            display: block;
            overflow: hidden;
            margin-left: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #f0f0f0;
            font-weight: normal;
            font-size: 13px;
            line-height: 1.2;
            color: #999;
        }
    }

    .box_category {
        float: left;
        height: 350px;
        padding: 0 20px;

        &:after {
            display: inline-block;
            clear: both;
            content: "";
        }

        .list_category {
            float: left;
            width: 150px;
            padding: 7px 0 0;

            > li {
                position: relative;

                &:hover {
                    z-index: 1;
                }
            }

            .link_cont {
                display: block;
                overflow: hidden;
                width: 140px;
                padding-left: 10px;
                height: 24px;
                line-height: 22px;
                color: #4c4c4c;
            }

            > li:hover {
                .link_cont {
                    background-color: #111;
                    color: #fff;
                    text-decoration: none;
                }

                .sub_cate {
                    display: block;
                }
            }
        }

        .sub_cate {
            display: none;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 126px;
            z-index: 20;
            border: 2px solid #111;
            background-color: #fff;
        }

        .list_sub_cate {
            position: relative;
            z-index: 100;
            float: left;
            width: 139px;
            border-bottom: 1px solid #ededed;

            &:first-child {
                width: 140px;
                border-right: 1px solid #ededed;
            }

            li {
                border-top: 1px solid #ededed;

                &:hover .link_sub {
                    background-color: $color-11st-red;
                    color: #fff;
                    text-decoration: none;
                }

                &:first-child {
                    border-top: 0 none;
                }
            }

            .link_sub {
                display: block;
                overflow: hidden;
                width: 110px;
                height: 23px;
                padding: 0 15px 0 15px;
                font-size: 13px;
                line-height: 23px;
                color: #4c4c4c;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .box_rel {
        float: left;
        width: 130px;
        height: 350px;
        padding: 0 20px 0 19px;
        border-left: 1px solid #f0f0f0;

        .tit_sort {
            margin: 0;
        }

        ul {
            width: 130px;
            padding: 5px 0 0;

            li {
                overflow: hidden;
                height: 22px;
                font-size: 13px;
                line-height: 22px;
            }

            .link_cont {
                color: #999;
            }
        }

        .list_recommend {
            margin-bottom: 28px;
        }
    }

    .main_category_v2 {
        .cate_visbox {
            position: absolute;
            top: -1px;
            right: -1px;
            width: 1px;
            height: 464px;

            .cate_link {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                min-width: 310px;
                text-align: left;
            }
        }
    }
}

.dimm {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0;
    height: auto;
    background-color: #000;
    opacity: 0.2;
    filter: alpha(opacity = 20);
    z-index: 30;
    overflow: hidden;
}

.dimm_on {
    display: block;
}
// MPSR-59739 런칭 후 삭제예정 끝

// header
div.l_header {
    position: relative;
    width: 100%;
    z-index: 1000;
    font-family: $font-family;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;

    a {
        font-family: $font-family;
    }
    &.l_fixed_gnb {
        .b_header_gnb {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background: #fff;
            box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.06), 0 -1px 4px 0 rgba(0, 0, 0, 0.18);
            &:after {
                display: none;
            }
        }

        .b_header_gnb_logoday {
            .visual {
                display: none;
            }
        }

        .b_header_gnb_line {
            box-shadow: none;
            border-bottom: 1px solid #f4f4f4;
            box-sizing: border-box;
        }
    }
    &.l_header_category_visible {
        .c_header_category {
            display: block;
            opacity: 1;
            left: 0;

            &:after {
                position: fixed;
                opacity: 1;
            }
        }
    }
    &.l_header_inventory_visible {
        .c_header_inventory {
            display: block;
            opacity: 1;
            right: 0;

            &:after {
                position: fixed;
                opacity: 1;
            }
        }
    }
}

.b_header_gnb {
    position: relative;
    background: #fff;
    z-index: 100;

    &:after {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #f4f4f4;
        content: "";
    }

    > .inner {
        position: relative;
        width: 1240px;
        height: 120px;
        margin: 0 auto;
    }

    &.b_header_gnb_mini {
        .inner {
            height: 70px;
        }
        .c_gnb_logo {
            top: 20px;
            left: 0px;
            @include sprite-retina($sp_gnb_2x_logo_s);
        }

        .bi_11st {
            top: 20px;
            left: 0px;
            @include sprite-retina($sp_gnb_2x_logo_s);
        }
    }

    &.b_header_gnb_amazon {
        .c_gnb_logo {
            width: 259px;
            height: 46px;
            top: 37px;
            left: 83px;
            background: none;

            &::before {
                position: absolute;
                left: 85px;
                top: 0px;
                width: 1px;
                height: 46px;
                background-color: #222f3e;
                content: "";
            }

            .bi_11st {
                float: left;
                @include get-sprite-common("bi_11st", 65);
                margin-top: 10px;
            }

            .bi_amazon {
                float: left;
                @include get-sprite-common("bi_amazon", 112);
                margin: 12px 0 0 39px;
            }
        }

        .c_gnb_search {
            left: 319px;

            .search_button {
                top: 4px;
                right: 4px;
                @include sprite-retina($sp_gnb_2x_search_button_amazon);
            }
        }

        .c_gnb_searchbox {
            left: 319px;

            .search_button {
                top: 4px;
                right: 4px;
                @include sprite-retina($sp_gnb_2x_search_button_amazon);
            }
        }

        .c_gnb_etc {
            display: block;
        }

        .c_gnb_raking,
        .c-gnb-ranking {
            display: none;
        }
    }

    &.b_header_gnb_amazon_simplebar {
        > .inner {
            height: 70px;
        }

        .c_gnb_button_category {
            top: 20px;

            > button {
                width: 30px;
                height: 30px;
                box-shadow: none;
                border-radius: 0;
            }
        }

        .c_gnb_logo {
            width: 163px;
            height: 46px;
            top: 17px;
            left: 46px;
            background: none;

            &::before {
                position: absolute;
                left: 61px;
                top: 0px;
                width: 1px;
                height: 36px;
                background-color: #222f3e;
                content: "";
            }

            .bi_11st {
                float: left;
                @include get-sprite-common("bi_11st", 47);
                margin-top: 8px;
            }

            .bi_amazon {
                float: left;
                @include get-sprite-common("bi_amazon", 85);
                margin: 10px 0 0 29px;
            }
        }

        .c_gnb_search {
            top: 13px;
            left: 220px;
            width: 510px;
            height: 45px;

            .search_text {
                height: 38px;
                line-height: 38px;
                font-size: 16px;
            }

            .search_button {
                top: 4px;
                right: 3px;
                @include sprite-retina($sp_gnb_2x_search_button_amazon_s);
            }

            .c_gnb_layer {
                top: 50px;
            }
        }

        .c_gnb_etc {
            display: block;
            top: 13px;
            left: 736px;

            .c_gnb_button {
                width: 92px;
                height: 45px;
                font-size: 14px;
                line-height: 43px;
            }
        }

        .c_gnb_raking,
        .c-gnb-ranking {
            display: none;
        }

        .c_gnb_usermenu,
        .c-util-user {
            top: 14px;
            > ul {
                display: flex;
                gap: 16px;
            }
            > ul > li {
                &.my {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_my_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_my_s_on);
                        }
                    }
                    .c_gnb_layer {
                        left: 0;
                        top: 50px;
                    }
                }
                &.order {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_delivery_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_delivery_s_on);
                        }
                    }
                }
                &.cart {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_cart_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_cart_s_on);
                        }
                    }
                }
                &.lately {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_recent_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_recent_s_on);
                        }
                        .thumnail {
                            left: 8px;
                            top: 6px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .count {
                top: -3px;
                left: 25px;
            }
        }
    }

    &.b_header_gnb_external {
        box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.06), 0 -1px 4px 0 rgba(0, 0, 0, 0.18);

        &:after {
            display: none;
        }
        .inner {
            height: 70px;
        }
        .c_gnb_logo {
            top: 20px;
            left: 0px;
            @include sprite-retina($sp_gnb_2x_logo_s);
        }
    }

    &.b_header_gnb_logoday {
        .c_gnb_logo {
            left: 60px;
            top: 0;
            width: 190px;
            height: 119px;
            background: none;

            .bi_11st {
                left: 60px;
                top: 0;
                width: 190px;
                height: 119px;
                //@include get-sprite-common("bi_11st", 190);
                background: none;
            }

            .visual {
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            #lottie {
                //개발에서 사용
                position: absolute;
                top: 0;
                background: #fff;
                height: 119px;

                canvas {
                    position: relative;
                    top: -86px;
                }
            }
        }
        .c_gnb_search {
            left: 250px;
            width: 456px;
        }
    }

    &.b_header_gnb_simplebar {
        > .inner {
            height: 70px;
        }

        .c_gnb_button_category {
            top: 20px;

            > button {
                width: 30px;
                height: 30px;
                box-shadow: none;
                border-radius: 0;
            }
        }

        .c_gnb_logo {
            top: 20px;
            left: 45px;
            @include sprite-retina($sp_gnb_2x_logo_s);

            .bi_11st {
                @include get-sprite-common("bi_11st", 70);
            }
        }

        .c_gnb_search {
            top: 13px;
            left: 126px;
            width: 510px;
            height: 45px;

            .search_text {
                height: 36px;
                line-height: 36px;
                font-size: 16px;
            }

            .search_button {
                top: 4px;
                right: 4px;
                @include sprite-retina($sp_gnb_2x_search_button_s);
            }

            &.c_gnb_search_tab {
                .search_text {
                    height: 40px;
                    line-height: 40px;
                }

                .c_gnb_search_listbox {
                    .listbox_button {
                        height: 45px;

                        &::after {
                            top: 20px;
                        }
                    }

                    .active {
                        &::after {
                            top: 22px;
                        }
                    }
                }
            }

            .c_gnb_layer {
                top: 50px;
            }
        }

        .c_gnb_searchbox {
            top: 13px;
            left: 126px;
            width: 510px;
            height: 45px;

            .search_text {
                height: 36px;
                line-height: 36px;
                font-size: 16px;
            }

            .search_button {
                top: 4px;
                right: 4px;
                @include sprite-retina($sp_gnb_2x_search_button_s);
            }

            .c_gnb_layer {
                top: 50px;
            }
        }

        .c_gnb_raking,
        .c-gnb-ranking {
            display: none;
        }

        .c_gnb_usermenu,
        .c-util-user {
            top: 14px;
            > ul {
                display: flex;
                gap: 16px;
            }

            > ul > li {
                &.my {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_my_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_my_s_on);
                        }
                    }
                    .c_gnb_layer {
                        left: 0;
                        top: 50px;
                    }
                }
                &.order {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_delivery_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_delivery_s_on);
                        }
                    }
                }
                &.cart {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_cart_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_cart_s_on);
                        }
                    }
                }
                &.lately {
                    .menu {
                        @include sprite-retina($sp_gnb_2x_menu_recent_s);

                        &:hover {
                            @include sprite-retina($sp_gnb_2x_menu_recent_s_on);
                        }
                        .thumnail {
                            left: 8px;
                            top: 6px;
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .count {
                top: -3px;
                left: 25px;
            }
        }
    }

    &.b_header_gnb_shadow {
        box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.06), 0 -1px 4px 0 rgba(0, 0, 0, 0.18);

        &:after {
            display: none;
        }
    }
}

h2.c_gnb_servicename {
    position: absolute;
    top: 9px;
    left: 80px;
    font-size: 30px;
    line-height: 47px;
    font-weight: bold;

    a {
        font-family: "11StreetGothic", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
        color: #111;

        &:focus,
        &:active,
        &:hover {
            text-decoration: none;
        }
    }
}

.c_gnb_button_category {
    position: absolute;
    left: 0;
    top: 30px;
    z-index: 10;

    > button {
        position: relative;
        overflow: hidden;
        width: 60px;
        height: 60px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.4);
        line-height: 200px !important;
        border-radius: 50%;

        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -18px 0 0 -18px;
            @include sprite-retina($sp_gnb_2x_category_menu);
            content: "";
        }
    }
    &.active {
        > button {
            box-shadow: 0 8px 32px -8px rgba(0, 0, 0, 0.4), 0 0 1px 0 rgba(0, 0, 0, 0.7);
        }
    }
}

.c_gnb_guide_layer {
    display: none;
    position: absolute;
    left: -21px;
    top: 84px;
    width: 264px;
    padding: 17px 27px 20px 27px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: #ff5534;
    background: linear-gradient(115deg, $color-11st-gradient-orange, $color-11st-red 63%, $color-11st-gradient-pink 121%);

    &:after {
        position: absolute;
        left: 40px;
        top: -16px;
        @include sprite-retina($sp_gnb_2x_layer_tail);
        content: "";
    }
    &.active {
        display: block;
    }
    .text {
        color: #fff;
        font-size: 15px;
        line-height: 22px;
        word-break: keep-all;

        strong {
            display: block;
            margin: 0 0 5px 0;
            font-size: 16px;
        }
    }

    .close {
        position: absolute;
        right: 4px;
        top: 1px;
        overflow: hidden;
        line-height: 200px;
        @include ico-cancel(13px, 1px, #fff, 10px);
    }
}

.c_gnb_logo {
    position: absolute;
    left: 83px;
    top: 40px;
    @include sprite-retina($sp_gnb_2x_logo);

    .bi_11st {
        @include get-sprite-common("bi_11st", 94);
        display: block;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    .bi_amazon {
        @include get-sprite-common("bi_amazon", 112);
        display: block;
        background-repeat: no-repeat;
        background-color: #fff;
    }

    a {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        line-height: 400px;

        .visual {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            width: 190px;
            height: 119px;
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 190px 119px;
            background-color: #fff;
        }
    }
}

.c_gnb_search {
    position: absolute;
    left: 196px;
    top: 35px;
    width: 510px;
    height: 50px;
    padding: 2px 55px 0 27px;
    border-radius: 25px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    background: #fafafa;

    .search_text {
        display: block;
        width: 100%;
        height: 44px;
        line-height: 44px;
        background: none;
        border: none;
        font-size: 18px;
        font-family: $font-family;
        letter-spacing: 0;
        color: #111;
        outline: none;
        padding: 0;

        &.search_text_ad {
            color: $color-grayscale-05;
        }

        &.search_text_img {
            color: transparent;
            background-repeat: no-repeat;
            background-size: 120px 28px;
            background-position: calc(50% + 18px) center;
        }
    }

    .search_button {
        position: absolute;
        right: -1px;
        top: -1px;
        overflow: hidden;
        line-height: 200px;
        @include sprite-retina($sp_gnb_2x_search_button);
    }

    .c_gnb_layer {
        top: 55px;
        left: 0;

        .search_word {
            @include clearfix;
            width: 733px;
            height: 534px;
        }
    }

    .search_keyword {
        position: relative;
        float: left;
        width: 321px;
        height: 493px;
        padding: 0 0 40px 0;
        border-right: 1px solid #f4f4f4;
        box-sizing: border-box;
    }

    .search_ranking {
        float: left;
        width: 411px;
        height: 493px;
    }

    .c_gnb_search_listbox {
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: 1;
        width: 140px;

        &.active {
            border-radius: 23px;
            box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0 rgba(0, 0, 0, 0.36);
            background-color: $color-grayscale-13;

            .listbox_button {
                background-color: #fff;

                &[aria-expanded="true"] + [role="listbox"] {
                    display: block;
                }

                &::before {
                    display: none;
                }

                &::after {
                    border-top: none;
                    border-bottom: 4px solid #333;
                }
            }
        }

        .listbox_button {
            position: relative;
            width: 140px;
            height: 49px;
            padding: 0 0 2px 20px;
            border-radius: 25px 25px 0 25px;
            color: $color-grayscale-02;
            font-size: 16px;
            font-family: $font-family;
            text-align: left;

            &:before {
                position: absolute;
                top: 50%;
                right: 0;
                width: 1px;
                height: 20px;
                background-color: $color-grayscale-06;
                transform: translateY(-50%);
                content: "";
            }

            &:after {
                position: absolute;
                top: 22px;
                right: 12px;
                width: 0;
                height: 0;
                content: "";
                border-top: 4px solid #333;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .listbox_list[role="listbox"] {
            display: none;
            padding: 5px 0 10px;
            border-top: 1px solid $color-grayscale-10;

            li[role="option"] {
                position: relative;
                width: 100%;
                height: 32px;
                padding: 0 20px;
                color: $color-grayscale-02;
                text-align: left;
                font-size: 15px;
                line-height: 32px;
                box-sizing: border-box;
                font-weight: normal;

                &[aria-selected="true"].focused {
                    &::before {
                        position: absolute;
                        top: 50%;
                        left: 11px;
                        display: block;
                        width: 3px;
                        height: 3px;
                        margin-top: -2px;
                        border-radius: 50%;
                        background-color: $color-11st-red;
                        content: "";
                    }

                    color: $color-11st-red;
                    cursor: pointer;
                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                &.list_group_line {
                    position: relative;
                    padding-top: 20px;
                    height: 52px;

                    &[aria-selected="true"].focused {
                        &::before {
                            top: calc(50% + 10px);
                        }
                    }

                    &::after {
                        position: absolute;
                        top: 10px;
                        left: 0;
                        display: block;
                        width: 100%;
                        height: 1px;
                        background-color: #f4f4f4;
                        content: "";
                    }
                }

                &.list_group_level_2 {
                    margin-top: 5px;
                    position: relative;
                    width: 100%;
                    height: 28px;
                    padding: 0 20px 0 31px;
                    font-size: 14px;
                    line-height: 28px;
                    color: $color-grayscale-03;
                    text-align: left;

                    &[aria-selected="true"].focused {
                        &::before {
                            left: 22px;
                            background-color: $color-11st-red;
                        }

                        color: $color-11st-red;
                    }

                    &::before {
                        position: absolute;
                        top: 50%;
                        left: 22px;
                        display: block;
                        width: 3px;
                        height: 3px;
                        margin-top: -2px;
                        border-radius: 50%;
                        background-color: $color-grayscale-07;
                        content: "";
                    }

                    &:after {
                        position: absolute;
                        top: 0;
                        left: 22px;
                        display: block;
                        @include sprite-retina($sp_gnb_2x_tree_top);
                        content: "";
                    }
                }

                &.list_group_level_3 {
                    position: relative;
                    width: 100%;
                    height: 28px;
                    padding: 0 20px 0 39px;
                    font-size: 14px;
                    line-height: 28px;
                    color: $color-grayscale-03;
                    text-align: left;

                    &[aria-selected="true"].focused {
                        color: $color-11st-red;

                        &::before {
                            display: none;
                        }
                    }

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 22px;
                        display: block;
                        @include sprite-retina($sp_gnb_2x_tree);
                        content: "";
                    }

                    &.end {
                        &::after {
                            position: absolute;
                            top: 0;
                            left: 22px;
                            display: block;
                            @include sprite-retina($sp_gnb_2x_tree_end);
                            content: "";
                        }
                    }
                }
            }
        }
    }

    &.c_gnb_search_tab {
        padding: 2px 55px 0 156px;

        .search_text {
            height: 43px;
            line-height: 43px;
            font-size: 16px;
        }

        .c_gnb_layer {
            left: 140px;

            .search_word {
                width: 713px;

                .search_keyword {
                    width: 311px;

                    .c_recent_search_title {
                        padding: 17px 0 20px 20px;
                    }
                    .c_recent_search_list {
                        margin: 0 20px;
                    }
                }

                .search_ranking {
                    width: 401px;

                    .c_raking_title {
                        padding: 17px 20px 18px 20px;
                    }
                    .c_ranking_tab {
                        margin: 0 20px;
                    }
                    .c_ranking_list {
                        margin: 20px 20px 28px 20px;
                    }
                }
            }

            .c_search_autolist {
                width: 371px;

                a {
                    padding: 0 20px 0 16px;
                }
            }
        }
    }
}

.c_gnb_etc {
    display: none;
    position: absolute;
    top: 35px;
    left: 835px;

    .c_gnb_button {
        width: 92px;
        height: 50px;
        border-radius: 100px;
        border: solid 1px $color-grayscale-08;
        background-color: $color-grayscale-13;
        font-size: 15px;
        font-weight: 500;
        line-height: 48px;
        color: #111;
        text-align: center;
    }
}

/* Start .c-gnb-ranking 반영 후 제거 */
.c_gnb_raking {
    position: absolute;
    left: 730px;
    top: 48px;
    width: 210px;

    .raking_current {
        overflow: hidden;
        height: 28px;

        .raking_box {
            li {
                position: relative;
                margin: 4px 0 0 0;
                padding: 0 26px 0 0;
                color: #111;

                &:first-child {
                    margin-top: 0;
                }
                &:hover {
                    color: $color-11st-red;
                }
                .number {
                    float: left;
                    margin: 0 10px 0 0;
                    color: $color-11st-red;
                    font-size: 16px;
                    font-weight: bold;
                    font-style: italic;
                    font-family: $font-family-number-lato;
                }
            }
            a {
                display: block;

                &:hover {
                    text-decoration: none;
                    color: $color-11st-red;

                    .text {
                        color: $color-11st-red;
                    }
                }
            }
            .text {
                overflow: hidden;
                display: block;
                height: 24px;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 15px;
                line-height: 24px;
                font-weight: bold;
                color: #111;
            }
        }
        .raking_button {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 200px;
            width: 24px;
            height: 24px;

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 6px;
                height: 6px;
                margin: -5px 0 0 -4px;
                border: solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
                content: "";
            }
        }
    }

    .c_gnb_layer {
        width: 410px;
        left: 0;
        top: 43px;
    }
}
/* End .c-gnb-ranking 반영 후 제거 */

.c-gnb-ranking {
    position: absolute;
    left: 730px;
    top: 48px;
    width: 173px;

    .raking_current {
        overflow: hidden;
        height: 28px;

        .raking_box {
            li {
                position: relative;
                margin: 4px 0 0 0;
                padding: 0 26px 0 0;
                color: #111;

                &:first-child {
                    margin-top: 0;
                }
                &:hover {
                    color: $color-11st-red;
                }
                .number {
                    float: left;
                    margin: 0 10px 0 0;
                    color: $color-11st-red;
                    font-size: 16px;
                    font-weight: bold;
                    font-style: italic;
                    font-family: $font-family-number-lato;
                }
            }
            a {
                display: block;

                &:hover {
                    text-decoration: none;
                    color: $color-11st-red;

                    .text {
                        color: $color-11st-red;
                    }
                }
            }
            .text {
                overflow: hidden;
                display: block;
                height: 24px;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 15px;
                line-height: 24px;
                font-weight: bold;
                color: #111;
            }
        }
        .raking_button {
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            line-height: 200px;
            width: 24px;
            height: 24px;

            &:after {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 6px;
                height: 6px;
                margin: -5px 0 0 -4px;
                border: solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(135deg);
                content: "";
            }
        }
    }

    .c_gnb_layer {
        width: 410px;
        left: 0;
        top: 43px;
    }
}

.c_gnb_layer {
    display: none;
    position: absolute;
    box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 10;

    &.active {
        display: block;
    }
}

.c_raking_title {
    padding: 17px 25px 18px 25px;

    .title {
        display: inline-block;
        vertical-align: middle;
        font-size: 17px;
        color: #111;
    }
    .text {
        display: inline-block;
        margin: 0 0 0 4px;
        vertical-align: middle;
        font-size: 13px;
        color: #999;
    }
    .time {
        font-family: $font-family-number-lato;
    }
}

.c_ranking_tab {
    @include clearfix;
    margin: 0 25px;

    li {
        position: relative;
        float: left;
        width: 180px;
        height: 40px;
        box-sizing: border-box;
        border: 1px solid #eee;
        background: #fafafa;

        a {
            display: block;
            height: 100%;
            text-align: center;
            color: #7d7d7d;
            font-size: 15px;
            line-height: 40px;

            &:hover {
                text-decoration: none;
            }
        }
        &:first-child {
            margin-right: -1px;
        }

        &.active {
            background: #fff;
            z-index: 5;

            a {
                font-weight: bold;
                color: #111;
            }
        }
    }
}

.c_ranking_list {
    margin: 20px 25px 28px 25px;

    li {
        position: relative;
        margin: 14px 0 0 0;
        padding: 0 50px 0 0;
        height: 22px;
        line-height: 22px;
        font-size: 15px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            margin-top: 0;
        }
    }
    a {
        color: #111;

        &:hover {
            text-decoration: none;

            .text {
                text-decoration: underline;
                color: $color-11st-red;
            }
            .related_text {
                text-decoration: none;
            }
        }
    }
    .number {
        float: left;
        margin-right: 10px;
        font-weight: bold;
        color: $color-11st-red;
        font-size: 15px;
        font-family: $font-family-number-lato;
        font-style: italic;
        vertical-align: top;
    }
    .related_text {
        display: inline-block;
        margin: 1px 0 0 4px;
        font-size: 13px;
        vertical-align: top;
        color: #999;
    }
    .rank_icon {
        position: absolute;
        top: 1px;
        right: 0px;
        overflow: hidden;
        line-height: 200px;

        &.rank_down {
            @include sprite-retina($sp_gnb_2x_rank_down);
        }
        &.rank_up {
            @include sprite-retina($sp_gnb_2x_rank_up);
        }
        &.rank_stay {
            width: 6px;
            height: 2px;
            margin: 10px 7px 0 0;
            background: #999;
        }
        &.rank_new {
            @include sprite-retina($sp_gnb_2x_rank_new);
        }
    }
}

.c_ranking_close {
    position: absolute;
    right: 20px;
    top: 15px;

    button {
        @include ico-cancel(13px, 1px, #111, 5px);
    }
}

.c_recent_search_title {
    padding: 17px 0 20px 25px;

    .title {
        font-size: 17px;
        color: #111;
    }
}

.c_recent_search_list {
    margin: 0 25px;

    li {
        position: relative;
        overflow: hidden;
        margin: 16px 0 0 0;
        padding: 0 60px 0 30px;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            margin-top: 0;
        }
        &:before {
            position: absolute;
            left: 0;
            top: -1px;
            @include sprite-retina($sp_gnb_2x_search_recent);
            content: "";
        }
    }
    a {
        font-size: 15px;
        color: #111;
    }
    .delete {
        position: absolute;
        right: -4px;
        top: 0;
        @include ico-cancel(10px, 1px, #999, 5px);
    }
}

.c_search_all_delete {
    position: absolute;
    bottom: 24px;
    left: 25px;

    button {
        border: none;
        background: none;
        text-decoration: underline;
        font-size: 13px;
        color: #999;
    }
}

.c_recent_no_data {
    margin: 0 25px;
    font-size: 15px;
    color: #666;
}

.c_search_autolist {
    position: relative;
    width: 510px;
    box-sizing: border-box;

    ul {
        padding: 20px 0 20px;
    }

    li {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
            margin-top: 0;
        }
        &.active {
            a {
                background: #fafafa;
            }
        }
    }
    a {
        display: block;
        padding: 0 25px 0 25px;
        color: #333;
        font-size: 15px;
        line-height: 32px;

        strong {
            font-weight: normal;
            color: $color-11st-red;
        }

        &:hover,
        &:focus {
            background: #fafafa;
            text-decoration: underline;
        }
    }
    .search_data_no {
        padding: 40px 0 40px 0;
        font-size: 15px;
        color: #333;
        text-align: center;
    }
}

.c_search_close {
    clear: both;
    position: relative;
    height: 40px;
    border-top: 1px solid #f4f4f4;
    box-sizing: border-box;

    button {
        position: absolute;
        right: 20px;
        top: 9px;
        padding: 0 16px 0 0;
        font-size: 13px;
        color: #999;
        line-height: 1.5;

        span {
            position: absolute;
            right: 0;
            top: 2px;
            @include ico-cancel(8px, 1px, #999, 4px);
        }
    }
}

.c-util-user {
    display: flex;
    position: absolute;
    top: 36px;
    right: -15px;
    > ul {
        display: flex;
        gap: 12px;
    }
    > ul > li {
        position: relative;
        padding: 0 0 15px 0;

        &:first-child {
            margin-left: 0;
        }
        .menu {
            overflow: hidden;
            display: block;
            line-height: 200px;
        }
        &.my {
            .menu {
                @include sprite-retina($sp_gnb_2x_menu_my);

                &:hover {
                    @include sprite-retina($sp_gnb_2x_menu_my_on);
                }
            }
            .c_gnb_layer {
                width: 170px;
                left: 0;
                top: 55px;
            }
        }
        &.order {
            z-index: 40;

            .menu {
                @include sprite-retina($sp_gnb_2x_menu_delivery);

                &:hover {
                    @include sprite-retina($sp_gnb_2x_menu_delivery_on);
                }
            }
        }
        &.cart {
            z-index: 30;

            .menu {
                @include sprite-retina($sp_gnb_2x_menu_cart);

                &:hover {
                    @include sprite-retina($sp_gnb_2x_menu_cart_on);
                }
            }
        }

        &.lately {
            .menu {
                position: relative;
                @include sprite-retina($sp_gnb_2x_menu_recent);
                z-index: 20;

                &:hover {
                    @include sprite-retina($sp_gnb_2x_menu_recent_on);
                }

                .thumnail {
                    position: absolute;
                    left: 9px;
                    top: 7px;
                    width: 30px;
                    height: 30px;
                    z-index: 10;
                    background: center center / contain no-repeat rgb(255, 255, 255);
                }
            }
        }
    }
    .count {
        position: absolute;
        top: -5px;
        left: 36px;
        height: 20px;
        padding: 0 7px;
        line-height: 20px;
        background: $color-11st-red;
        border-radius: 10px;
        font-size: 12px;
        font-family: $font-family-number-lato;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
    }
}

.c-app {
    position: relative;

    &__download {
        display: flex;
        gap: 18px;
        position: relative;
        padding: 15px 15px 25px;
        margin-top: -15px;

        &::before {
            display: block;
            width: 1px;
            height: 30px;
            margin-top: 8px;
            background-color: $color-gray-11;
            content: "";
        }

        &::after {
            @include sprite-retina($sp_gnb_2x_btn_app);
            background-color: $color-gray-14;
            content: "";
        }

        &--active {
            &::after {
                @include sprite-retina($sp_gnb_2x_btn_app_on);
                background-color: $color-gray-14;
                content: "";
            }
        }
    }

    @at-root {
        .b_header_gnb_amazon_simplebar .c-app,
        .b_header_gnb_simplebar .c-app {
            margin-left: 5px;
            &__download {
                gap: 20px;
                padding: 15px;
                &::after {
                    @include sprite-retina($sp_gnb_2x_btn_app_s);
                    background-color: $color-gray-14;
                    content: "";
                }

                &--active {
                    &::after {
                        @include sprite-retina($sp_gnb_2x_btn_app_on_s);
                        background-color: $color-gray-14;
                        content: "";
                    }
                }
            }
        }
    }
}

.c-app-layer {
    position: absolute;
    top: 65px;
    right: 10px;
    width: 286px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 24px 20px;
    border-radius: 4px;
    background: $color-gray-14;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.36), 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    font-family: $font-family-lato;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px) scale(0.8);
    transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;

    &__title {
        text-align: center;
        color: $color-gray-02;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
    }

    &__info {
        margin-top: 4px;
        text-align: center;
        color: $color-gray-05;
        font-size: 13px;
        font-weight: 400;
        line-height: 19px;
    }

    &__link {
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
    }

    &__button {
        display: block;
        box-sizing: border-box;
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }

    &__qr {
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        width: 156px;
        height: 156px;
        margin-top: 16px;
        border-radius: 4px;
        background: $color-gray-14;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 2px 12px 0px rgba(0, 0, 0, 0.06);

        .qr-code {
            width: 128px;
            height: 128px;
        }
    }

    &--active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0) scale(1);
    }

    .app-store,
    .google-play {
        position: relative;
        display: block;
        width: 32px;
        height: 32px;
        overflow: hidden;
        line-height: 200px;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
        }
    }

    .app-store {
        &::before {
            @include sprite-retina($sp_gnb_2x_ic_apple);
        }
    }

    .google-play {
        &::before {
            @include sprite-retina($sp_gnb_2x_ic_google);
        }
    }

    @at-root {
        .b_header_gnb_amazon_simplebar .c-app-layer,
        .b_header_gnb_simplebar .c-app-layer {
            top: 50px;
        }
    }
}

.c_gnb_menulist {
    padding: 15px 0px;

    a {
        display: block;
        height: 35px;
        padding: 0 10px 0 25px;
        line-height: 35px;
        color: #111;
        font-size: 15px;

        &:hover {
            color: $color-11st-red;
            background: #fafafa;
            text-decoration: none;
        }
    }
}

.b_header_util {
    position: relative;
    box-shadow: 0px -2px 12px 0px rgba(0, 0, 0, 0.06), 0px -1px 4px 0px rgba(0, 0, 0, 0.18);
    background: #fff;

    > .inner {
        position: relative;
        width: 1240px;
        height: 66px;
        margin: 0 auto;
        z-index: 20;
    }
    &.b_header_util_line {
        box-shadow: none;

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: #f4f4f4;
            content: "";
        }
    }
}

.c-util-tooltip {
    position: absolute;
    top: 4px;
    left: 50%;
    padding: 0 5px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 8px;
    background: #ffeff3;
    border: 1px solid #ffe5eb;
    color: $color-11st-red;
    transform: translateX(-50%);

    &__text {
        position: relative;
        z-index: 3;
        font-size: 8.5px;
        font-weight: 700;
        line-height: 14px;
        white-space: nowrap;
    }

    &::after {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 50%;
        width: 8px;
        height: 2px;
        background: #ffeff3;
        transform: translateX(-50%);
        content: "";
    }
    &::before {
        position: absolute;
        z-index: 1;
        bottom: -5px;
        left: 50%;
        width: 4px;
        height: 4px;
        background: #ffeff3;
        border-style: solid;
        border-color: #ffe5eb;
        border-width: 0px 1px 1px 0px;
        border-radius: 1.5px;
        transform: translate(-50%, -50%) scale(1, 1.5) rotate(45deg);
        content: "";
    }
}

.c-util-service {
    @include clearfix;

    ul {
        float: left;

        &.servicelink_amazon {
            &::before {
                float: left;
                width: 1px;
                height: 18px;
                margin: 24px 14px;
                background-color: $color-grayscale-06;
                content: "";
            }

            li {
                a {
                    &:hover,
                    &.active {
                        &:after {
                            background-color: #49a3c7;
                        }
                    }

                    .new {
                        position: absolute;
                        right: -12px;
                        top: 10px;
                        overflow: hidden;
                        line-height: 200px;
                        @include sprite-retina($sp_gnb_2x_amazon_new);
                    }
                }
            }
        }
    }

    li {
        position: relative;
        float: left;
        height: 66px;
        margin: 0 0 0 14px;

        &:first-child {
            margin-left: 0;
        }

        &.line {
            position: relative;
            padding: 0 0 0 14px;

            &::before {
                position: absolute;
                left: 0;
                top: 24px;
                width: 1px;
                height: 18px;
                background-color: $color-grayscale-06;
                content: "";
            }
        }

        a {
            position: relative;
            display: block;
            height: 100%;
            color: #111;
            font-size: 17px;
            line-height: 66px;
            font-family: "11StreetGothic", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
            font-weight: bold;

            &:hover,
            &.active {
                text-decoration: none;

                &:after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: $color-11st-red;
                    content: "";
                }
            }
            .new {
                position: absolute;
                right: -5px;
                top: 19px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                overflow: hidden;
                line-height: 200px;
                background: $color-11st-red;
            }
        }
    }

    .amazon {
        padding-left: 29px;

        a {
            &::before {
                position: absolute;
                top: 23px;
                left: -30px;
                @include get-sprite-common("bi_amazon_icon", 20);
                display: block;
                content: "";
            }
        }
    }
}

.c_util_etc {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    color: $color-gray-02;
    box-sizing: border-box;

    .login_status {
        display: flex;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            color: $color-gray-02;

            &:not(:first-child) {
                &::before {
                    display: block;
                    width: 1px;
                    height: 10px;
                    margin: 0 10px;
                    background: $color-gray-11;
                    content: "";
                }
            }
        }
    }

    .login_info {
        display: flex;
        a {
            color: $color-gray-02;
        }

        .user {
            position: relative;
            & > a {
                display: flex;
                align-items: center;
            }
            .level {
                overflow: hidden;
                line-height: 200px;
                margin-right: 4px;
                &.biz {
                    @include sprite-retina($sp_gnb_2x_level_biz_s);
                }
            }

            .name {
                font-weight: 700;

                span {
                    display: inline-block;
                    max-width: 96px;
                    vertical-align: middle;
                    margin-top: -3px;
                    @include text-ellipsis();
                }
            }
        }

        .point {
            position: relative;
            display: flex;
            align-items: center;
            font-family: $font-family-lato;
            line-height: 22px;

            &::before {
                width: 1px;
                height: 10px;
                margin: 0 10px;
                background: $color-gray-11;
                content: "";
            }

            a {
                font-family: $font-family-number-lato;
            }

            .elevenpay {
                @include sprite-retina($sp_gnb_2x_11pay_p);
                display: block;
                margin-right: 8px;
                overflow: hidden;
                line-height: 200px;
            }
        }
    }

    .direct_stauts {
        position: relative;
        display: flex;
        align-items: center;

        &::before {
            width: 1px;
            height: 10px;
            margin: 0 10px;
            background: $color-gray-11;
            content: "";
        }

        .text {
            display: inline-block;
            vertical-align: top;
        }
        .status {
            display: inline-block;
            width: 30px;
            text-align: center;
            font-weight: bold;
            font-family: $font-family-number-lato;
            color: #999;
            white-space: nowrap;

            &.on {
                color: $color-11st-red;
            }
        }
        .direct_button {
            overflow: hidden;
            position: absolute;
            left: 0;
            top: -10px;
            width: 100%;
            height: 38px;
            line-height: 300px;
        }
        .c_gnb_layer {
            top: 25px;
            left: -185px;
        }
    }
}

.c_direct_guide {
    width: 340px;
    padding: 20px 25px 25px 25px;
    box-sizing: border-box;
    font-size: 15px;
    line-height: 22px;

    .status_text {
        color: #111;
        font-size: 17px;
        font-weight: bold;

        strong {
            color: $color-11st-red;
        }
    }
    dl {
        @include clearfix;
        display: block;
        width: 100%;
    }
    .group {
        @include clearfix;
        width: 100%;
        padding: 20px 0 15px 0;
        border-top: 1px solid #eee;
        box-sizing: border-box;

        &:first-child {
            border-top: none;
        }
        dt {
            margin: 0 0 20px 0;
            font-weight: bold;
            color: #111;
        }
        dd {
            margin: 0 0 5px 0;
            color: #666;
        }
    }
    .how {
        dd {
            float: left;
            width: 50%;

            button {
                font-size: 15px;
                line-height: 22px;
                font-family: $font-family;
                color: $color-11st-blue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .detail_link {
        display: block;
        width: 100%;
        height: 40px;
        line-height: 38px;
        border: 1px solid #ccc;
        text-align: center;
        color: #111;

        &:hover {
            text-decoration: none;
        }
    }
}

.c_header_category {
    display: none;
    opacity: 0;
    position: fixed;
    left: -100%;
    // left: 0;
    top: 0;
    width: 300px;
    height: 100%;
    background: #f6f6f6;
    transition: all 0.3s ease;
    z-index: 200;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
        opacity: 0;
        z-index: -1;
        transition: all 1s ease;
        content: "";
    }
    .inner {
        overflow-y: auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 94px;
        background: #f6f6f6;
        padding-bottom: 10px;
        box-sizing: content-box;
    }
    &.c_header_category_no_data {
        .inner {
            bottom: 0;
        }
        .c_category_group {
            display: none;
        }
        .c_category_banner {
            display: none;
        }
    }

    .close {
        position: absolute;
        right: 20px;
        top: 17px;
        overflow: hidden;
        line-height: 200px;
        @include sprite-retina($sp_gnb_2x_close);
    }
}

.c_category_userinfo {
    background: #fff;

    .user {
        position: fixed;
        top: 0;
        width: 300px;
        height: 70px;
        padding: 20px 60px 0 23px;
        box-sizing: border-box;
        border-bottom: 1px solid #f4f4f4;
        background: #fff;

        .level {
            float: left;
            margin: 4px 10px 0 0;
            overflow: hidden;
            line-height: 200px;
            vertical-align: top;

            &.vip {
                @include sprite-retina($sp_gnb_2x_level_vip);
            }
            &.biz {
                @include sprite-retina($sp_gnb_2x_level_biz);
            }
            &.family {
                @include sprite-retina($sp_gnb_2x_level_family);
            }
            &.social {
                @include sprite-retina($sp_gnb_2x_level_social);
            }
        }
        .name {
            overflow: hidden;
            display: block;
            height: 30px;
            margin: 0 0 0 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 20px;
            color: #111;
        }
        .login {
            position: relative;
            top: -2px;
            display: inline-block;
            padding: 0 0 0 34px;
            font-weight: bold;
            font-size: 20px;
            color: #111;

            &:before {
                position: absolute;
                left: 0;
                top: 4px;
                @include sprite-retina($sp_gnb_2x_login);
                content: "";
            }
        }
        .text {
            font-weight: bold;
            font-size: 20px;
            color: #111;
        }
    }
    .info {
        @include clearfix;
        height: 55px;
        padding: 16px 25px 0 25px;
        box-sizing: border-box;
        text-align: right;

        a {
            text-decoration: none;
        }
        .skpaypoint {
            position: absolute;
            left: 25px;
            top: 21px;
            overflow: hidden;
            line-height: 200px;
            @include sprite-retina($sp_gnb_2x_skpaypoint);
        }
        .number {
            position: relative;
            margin: -5px 0 0 0;
            padding: 0 0 0 25px;
            font-size: 17px;
            font-family: $font-family-number-lato;
            color: #111;

            &:before {
                position: absolute;
                left: 0;
                top: 1px;
                @include sprite-retina($sp_gnb_2x_skpaypoint_icon);
                content: "";
            }
        }
    }
}

.c_category_group {
    margin: 10px 0 0 0;
    background: #fff;

    &:first-child {
        margin-top: 0px;
    }
}

.c_category_title {
    position: relative;
    height: 55px;

    .title {
        padding: 14px 25px 0 25px;
        font-size: 17px;
        color: #111;
        letter-spacing: 0;
    }
    .fold_button {
        position: absolute;
        right: 0;
        top: 0;

        button {
            overflow: hidden;
            position: relative;
            width: 60px;
            height: 55px;
            line-height: 200px;

            &:after {
                position: absolute;
                top: 50%;
                right: 50%;
                width: 7px;
                height: 7px;
                margin: -3px -5px 0 0;
                border: solid #111;
                border-width: 1px 1px 0 0;
                content: "";
            }
            &.expand {
                &:after {
                    transform: rotate(135deg);
                }
            }
            &.reduce {
                &:after {
                    transform: rotate(-45deg);
                }
            }
        }
    }
}

.c_category_list {
    position: relative;

    > ul > li {
        > a {
            position: relative;
            overflow: hidden;
            display: block;
            height: 50px;
            line-height: 50px;
            padding: 0 0 0 52px;
            font-size: 15px;
            color: #111;

            &:before {
                position: absolute;
                left: 22px;
                top: 13px;
                content: "";
            }
            &:hover {
                text-decoration: none;
            }
        }
        &.brand {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_brandfashion);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_brandfashion_on);
                    }
                }
            }
        }
        &.trend {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_trendfashion);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_trendfashion_on);
                    }
                }
            }
        }
        &.beauty {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_beauty);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_beauty_on);
                    }
                }
            }
        }
        &.food {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_food);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_food_on);
                    }
                }
            }
        }
        &.baby {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_baby);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_baby_on);
                    }
                }
            }
        }
        &.life {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_life);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_life_on);
                    }
                }
            }
        }
        &.interior {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_interior);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_interior_on);
                    }
                }
            }
        }
        &.digital {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_digital);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_digital_on);
                    }
                }
            }
        }
        &.sports {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_sports);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_sports_on);
                    }
                }
            }
        }
        &.book {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_book);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_book_on);
                    }
                }
            }
        }
        &.tour {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_tour);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_tour_on);
                    }
                }
            }
        }
        &.overseas {
            > a {
                &:before {
                    @include sprite-retina($sp_category_menu_2x_overseas);
                }
            }
            &.active {
                > a {
                    &:before {
                        @include sprite-retina($sp_category_menu_2x_overseas_on);
                    }
                }
            }
        }
        &.active {
            font-weight: normal;

            > a {
                text-decoration: none;
                color: #fff;
                background-color: #ff5534;
                background: linear-gradient(to right, $color-11st-gradient-orange -11%, $color-11st-red 57%, $color-11st-gradient-pink 121%);

                &:after {
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    width: 7px;
                    height: 7px;
                    margin-top: -5px;
                    border: solid #fff;
                    border-width: 1px 1px 0 0;
                    transform: rotate(45deg);
                    content: "";
                }
            }
            .box_2depth {
                display: block;
            }
        }
        .box_2depth {
            display: none;
            position: fixed;
            left: 300px;
            top: 0;
            bottom: 0;
            width: 200px;
            border-left: 1px solid #eee;
            background: #fff;
            box-sizing: content-box;
            transition: all 0.3s ease; //개발요청

            .box_2depth_inner {
                overflow-y: auto;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
            .group {
                position: relative;
                width: 100%;
                padding: 23px 0 20px 0;

                &:before {
                    position: absolute;
                    left: 20px;
                    right: 20px;
                    top: 0;
                    height: 1px;
                    background: #f4f4f4;
                    content: "";
                }
                &:first-child {
                    padding-top: 22px;

                    &:before {
                        display: none;
                    }
                }
            }
            dt {
                display: block;
                padding: 10px 20px 9px 20px;
                font-size: 14px;
                color: #999;
                font-weight: bold;
            }
            dd {
                > a {
                    display: block;
                    position: relative;
                    padding: 10px 20px 8px 20px;
                    font-size: 15px;
                    color: #111;

                    &:after {
                        display: none;
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        width: 5px;
                        height: 5px;
                        margin-top: -3px;
                        border: solid $color-11st-red;
                        border-width: 1px 1px 0 0;
                        transform: rotate(45deg);
                        content: "";
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                &.active {
                    font-weight: normal;

                    > a {
                        background: rgba(255, 0, 56, 0.03);
                        color: $color-11st-red;
                        font-weight: bold;
                        text-decoration: none;

                        &:after {
                            display: block;
                        }
                    }
                    .box_3depth {
                        display: block;
                    }
                }
                &.no_depth {
                    > a {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .box_3depth {
            display: none;
            position: fixed;
            left: 500px;
            top: 0;
            bottom: 0;
            overflow-y: auto;
            width: 200px;
            padding: 20px 0;
            border-left: 1px solid #eee;
            background: #fff;
            transition: all 0.3s ease; //개발요청

            a {
                display: block;
                position: relative;
                padding: 10px 20px 8px 20px;
                font-size: 15px;
                color: #111;

                &:hover {
                    background: rgba(244, 49, 66, 0.03);
                    color: $color-11st-red;
                    font-weight: bold;
                    text-decoration: none;

                    &:after {
                        display: block;
                    }
                }
            }
        }
    }
}

//ie9 버그해결 핵
@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    //카테고리 리스트 ie9 scroll시 width값 버그해결
    .c_category_list {
        > ul > li {
            .box_2depth {
                overflow-x: hidden;

                .group {
                    width: 200px;
                }
            }
        }
    }
    .c_header_category {
        .inner {
            overflow-x: hidden;
        }
    }
    .c_category_list {
        li {
            width: 300px;
        }
    }
    .c_category_group {
        width: 300px;
    }
}

.c_category_message {
    position: absolute;
    left: 0;
    right: 0;
    top: 65px;
    bottom: 0;
    background: #fff;

    .caution {
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -89px;
        font-size: 16px;
        line-height: 24px;
        color: #333;
        text-align: center;

        &::before {
            display: block;
            margin: 0 auto 20px auto;
            @include sprite-retina($sp_gnb_2x_caution);
            content: "";
        }
    }
}

.c_major_service {
    @include clearfix;

    li {
        float: left;
        width: 50%;
    }
    a {
        display: block;
        overflow: hidden;
        height: 50px;
        padding: 14px 0 0 24px;
        box-sizing: border-box;
        font-size: 15px;
        color: #111;

        &:hover {
            background: #fafafa;
            color: $color-11st-red;
            text-decoration: none;
        }
    }
}

.c_department_list {
    padding: 0 0 25px 25px;

    li {
        margin: 10px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }
    a {
        display: block;
    }
    img {
        width: 250px;
        height: 55px;
    }
}

.c_category_partnerslist {
    @include clearfix;
    padding: 5px 0 25px 0;
    border-top: 1px solid #f4f4f4;

    li {
        float: left;
        width: 50%;
        padding: 15px 0 0 0;
        text-align: center;

        img {
            width: 112px;
            height: 42px;
        }
    }
}

.c_category_brandlist {
    @include clearfix;
    padding: 0 0 15px 0;
    border-top: 1px solid #f4f4f4;

    li {
        float: left;
        width: 33.3%;
        padding: 10px 0 10px 0;

        a {
            display: block;
            position: relative;
            width: 82px;
            height: 82px;
            margin: 0 auto;
            text-align: center;

            &:hover {
                &:after {
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: rgba(0, 0, 0, 0.02);
                    content: "";
                }
            }
        }
        &:nth-child(3n-2) {
            width: 33.4%;
        }
        .thumnail {
            img {
                width: 55px;
                height: 55px;
            }
        }
        .name {
            display: block;
            text-align: center;
            font-size: 14px;
        }
    }
}

.c_category_content {
    display: none;

    &.active {
        display: block;
    }
}

.c_category_banner {
    position: absolute;
    left: 0;
    bottom: 0;

    img {
        width: 300px;
        height: 94px;
        vertical-align: top;
    }
}

.c_header_inventory {
    display: none;
    opacity: 0;
    position: fixed;
    right: -100%;
    top: 0;
    width: 400px;
    height: 100%;
    background: #fff;
    transition: all 0.3s ease;
    z-index: 200;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.1);
        opacity: 0;
        z-index: -1;
        // transition-duration: all 1s ease;
        content: "";
    }
    .inner {
        height: 100%;
    }
    .scrollarea {
        overflow-y: auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 266px;
        background: #fff;
    }
    .close {
        position: absolute;
        right: 20px;
        top: 17px;
        overflow: hidden;
        line-height: 200px;
        @include sprite-retina($sp_gnb_2x_close);
    }
    &.c_header_inventory_not_recommend {
        .scrollarea {
            bottom: 0px;
        }
        .c_inventory_reommend {
            display: none;
        }
    }
}

.c_inventory_title {
    height: 70px;
    padding: 19px 0 0 25px;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;
    background: #fff;

    .title {
        display: inline-block;
        font-size: 20px;
        vertical-align: middle;
        color: #111;
    }
    .like_link {
        display: inline-block;
        margin: 0 0 0 25px;
        vertical-align: middle;

        a {
            position: relative;
            display: inline-block;
            padding: 0 12px 0 0;
            font-size: 14px;
            color: #111;

            &:after {
                position: absolute;
                top: 13px;
                right: 0;
                width: 6px;
                height: 6px;
                margin: -6px 0 0 -3px;
                border: solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }
    }
}

.c_inventory_history {
    padding: 0 25px 25px 43px;

    dt {
        display: block;
        position: relative;
        margin: 0 0 1px 0;
        font-size: 15px;
        font-family: $font-family-number-lato;
        z-index: 10;

        &:before {
            position: absolute;
            left: -17px;
            top: 9px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #666;
            content: "";
        }
    }
    .group {
        position: relative;
        padding: 23px 0 0 0;

        &:first-child {
            padding-top: 19px;

            &:after {
                top: 36px;
            }
        }
        &:last-child {
            &:after {
                height: 36px;
            }
        }
        &.today {
            dt {
                color: $color-11st-red;

                &:before {
                    background: $color-11st-red;
                }
            }
        }
    }
    ul {
        @include clearfix;
        margin-right: -25px;

        li {
            float: left;
            position: relative;
            width: 104px;
            margin: 10px 0 0 10px;

            &:nth-child(3n-2) {
                margin-left: 0;
            }
            .thumnail {
                display: block;
                position: relative;
                width: 104px;
                height: 104px;
                text-align: center;

                img {
                    @include img-center();
                }
                &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.06);
                    content: "";
                }
            }
            &:hover {
                .thumnail {
                    &:after {
                        border: 1px solid rgba(0, 0, 0, 0.7);
                    }
                }
                .delete {
                    opacity: 1;
                }
            }
            a:focus + .delete {
                opacity: 1;
            }
            .delete {
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                overflow: hidden;
                line-height: 200px;
                background: #111;
                @include ico-cancel(9px, 1px, #fff, 7px);

                &:focus {
                    opacity: 1;
                }
            }
        }
    }
    .recent_more_link {
        margin: 25px 0 0 0;
        text-align: center;

        a {
            display: inline-block;
            position: relative;
            height: 40px;
            line-height: 40px;
            padding: 0 32px 0 20px;
            border: 1px solid #ccc;
            border-radius: 20px;
            color: #111;
            font-size: 15px;

            &:hover {
                text-decoration: none;
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 20px;
                width: 5px;
                height: 5px;
                margin-top: -3px;
                border: solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }
    }
}

.c_inventory_reommend {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #eee;
    background: #fff;
}

.c_inventory_reommend_title {
    height: 70px;
    padding: 23px 0 0 25px;
    box-sizing: border-box;

    .title {
        font-size: 17px;
        color: #111;
    }
}

.c_inventory_reommend_list {
    padding: 0 25px 25px 25px;

    li {
        margin: 10px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
        .c_card {
            a {
                position: relative;
                display: block;
                height: 80px;

                &:hover {
                    text-decoration: none;

                    .c_prd_name {
                        strong {
                            text-decoration: underline;
                        }
                    }
                }
                &:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.03);
                    content: "";
                }
            }
        }
        .c_prd_thumb {
            position: relative;
            float: left;
            width: 80px;
            height: 80px;

            img {
                @include img-center();
            }
        }
        .c_card_info {
            margin: 0 6px 0 95px;
            padding: 16px 0 0 0;
        }
        .c_prd_name {
            margin: 0 0 4px 0;
        }
        .c_prd_price {
            .rate {
                font-size: 17px;
                line-height: 17px;

                .unit {
                    font-size: 17px;
                    line-height: 17px;
                }
            }
            .price {
                font-size: 17px;
                line-height: 17px;
            }
            .unit {
                font-size: 15px;
            }
        }
    }
}

.c_inventory_reommend_navigator {
    position: absolute;
    right: 24px;
    top: 21px;

    button {
        overflow: hidden;
        position: relative;
        width: 28px;
        height: 28px;
        line-height: 300px;
        border-radius: 50%;
        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
        background: #fff;

        &::before {
            position: absolute;
            left: 50%;
            top: 50%;
            content: "";
        }
        &.previous {
            &::before {
                margin: -6px 0 0 -5px;
                @include sprite-retina($sp_gnb_2x_arrow_left);
            }
            &:hover {
                &::before {
                    @include sprite-retina($sp_gnb_2x_arrow_left_on);
                }
            }
        }
        &.next {
            margin-left: 10px;

            &::before {
                margin: -6px 0 0 -3px;
                @include sprite-retina($sp_gnb_2x_arrow_right);
            }
            &:hover {
                &::before {
                    @include sprite-retina($sp_gnb_2x_arrow_right_on);
                }
            }
        }
        &:disabled {
            &.previous {
                &::before {
                    @include sprite-retina($sp_gnb_2x_arrow_left_dim);
                }
            }
            &.next {
                &::before {
                    @include sprite-retina($sp_gnb_2x_arrow_right_dim);
                }
            }
            &:hover {
                box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);
            }
        }
        &:hover {
            box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
        }
    }
}

.c_inventory_no_data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 70px);
    min-height: 120px;
    box-sizing: border-box;
    background: #fff;

    .caution {
        width: 100%;
        font-size: 17px;
        color: #333;
        text-align: center;

        &::before {
            display: block;
            margin: 0 auto 20px auto;
            @include sprite-retina($sp_gnb_2x_caution);
            content: "";
        }
    }
}
