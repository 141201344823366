[data-type="PC_GridList_ImgText_CircleBox"] {
    min-width: 1240px;
    min-height: 102px;

    .c-list {
        &__container {
            @include flex-list-gap-container(8px);
            margin-top: -40px;
            flex-wrap: wrap;
        }

        &__item {
            @include flex-list-gap-item(8.3%, 10px);
            min-width: 104px;
            padding-top: 40px;
            box-sizing: border-box;
        }
    }

    .c-icontext {
        width: 100%;
        min-width: 94px;
        padding-top: 60px;
        background-color: $color-gray-14;

        &__icon {
            width: 60px;
            height: 60px;
        }

        &__text {
            height: 22px;
            overflow: hidden;
            margin-top: 20px;
            color: $color-gray-03;
            font-family: $font-family-lato;
            font-size: 15px;
            line-height: 22px;
        }

        &:hover {
            text-decoration: underline;
        }

        &::after {
            position: absolute;
            top: 7px;
            left: 50%;
            display: block;
            transform: translateX(-50%);
            width: 60px;
            height: 60px;
            border-radius: 30px;
            z-index: 1;
            content: "";
        }
    }
}
