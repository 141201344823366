[data-type="PC_Banner_Iframe_Col2"] {
    max-width: 1240px;
    min-width: 1240px;
    min-height: 220px;
    margin: 0 auto;

    .c-banner {
        @include clearfix();

        &__iframe {
            width: 602px;
            height: 220px;

            &:nth-of-type(1) {
                float: left;
            }
            &:nth-of-type(2) {
                float: right;
            }
        }
    }
}
