@charset "UTF-8";

// [Button]
.btn_nw_white {
    border: 1px solid #dadada;
    background-color: #fff;
    color: #666;
}

.btn_nw_gray {
    border: 1px solid #666;
    background-color: #666;
    color: #fff;
}

.btn_nw_grege {
    border: 1px solid #dadada;
    background-color: #f5f5f5;
    color: #333;
}

.btn_nw_blue {
    border: 1px solid #868ba8;
    background-color: #868ba8;
    color: #fff;
}

.btn_nw_red {
    border: 1px solid $color-11st-red;
    background-color: #fff;
    color: $color-11st-red;
}

.btn_nw_white,
.btn_nw_gray,
.btn_nw_grege,
.btn_nw_blue,
.btn_nw_red {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    vertical-align: middle;
}

// [Button] 기본높이 35px
a {
    &.btn_nw_white,
    &.btn_nw_gray,
    &.btn_nw_grege,
    &.btn_nw_blue,
    &.btn_nw_red {
        display: inline-block;
        min-width: 60px;
        height: 27px;
        padding-top: 6px;
        font-family: $font-family-default;
    }

    &.btn_nw_white:hover,
    &.btn_nw_gray:hover,
    &.btn_nw_grege:hover,
    &.btn_nw_blue:hover,
    &.btn_nw_red:hover {
        text-decoration: none;
    }
}

button {
    &.btn_nw_white,
    &.btn_nw_gray,
    &.btn_nw_grege,
    &.btn_nw_blue {
        min-width: 90px;
        height: 35px;
        padding-bottom: 2px;
        *padding-bottom: 3px;
        font-family: $font-family-default;
    }
}
