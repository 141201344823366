@import "../../common/helpers/variables";
@import "../../common/mixins";

@mixin c-checkbox-toggle-reset() {
    display: inline-block;
    margin: 0;

    > em {
        display: block;
        float: left;
        position: relative;
        cursor: pointer;
        transition: background-position 0.2s ease-in;

        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 34px;
            background: linear-gradient(0deg, rgba(120, 120, 128, 0.16), rgba(120, 120, 128, 0.16)), #ffffff;
            //opacity: 0.5;
            transform: translate(-50%, -50%);
            content: "";
        }

        &:after {
            transform: translateY(-50%);
            border-radius: 100%;
            position: absolute;
            top: 50%;
            border: 0.5px solid rgba(0, 0, 0, 0.04);
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
            transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
            box-sizing: border-box;
            content: "";
        }

        &:active {
            &:after {
                transform: translateY(-50%) scale3d(1.15, 0.85, 1);
            }
        }
    }

    span {
        color: $color-gray-03;
        font-size: 15px;
        display: block;
        float: left;
        padding-left: 8px;

        &:first-child {
            padding: 0 8px 0 0;
        }
    }

    > input {
        @include size(0 0);
        display: block;
        position: absolute;
        opacity: 0;

        &:checked + em {
            &:after {
                border-color: rgba(0, 0, 0, 0.1);
            }
        }

        &:disabled + em {
            cursor: default;

            &:before,
            &:after {
                background: $color-gray-11;
            }

            &:after {
                border: 0;
                box-shadow: none;
            }

            &:active {
                &:after {
                    transform: translateY(-50%);
                }
            }
        }
    }

    &--disabled {
        span {
            color: $color-gray-09;
        }
    }
}

@mixin c-checkbox-toggle-set($checkboxWidth, $checkboxHeight, $checkboxCheckSize, $checkColor, $checkbgColor, $checkStart) {
    > em {
        width: $checkboxWidth;
        height: $checkboxHeight;

        &::before {
            width: $checkboxWidth;
            height: $checkboxHeight;
        }

        &::after {
            left: $checkStart;
            width: $checkboxCheckSize;
            height: $checkboxCheckSize;
            background: $checkColor;
        }
    }

    span {
        height: $checkboxHeight;
        line-height: $checkboxHeight;
    }

    > input {
        &:checked + em {
            &:before {
                background: $color-11st-red;
            }
            &:after {
                left: calc(100% - #{$checkboxCheckSize + $checkStart});
                background-color: $checkColor;
            }
        }
    }
}

.c-checkbox-toggle {
    @include c-checkbox-toggle-reset();
}

// 사용 할 영역에서 설정 방식
//.c-checkbox-toggle {
//토글디자인너비, $토글디자인높이, $토글체크불릿사이즈, $토글체크불릿칼라, $토글체크bg칼라, $토글체크불릿위치
//   @include c-checkbox-toggle-set(33px, 20px, 16px, #fff, #ff0038, 2px);
//}
//
