//이미지 가로x세로 비율이 정수로 떨어지지 않는 경우, 아래 변수에 값을 추가

$lazyload-rates: (329, 197), (720, 500), (880, 100), (140, 210), (320, 197), (605, 302), (394, 198), (605, 304), (608, 310), (292, 310), (1240, 220), (132, 50), (620, 200);

@each $rates in $lazyload-rates {
    $h: nth($rates, 1);
    $v: nth($rates, 2);
    .c-lazyload--ratio_#{$h}x#{$v} {
        padding-bottom: (percentage($v * 100 / $h) / 100);
        min-width: #{$h}px;
        min-height: #{$v}px;
    }
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.c-lazyload {
    display: block;
    overflow: hidden;
    position: relative;
    font-size: 0;
    line-height: 0;
    box-sizing: border-box;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent url(#{data-uri("../img/common/v3/loading_thumb.png")}) no-repeat 50% 50%;
        background-size: 40px 36px;
        opacity: 1;
    }
    img,
    iframe {
        //pc에서 영역내 센터정렬
        position: absolute !important;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        animation: fadeInOpacity 0.2s ease-in;

        &.c-lazyload__image {
            border-radius: 0;
            visibility: hidden;
            @at-root .c-round-button__icon {
                .c-lazyload__image {
                    border-radius: 28px;
                }
            }
        }
    }

    iframe {
        margin: 0;
        width: 1px;
        min-width: 100%;
        *width: 100%;
    }

    &--ratio_1x1 {
        padding-bottom: 100%;
    }

    &--ratio_2x1 {
        padding-bottom: 50%;
    }
    /*
    &ed {
        display: block;
        overflow: hidden;
        position: relative;
        font-size: 0;
        line-height: 0;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            background-image: none;
        }
    }*/
}
