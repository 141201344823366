@charset "UTF-8";

.c-iconnew {
    position: relative;
    display: block;
    overflow: hidden;
    text-align: center;

    &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: block;
        font-size: 12px;
        color: $color-gray-14;
        font-family: $font-family-lato;
        line-height: inherit;
        content: "N";
    }

    &--11red {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: $color-11st-red;

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: block;
            font-size: 12px;
            color: $color-gray-14;
            font-family: $font-family-lato;
            line-height: inherit;
            content: "N";
        }
    }

    &--11red-small {
        display: inline-block;
        width: 10px;
        height: 10px;
        line-height: 10px;

        &::before {
            color: $color-11st-red;
            font-size: 10px;
            font-family: $font-family-lato;
            content: "N";
        }
    }

    &--amazon {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: $color-amazon-bluegreen;

        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            display: block;
            font-size: 12px;
            color: $color-gray-14;
            font-family: $font-family-lato;
            line-height: inherit;
            content: "N";
        }
    }
}
