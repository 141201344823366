@charset "UTF-8";

// spinner
.c-spinner {
    @include loading-spinner;

    &-inner {
        @include loading-spinner-inner;
    }
}
