@import "../../common/mixins"; //믹스인모음

%pui-deal-card {
    //ie를 고려하여 :has(:not()) 으로 설정함.
    &:has(:not(.c-starrate)) {
        .c-card-item {
            &.c-card-item--deal {
                .c-card-item__info {
                    min-height: 122px;
                }
            }
        }
    }

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-flag-box {
        position: absolute;
        left: 20px;
        right: 20px;
        top: -11px;
        max-height: 25px;

        .c-flag {
            &__item {
                margin-right: 2px;
            }
        }
    }

    .c-addition {
        .c-addition-link {
            display: flex;
        }

        .c-addition-coupon {
            display: flex;
            flex-grow: 0;
            max-width: 50%;

            &__text {
                max-width: 100%;
            }
        }
    }

    .c-card-item {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:hover {
            .c-card-item__thumb {
                .c-lazyload {
                    img {
                        transform: scale(1.08);
                    }
                }
            }
        }

        &.c-card-item--deal {
            width: 100%;

            .c-card-item {
                &__thumb {
                    .c-lazyload {
                        img {
                            transition: transform 0.2s ease-in;
                        }
                    }
                }

                &__info {
                    flex-grow: 1;
                    min-height: 145px;
                    padding: 20px 0 18px 20px;

                    .c-card-item__qty {
                        bottom: 15px;
                    }

                    .c-starrate {
                        margin-top: 5px;
                    }
                }

                &__name {
                    margin: 0 20px 9px 0;
                }

                &__counsel {
                    margin: -5px 0 0 0;
                }

                &__price-info {
                    @include clearfix;
                    margin: 9px 0 6px 0;
                    padding: 0;

                    .c-card-item__rate {
                        margin-right: 8px;
                        font-size: 16px;
                        font-family: $font-family-lato;
                        line-height: 24px;

                        .value {
                            font-size: 24px;
                            vertical-align: baseline;
                        }
                    }

                    .c-card-item__price-special {
                        float: left;
                        width: auto;
                        margin-right: 8px;
                        font-size: 20px;
                        line-height: 24px;
                    }

                    .c-card-item__price {
                        float: left;
                        margin-right: 0;
                        font-family: $font-family-lato;
                        line-height: 24px;
                        font-size: 16px;

                        .value {
                            margin-bottom: 0;
                            font-size: 24px;
                            vertical-align: baseline;
                        }
                    }

                    .c-card-item__price-del {
                        float: left;
                        margin-left: 8px;
                        color: #a9a9a9;
                        font-family: $font-family-lato;
                        font-size: 13px;
                        line-height: 24px;

                        .value {
                            font-size: 16px;
                            vertical-align: baseline;
                        }
                    }

                    .c-card-item__discount {
                        display: flex;
                        &--point {
                            clear: both;
                            padding: 2px 0;
                            line-height: 17px;

                            .value {
                                margin-left: 2px;
                            }
                        }
                    }
                }

                &__benefit {
                    .benefit {
                        font-family: $font-family-lato;
                    }
                }

                &__extra {
                    min-height: 50px;
                    border-top: none;
                    z-index: 2;
                }

                &__relates {
                    margin-top: -10px;
                    padding: 14px 0 15px;
                    border-top: 1px solid $color-gray-12;
                }

                &__bottom-info {
                    position: relative;
                    width: 100%;
                    height: 49px;
                    border-top: 1px solid $color-gray-12;

                    &:first-of-type:last-of-type() {
                        position: absolute;
                        bottom: 0;
                        width: calc(100% - 42px);
                    }

                    .c-card-item__delivery {
                        display: flex;
                        padding: 14px 0 15px;
                        height: 49px;

                        .delivery {
                            @include clearfix();

                            &:first-child {
                                padding-left: 0px;
                            }

                            em {
                                float: left;
                                margin-right: 4px;
                            }

                            .shooting-delivery__text,
                            .delivery-text {
                                clear: none;
                                float: left;
                            }
                        }

                        .sktpass {
                            strong {
                                @include sr-only();
                            }
                        }

                        $c-card-shooting: (delivery, install, plus, fresh, seller);

                        //.shooting-delivery, .shooting-install
                        @each $iconKey in $c-card-shooting {
                            .shooting-#{$iconKey} {
                                display: flex;
                                align-items: center;
                                margin-top: 0;

                                strong {
                                    @include sr-only();
                                }

                                &::before {
                                    margin-top: 2px;
                                }

                                em {
                                    float: left;
                                    margin-left: 4px;
                                    color: $color-11st-blue;
                                    font-weight: normal;

                                    &::before {
                                        display: none;
                                    }
                                }

                                .shooting-delivery__text,
                                .delivery-text {
                                    margin-left: 4px;
                                    font-family: $font-family-lato;
                                }
                            }
                        }
                    }

                    .c-relates__icon {
                        margin-left: 4px;
                    }
                }
            }
        }
    }
}
