.c-system-notice {
    $component: &;
    padding: 140px 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: $font-family-lato;

    .c-button {
        //인수정보 너비 고정일 경우: font-size, padding, min-width, height, width, border-radius
        @include c-button-set(19px, 0 2px, 44px, 60px, 200px, 0);
    }

    &::before {
        display: block;
        width: 126px;
        height: 126px;
        margin-bottom: 30px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='126' height='126' fill='none'%3E%3Ccircle cx='63' cy='63' r='63' fill='%23F4F4F4' /%3E%3Cpath fill='%23fff' fill-rule='evenodd' stroke='%23333' d='M59.565 32.436c1.13-3.86 6.612-3.824 7.692.051L83 89H43l16.565-56.564ZM39 89h48v7H39v-7Z' clip-rule='evenodd' /%3E%3Cpath stroke='%23333330' d='M59.565 32.436c1.13-3.86 6.612-3.824 7.692.051L83 89H43l16.565-56.564Z' clip-rule='evenodd' /%3E%3Cpath stroke='%23333' d='M56 43h14M51 61h24.5M46.5 78.5h34' /%3E%3C/svg%3E");
        content: "";
    }
    &__header {
        color: #222;
        font-size: 20px;
        line-height: 30px;
    }
    &__message {
        margin-top: 6px;
        color: $color-gray-04;
        font-size: 18px;
        font-weight: 400;
        line-height: 27px;
        text-align: center;
    }
    &__footer {
        margin-top: 40px;
        display: flex;
        gap: 10px;
    }

    @at-root .pui-wrap--category .pui-wrap__body {
        #{$component} {
            padding: 105px 0 115px;
        }
    }
}
