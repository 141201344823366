[data-type="PC_Tab_BillboardAll"] {
    .c-tab {
        @include clearfix;
        width: 1240px;
        height: 61px;
        margin: 0 auto;
        position: relative;
        border-bottom: 1px solid $color-gray-11;

        &__container {
            display: flex;
            flex-wrap: wrap;
            width: 1240px;
        }

        &__item {
            position: relative;
            margin: 0 15px;
            padding: 17px 0 20px 0;

            &:before {
                position: absolute;
                left: -15px;
                top: 22px;
                width: 1px;
                height: 15px;
                background: $color-gray-10;
                content: "";
            }
            &:first-child {
                margin-left: 0;

                &:before {
                    display: none;
                }
            }

            &.active {
                &:after {
                    position: absolute;
                    left: 0;
                    bottom: -1px;
                    right: 0;
                    height: 2px;
                    background: $color-11st-red;
                    content: "";
                }
                button {
                    color: $color-11st-red;
                }
            }

            button {
                position: relative;
                font-size: 16px;
                color: #333;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__option {
            position: absolute;
            right: 0;
            top: 18px;
        }

        &__checkbox {
            display: inline-block;

            input {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
            }

            input:checked {
                & + .c-tab__checkbox-label:before {
                    border: 0;
                    background: $color-11st-red;
                }
                & + .c-tab__checkbox-label:after {
                    border-color: $color-gray-14;
                }
            }

            input:focus {
                & + .c-tab__checkbox-label:before {
                    box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                }
            }

            input:disabled {
                & + .c-tab__checkbox-label:before {
                    border: 0;
                    background-color: $color-gray-11;
                }
                & + .c-tab__checkbox-label:after {
                    border-color: $color-gray-14;
                }
            }
        }

        &__checkbox-label {
            position: relative;
            display: inline-block;
            height: 22px;
            padding-left: 31px;
            vertical-align: middle;
            line-height: 22px;
            font-size: 16px;
            color: $color-gray-04;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 22px;
                height: 22px;
                box-sizing: border-box;
                border: 1px solid $color-gray-09;
                background-color: $color-gray-14;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                left: 5px;
                display: block;
                width: 10px;
                height: 6px;
                border: 1px solid $color-gray-10;
                border-width: 0 0 1px 1px;
                transform: rotate(-45deg);
            }
        }
    }
}
