[data-type="PC_Banner_BillboardAll"] {
    .c-list {
        position: relative;
        width: 1240px;
        margin: 0 auto;

        &::before {
            position: absolute;
            left: 50%;
            top: 0;
            z-index: 1;
            width: 1px;
            height: 100%;
            background: #fff;
            content: "";
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            width: 1240px;
        }

        &__item {
            margin-top: 1px;
        }
    }
}
