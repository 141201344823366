@charset "UTF-8";
@import "../../../eui/sprites/sp_card_item.scss";

.c_list_card {
    position: relative;

    ul {
        @include clearfix;
    }

    li {
        position: relative;
        float: left;
        box-sizing: border-box;

        &:first-child {
            margin-left: 0;
        }
    }

    .c_adtext {
        position: absolute;
        left: 0;
        z-index: 10;
    }
    .c-starrate__sati {
        margin: 2px 3px 0 0;
    }

    .list_navigator {
        button {
            overflow: hidden;
            position: absolute;
            top: 50%;
            line-height: 300px;
            background: #fff;
            z-index: 10;

            &::before {
                position: absolute;
                left: 50%;
                top: 50%;
                margin: -9px 0 0 -5px;
                content: "";
            }
            &.previous {
                left: -25px;
            }
            &.next {
                right: -25px;
            }
            &:disabled {
                display: none;
            }
        }
    }

    %listnavigator {
        button {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            border-radius: 50%;
            box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);

            &.previous {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_left);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_left_on);
                    }
                }
            }
            &.next {
                &::before {
                    @include sprite-retina($sp_card_item_arrow_right);
                }
                &:hover {
                    &::before {
                        @include sprite-retina($sp_card_item_arrow_right_on);
                    }
                }
            }
            &:hover {
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
            }
        }
    }

    %boxhover {
        border-radius: 4px;
        box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.06), 0px 0px 1px 0px rgba(0, 0, 0, 0.28);

        &:hover {
            box-shadow: 0px 6px 32px -8px rgba(0, 0, 0, 0.2), 0px 0px 1px 0px rgba(0, 0, 0, 0.7);

            .c-card-item__thumb-img {
                img {
                    transform: scale(1.08);
                }
            }
        }
    }

    //deal Type
    &.c_list_card_deal {
        width: 1240px;

        .swiper-container,
        .c_slide_view {
            padding: 25px;
            margin: -25px;

            &.swiper_doing,
            &.c_slide_doing {
                margin-right: -3px;
                margin-left: -3px;
                padding-right: 3px;
                padding-left: 3px;
            }
        }

        .swiper-slide,
        .c_slide {
            margin-right: 29px;
        }

        li {
            &:not(:first-of-type) {
                margin-left: 29px;
            }
        }

        .c-card-item__soldout-text {
            &::before {
                width: 118px;
                height: 118px;
                background-size: 100% 100%;
            }
        }

        .list_navigator {
            @extend %listnavigator;
        }
    }

    //collection Type
    &.c_list_card_collection {
        width: 1240px;

        ul {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }

        li {
            float: none;
            vertical-align: top;
        }

        .swiper-container,
        .c_slide_view {
            padding: 25px;
            margin: -25px;

            &.swiper_doing,
            &.c_slide_doing {
                margin-right: -3px;
                margin-left: -3px;
                padding-right: 3px;
                padding-left: 3px;
            }
        }

        .swiper-slide,
        .c_slide {
            margin-right: 30px;

            li {
                &:first-of-type {
                    margin-left: 0px;
                }
            }
        }

        .c_adtext {
            top: 208px;
        }

        .c-nation {
            margin-bottom: 10px;
        }
        .c-card-item__soldout-text {
            &::before {
                width: 60%;
                height: 60%;
                background-size: 100% 100%;
            }
        }
        &.col_5 {
            .swiper-slide,
            .c_slide {
                margin-right: 30px;
            }

            li {
                flex: 0 1 224px;
                width: 224px;
                margin: 30px 0 0 30px;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    margin-top: 0;
                }
                &:nth-child(5n - 4) {
                    margin-left: 0;
                }
            }

            .c-card-item {
                width: 224px;
                border-radius: 4px;

                .c-card-item__thumb {
                    padding: 10px 10px 0 10px;
                }
                .c-card-item__thumb-img {
                    width: 204px;
                    height: 204px;

                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
                .c-card-item__soldout-text {
                    left: 10px;
                    top: 10px;
                    width: 204px;
                    height: 204px;
                }
                .c-card-item__info {
                    @include clearfix;
                    padding: 17px 0 17px 20px;
                    //height: 166px;
                }
                .c-time-flag {
                    margin: 0 0 8px 0;
                }
                .c-flag {
                    margin: 0 0 8px 0;
                }
                .c-card-item__name {
                    max-height: 3em;
                    padding: 0 20px 0 0;
                    margin: 0 0 8px 0;
                }
                .c-card-item__price-info {
                    margin: 0 0 11px 0;
                    padding: 0;

                    .c-card-item__lowest {
                        &-value {
                            clear: left;
                        }
                    }

                    .c-card-item__rate {
                        margin-right: 8px;
                    }
                    + .c-card-item__delivery {
                        padding-left: 0;

                        &:before {
                            display: none;
                        }
                    }
                }
                .c-starrate {
                    float: left;
                    line-height: 18px;
                    margin: 0 0 7px 0;

                    dd {
                        float: left;
                    }
                    .sr-only {
                        @include sr-only;
                    }
                }
                .c-card-item__delivery {
                    position: relative;
                    float: none;
                    clear: both;
                    padding: 0;

                    &:before {
                        display: none;
                    }
                    .allclub {
                        &:before {
                            float: left;
                            margin: 1px 2px 0 0;
                        }
                    }
                    .sktpass {
                        .c-card-item__point {
                            line-height: 18px;
                        }
                    }
                }

                .c-card-item__save {
                    display: block;
                    width: 100%;
                    height: 52px;
                    line-height: 52px;
                    text-align: center;
                    font-size: 17px;
                    color: $color-11st-red;

                    &:before {
                        display: inline-block;
                        margin: 12px 6px 0 0;
                        vertical-align: top;
                        overflow: hidden;
                        line-height: 200px;
                        content: "";
                    }
                }
            }

            .c_adtext {
                top: 208px;
            }
        }

        &.col_4 {
            .swiper-slide,
            .c_slide {
                margin-right: 30px;
            }

            li {
                flex: 0 1 287px;
                width: 287px;
                margin: 30px 0 0 30px;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 0;
                }
                &:nth-child(4n-3) {
                    margin-left: 0;
                }
            }

            .c-card-item {
                width: 287px;
                margin: 0 auto;

                .c-card-item__thumb-img {
                    overflow: hidden;
                    width: 268px;
                    height: 268px;
                    margin: 0 auto;

                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
                .c-card-item__soldout-text {
                    left: 10px;
                    top: 10px;
                    width: 268px;
                    height: 268px;
                }
                .c-card-item__info {
                    @include clearfix;
                    padding: 15px 20px 19px;
                }
                .c-time-flag {
                    margin: 0 0 8px 0;
                }
                .c-flag {
                    margin: 0 0 8px 0;
                }
                .c-card-item__name {
                    max-height: 3em;
                    line-height: 1.5;
                    margin: 0 0 8px 0;
                }
                .c-card-item__name-addition {
                    max-height: 1.5em;
                    margin: 0 0 8px 0;
                }
                .c-card-item__price-info {
                    margin: 0 0 10px 0;
                    padding: 0;

                    .c-card-item__rate {
                        margin-right: 8px;
                    }
                    + .c-card-item__delivery {
                        padding-left: 0;

                        &:before {
                            display: none;
                        }
                    }
                }
                .c-starrate {
                    dd {
                        float: left;
                    }
                }
                .c-card-item__delivery {
                    .sktpass {
                        .c-card-item__point {
                            line-height: 18px;
                        }
                    }
                }
            }
        }

        .list_navigator {
            @extend %listnavigator;
        }
    }

    //gallery Type
    &.c_list_card_gallery {
        width: 1240px;

        ul {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
        }

        li {
            float: none;
            vertical-align: top;

            &:nth-of-type(n + 100):nth-of-type(-n + 999) {
                .c-card-item__rank {
                    width: 50px;
                }
            }
        }
        .c-card-item {
            .c-nation {
                margin-bottom: 10px;
            }
            .c-card-item__info {
                .c-starrate {
                    margin: 0 0 8px 0;
                }
                .c-card-item__price-info {
                    margin-bottom: 11px;
                }
                .c-starrate + .c-card-item__price-info {
                    dt + .c-card-item__price {
                        margin-top: 2px;
                    }
                }
                .c-card-item__delivery {
                    .allclub {
                        line-height: 18px;
                    }
                }
            }
            .c-card-item__soldout-text {
                &::before {
                    width: 60%;
                    height: 60%;
                    background-size: 100% 100%;
                }
            }
        }

        &.col_5 {
            li {
                flex: 0 1 224px;
                width: 224px;
                margin: 30px 0 0 30px;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    margin-top: 0;
                }
                &:nth-child(5n - 4) {
                    margin-left: 0;
                }
            }

            .c-card-item {
                width: 224px;

                .c-card-item__thumb-img {
                    width: 204px;
                    height: 204px;
                }
                .c-card-item__soldout-text {
                    height: 204px;
                }
                .c-card-item__lowest {
                    &-value {
                        clear: left;
                    }
                }
            }
        }

        &.col_4 {
            li {
                flex: 0 1 287px;
                width: 287px;
                margin: 30px 0 0 30px;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    margin-top: 0;
                }
                &:nth-child(4n - 3) {
                    margin-left: 0;
                }
            }

            .c-card-item {
                width: 287px;

                .c-card-item__thumb-img {
                    width: 267px;
                    height: 267px;
                }
            }
        }
    }
}

.c_slide_view,
.swiper-container {
    overflow: hidden;
    width: 100%;
}

.c_slide_container,
.swiper-wrapper {
    @include clearfix;
}

.c_slide,
.swiper-slide {
    float: left;
}

.c_card_contents {
    .contents_info {
        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
    .contents_thumb {
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }
        .video {
            position: absolute;
            overflow: hidden;
            left: 50%;
            top: 50%;
            line-height: 200px;
            z-index: 10;
        }
    }
    .contents_name {
        overflow: hidden;
    }
    .contents_description {
        position: relative;
        overflow: hidden;

        .more {
            position: absolute;
            bottom: 1px;
            right: 0;

            &::before {
                position: absolute;
                height: 100%;
                right: 0;
                top: 0;
                content: "";
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 1px;
                margin-top: -3px;
            }
            span {
                position: relative;
                z-index: 5;
            }
        }
    }
    .contents_tag {
        overflow: hidden;

        a {
            float: left;

            &:first-child {
                margin-left: 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .contents_meta {
        overflow: hidden;
        box-sizing: border-box;

        dl {
            float: left;
            position: relative;
        }
        dt {
            float: left;
        }
        dd {
            float: left;
        }
        .writer {
            float: left;

            .thumb {
                float: left;
            }
            .name {
                overflow: hidden;
                float: left;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .date {
            font-family: $font-family-number-lato;
        }
        .count {
            font-family: $font-family-number-lato;
        }
    }
}

//ie9 버그해결 핵
@media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    //ie9 버그해결
    .c_list_card {
        &.c_list_card_collection {
            &.col_4 {
                li {
                    float: left;
                    margin-left: 26px !important;

                    &:first-of-type {
                        margin-left: 0 !important;
                    }

                    &:nth-child(4n - 3) {
                        margin-left: 0 !important;
                    }
                }
            }

            &.col_5 {
                li {
                    float: left;
                    margin-left: 26px !important;

                    &:first-of-type {
                        margin-left: 0 !important;
                    }

                    &:nth-child(5n - 4) {
                        margin-left: 0 !important;
                    }
                }
            }
        }
    }
}
