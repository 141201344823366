[data-type="PC_Banner_Promotion_Default"] {
    height: 100px;
    .c-banner {
        height: 100px;
        border-radius: 4px;

        &__link {
            display: block;
            height: inherit;
        }

        &__img {
            display: block;
            width: 880px;
            height: inherit;
            margin: 0 auto;
        }
    }
}
