[data-type="PC_Title_Promotion"] {
    width: 1240px;
    margin: 0 auto;

    .c-headline {
        display: flex;
        height: 100px;
        padding: 0 80px;
        border-radius: 4px;
        align-items: center;

        .c-headline__text {
            flex: 1 1 auto;
            min-width: 0;
        }

        &__subtext {
            @include text-ellipsis();
            color: $color-gray-14;
            font-size: 23px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
        }
    }
}
