[data-type="PC_Realtime_Sorting"] {
    min-height: 40px;

    .c-sorting {
        display: flex;
        
        &__float {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
        }
    }

    .c-selectbox {
        display: inline-block;
        position: relative;
        width: 130px;
        vertical-align: middle;
        margin-left: 10px;

        &:after {
            position: absolute;
            right: 16px;
            top: 50%;
            margin: -5px 0 0 -4px;
            @include ico-angle(down, 6px, 1px, $color-gray-02, static);
        }

        &.active {
            &:after {
                margin-top: -2px;
                @include ico-angle(up, 6px, 1px, $color-gray-02, static);
            }
            .c-selectbox__option {
                display: block;
                z-index: 10;
            }
        }

        &__selected {
            position: relative;
            min-width: 130px;
            height: 40px;
            line-height: 38px;
            padding: 0 35px 0 12px;
            text-overflow: ellipsis;
            font-size: 15px;
            color: $color-gray-03;
            border: 1px solid $color-gray-09;
            box-sizing: border-box;
            text-align: left;
            font-family: $font-family;
            cursor: pointer;
        }

        &__text {
            font-weight: 400;
        }

        &__option {
            display: none;
            position: absolute;
            left: 0;
            top: 39px;
            width: 100%;
            padding: 5px 0;
            box-sizing: border-box;
            border: 1px solid $color-gray-09;
            background: $color-gray-14;
        }

        &__item {
            display: block;
            width: 100%;
            padding: 6px 12px;
            color: $color-gray-03;
            box-sizing: border-box;
            font-size: 15px;
            text-align: left;
            cursor: pointer;

            &:hover,
            &.focused {
                text-decoration: underline;
            }
        }
    }
}
