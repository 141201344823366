@charset "UTF-8";

.c-netfunnel {
    width: 295px;
    padding: 16px;
    letter-spacing: 0;
    box-sizing: border-box;
    box-shadow: none;
    background-color: #fff;
    transform: translate(-50%, -50%);

    &__content {
        margin: 19px 0;
        color: $color-grayscale-04;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
    }
    &__title {
        padding-top: 45px;
        color: $color-grayscale-02;
        font-size: 18px;
        font-weight: bold;
        line-height: 22px;
        background: url('/img/common/netfunnel/ic_netfunnel_bg.png') no-repeat 50% 0;
        background-size: 126px 126px;
        box-sizing: border-box;
        text-align: center;

        &:before {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 auto 75px;
            background: url('/img/common/netfunnel/loading.gif') no-repeat 50% 0;
            background-size: 100%;
            transform: translateX(-3px);
        }
    }
    &__text {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
    }
    &__status {
        margin-top: 16px;
    }
    &__term,
    &__description {
        display: inline-block;
        color: $color-grayscale-03;
        font-weight: 700;
        vertical-align: top;
    }
    &__description {
        color: $color-11st-red;
    }
    &__description + &__term:before {
        content: "";
        display: inline-block;
        width: 1px;
        height: 12px;
        margin: -1px 7px 1px 3px;
        background-color: $color-grayscale-08;
        vertical-align: middle;
    }
}