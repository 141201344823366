@charset "UTF-8";

@import "../../common/sprites/_sp_close";
@import "../sprites/sp_seller_2x";
@import "../svg/sp_common.scss";
@import "../eui/component/c-button";
@import "../common/partial/component/info";

// today close layer
.close_wrap {
    height: 32px;
    border-top: 1px solid #e1e1e1;

    label {
        float: left;
        padding: 8px 0 0 10px;
        font-size: 11px;
        color: #757575;
        cursor: pointer;

        input {
            cursor: default;
        }
    }

    button {
        float: right;
        width: 50px;
        height: 24px;
        margin: 4px 10px 0 0;
        background: url("/img/popcmn/outpop_comimg02.gif") no-repeat 0 -76px;
        font-size: 11px;
        letter-spacing: -1px;
        color: #fff;
        text-align: left;
        text-indent: 10px;
        line-height: 24px;

        &:first-child {
            float: left;
            width: 130px;
            height: 14px;
            margin: 10px 0 0 10px;
            padding: 0 0 0 20px;
            background-position: 0 0px;
            line-height: 14px;
            text-align: left;
            color: #828282;
            text-indent: 0px;
        }
    }
}

// 토스트배너
.toastbnr_wrap {
    position: fixed;
    bottom: 0px;
    left: 50%;
    z-index: 1000;
    width: 286px;
    margin-left: 250px;
    border: 2px solid #465981;
    border-top: none;
    background: #fff;

    h3 button {
        display: block;
        width: 290px;
        height: 29px;
        margin: 0 -2px;
        border: 1px solid #3f527a;
        background: #465981 url("/img/default/icon/bul_arrdown_fff_03.gif") no-repeat 265px 12px;
        line-height: 27px;
        color: #fff;
        font-weight: bold;
        text-align: left;
        text-indent: 10px;
        font-size: 12px;
    }

    .toast_inner {
        padding: 10px;
        font-size: 12px;

        div {
            padding-bottom: 7px;
            color: #666;

            span {
                display: block;
                padding-bottom: 3px;
                color: #333;
                text-decoration: underline;
            }

            em {
                color: #ea0000;
                font-weight: bold;
            }

            img {
                width: 266px;
                height: 78px;
            }
        }
    }
}

// dimmed layer
.dimmest11_set {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.dimmest11 {
    display: none;
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    height: 100%;
    z-index: 6000;
    overflow: auto;
    background: url("/img/common/v2/bg_dimlayer.png") repeat;
}

.dimmest11_on {
    display: block;
}

.dimmest11 .dim_layer {
    position: absolute;
    left: 50%;
    top: 50%;
    background: #fff;
    .btn_close {
        position: absolute;
        right: 0px;
        top: 0px;
        width: 40px;
        height: 40px;
    }
}

// 공지 안내레이어
.dimmest11 .ly_notice {
    width: 540px;
    padding: 0 30px;
    background: #eee;
    font-family: $font-family-default;
    text-align: center;

    .btn_close {
        position: static;
        width: 299px;
        height: 60px;
    }
}

.ly_notice_titbox {
    margin: 0 -30px;
    padding: 52px 30px 70px;
    background: $color-11st-red;
    color: #fff;
    text-align: center;

    .ly_logo {
        display: inline-block;
        overflow: hidden;
        width: 70px;
        height: 30px;
        margin-bottom: 19px;
        background: url("/img/common/v2/sp_common_v2.png") no-repeat -366px -364px;
        font-size: 0;
        line-height: 200px;
    }

    .notice_tit {
        font-size: 36px;
        font-weight: normal;
        color: #fff;
        line-height: 40px;
        letter-spacing: -1px;
    }
}

.ly_notice_cont {
    margin-top: -30px;
    padding: 30px 15px;
    background: #fff;

    .notice_txt {
        margin-top: 21px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
        line-height: 24px;
        letter-spacing: -1px;

        &:first-child {
            margin-top: 0;
        }
    }

    .checking_txt {
        display: inline-block;
        position: relative;
        margin-top: 18px;
        padding-bottom: 2px;
        font-size: 19px;
        color: #111;

        .point {
            color: $color-11st-red;
        }

        .line {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 2px;
            background: #111;
        }
    }
}

.ly_notice_info {
    max-height: 238px;
    margin-top: -30px;
    padding: 20px 22px 0;
    background: #fff;
    overflow-y: auto;
    text-align: left;
    font-size: 12px;
    line-height: 20px;
    color: #333;

    .txt {
        margin-top: 18px;
        word-break: break-all;

        &:first-child {
            margin-top: 0;
        }
    }
    .txt2 {
        margin: 38px 0 27px;
    }
    .info_box {
        margin-top: 18px;
        padding: 16px 22px;
        border: 1px solid #f8e6e8;
    }
}

.ly_notice_btnbox {
    margin: 0 -30px;
    font-size: 0;

    .btn_ly {
        display: inline-block;
        width: 299px;
        height: 60px;
        font-size: 18px;
        font-weight: bold;
        color: #111;
        line-height: 60px;
        vertical-align: top;
    }

    .bar {
        display: inline-block;
        width: 1px;
        height: 30px;
        margin-top: 15px;
        background: #d8d8d8;
        vertical-align: top;
    }
}

// 비즈 11번가 사업자 회원 안내 팝업
.ly_biz11st {
    width: 560px;
    border: 1px solid #666;
    margin: -340px 0 0 -281px;

    .ly_tit {
        padding: 0 15px;
        height: 38px;
        border-bottom: 1px solid #dadada;
        line-height: 38px;
    }

    .tit {
        font-size: 16px;
        color: #333;
    }

    .ly_cont {
        position: relative;
        width: 560px;
        height: 606px;
        background: url("/img/popcmn/img_biz11st_popup.jpg") no-repeat;
    }

    .btn_area_biz {
        position: absolute;
        top: 375px;
        left: 50%;
        width: 350px;
        height: 40px;
        margin-left: -175px;
        font-size: 0;

        .btn {
            overflow: hidden;
            display: inline-block;
            width: 170px;
            height: 40px;
            line-height: 200px;

            &:hover {
                text-decoration: none;
            }
        }

        .btn_bizmain {
            margin-left: 10px;
        }
    }

    .close_wrap {
        height: 33px;
        border-top: 0;

        .lab {
            padding-left: 14px;
            font-size: 12px;
            color: #333;
        }

        .inp {
            margin-right: 3px;
        }
    }
}

// 베네피아 임직원 대상 경우 팝업
.ly-benefit {
    width: 500px;

    transform: translate(-50%, -50%);
    .bi-sk {
        margin: 0 0 24px;
        text-align: center;
        &::before {
            @include get-sprite-common("bi_sk", 110);
            content: "";
        }
    }
    .c-p-info {
        @include c-p-info(16px, $color-gray-03, 23px);
        &__sentence {
            margin-top: 6px;

            strong {
                font-size: 18px;
            }
        }
    }
    &__content {
        padding: 40px 30px;
    }
    &__bottom {
        padding: 0 30px 30px;
        text-align: center;

        .c-button {
            //font-size, padding, min-width, height, width, border-radius
            @include c-button-set(15px, 0 2px, 44px, 48px, 150px, 0);
            margin: 0 5px;
        }
    }
}

.dimmest11 .ly_biz11st .btn_close {
    width: 38px;
    height: 38px;
    background: url("/img/main/v2/sp_main.png") no-repeat -15px 0;
}

.dimmest11 .ly_return_confirm {
    width: 350px;
    padding: 30px;
    font-size: 15px;
    line-height: 23px;
    text-align: center;

    .text_emphasize {
        color: $color-grayscale-02;
        font-size: 18px;
        font-weight: bold;
        line-height: 1.5;

        & + .text {
            margin-top: 10px;
        }
    }

    .text {
        font-size: 15px;
        line-height: 23px;

        & + .btn_rewrite {
            margin-top: 30px;
        }
    }

    .text2 {
        margin: 30px 0 10px 0;
        color: #666;
    }

    .ico_sktpass {
        display: inline-block;
        margin: -2px 3px 0 0;
        @include get-sprite-common("bi_sktpass", 72);
        line-height: 200px;
        overflow: hidden;
        vertical-align: middle;
    }

    .link_skpass {
        display: block;
        margin: 20px auto 0;
        background: $color-sktpass-01;
        width: 350px;
        height: 48px;
        color: $color-grayscale-13;
        font-size: 15px;
        font-weight: bold;
        line-height: 48px;
    }

    .color_skpass {
        color: $color-sktpass-01;
    }

    .btn_rewrite {
        position: relative;
        display: block;
        width: auto;
        height: auto;
        margin: 0 auto;
        padding: 1px 13px 0 0;
        font-size: 15px;
        letter-spacing: 1px;
        font-weight: bold;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 7px;
            right: 3px;
            width: 6px;
            height: 6px;
            border: 1px solid #111;
            border-left: none;
            border-top: none;
            transform: rotate(-45deg);
        }
    }

    .btn_submit {
        width: 152px;
        height: 44px;
        margin-top: 28px;
        color: #fff;
        font-size: 13px;
        letter-spacing: -1px;
        background: $color-11st-red;
    }

    .btn_close {
        overflow: hidden;
        top: 24px;
        right: 24px;
        text-indent: -1000px;
        @include sprite($sp_close_btn_ly_close);
    }
}

.dimmest11 {
    .agree_popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: auto;
        padding-bottom: 32px;

        &.ly_tid {
            width: 536px;
            height: auto;
            padding: 0 32px 32px 32px;
            font-family: $font-family-default;
            font-size: 13px;
            line-height: 22px;
            color: #666;
            border: 1px solid #dddccc;

            .ly_tid_inner {
                padding: 20px 0 40px;
                font-size: 15px;
                color: #111;

                .tit_sub {
                    display: block;
                    margin: 20px 0 0;
                }

                .txt {
                    padding-left: 6px;
                    background: url("/img/common/icon/dul_dot3.gif") no-repeat 0 11px;
                }

                text {
                    color: #666;
                    line-height: 22px;

                    + .text {
                        margin-top: 10px;
                    }
                }

                dl {
                    margin-bottom: 25px;

                    dt {
                        margin-top: 25px;
                        font-weight: bold;
                    }

                    dd {
                        margin-top: 3px;
                    }
                }
            }

            .ly_tid_btn {
                text-align: center;

                .btn {
                    display: inline-block;
                    width: 120px;
                    height: 44px;
                    margin: 0 2px;
                    font-family: $font-family-default;
                    font-size: 15px;
                    line-height: 43px;
                    color: #fff;
                    border: 1px solid #f43412;
                    background: #f43412;
                }
            }

            .tit {
                font-size: 22px;
                line-height: 70px;
                color: #111;
                border-bottom: 1px solid #333;
            }

            .btn_close {
                overflow: hidden;
                top: 22px;
                right: 22px;
                background: url("/img/common/v2/ly_btn_close_big.png") no-repeat 50% 50%;
                line-height: 200px;
            }
        }
    }
}

.c_dialog_ctrl {
    overflow: hidden !important;
    touch-action: none;
}
