[data-type="PC_Title_Content_Img"] {
    height: 24px;
    .c-headline {
        display: flex;
        width: 100%;
        max-width: 1240px;
        border-radius: 4px;
        align-items: center;
        margin: 0 auto;

        &__text {
            flex: 0 1 auto;
            min-width: 0;
        }

        &__title {
            color: $color-gray-02;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }

        &__img {
            img {
                height: 24px;
                max-height: 24px;
            }
        }
    }

    .c-link-arrow {
        @include set-c-link-arrow(15px, $color-gray-04, 0 13px 0 0);
        position: absolute;
        right: 1px;
        top: 3px;
        z-index: 30;
    }

    .c-ad-box {
        @include set-c-ad-box(type1);
        margin-left: 8px;
    }
}
