@import "../../common/mixins";
@import "../../common/helpers/variables";
@import "../sprites/sp_star.scss";
@import "../svg/sp_common.scss";

.c-starrate {
    @include clearfix;

    dt {
        @include skip();
    }

    dd:first-of-type {
        .c-starrate__review {
            .sr-only {
                @include skip-reset();
                color: $color-gray-04;
            }
        }
    }

    .c-review-event + dd.c-starrate__review,
    dd.c-starrate__review:first-of-type {
        .sr-only {
            @include skip-reset();
            color: $color-gray-04;
        }
    }

    &.c-starrate--amazon {
        dt {
            @include skip-reset();
            @include get-sprite-common("bi_amazon_icon", 14);
            overflow: hidden;
            float: left;
            margin: 2px 4px 0 0;
            color: transparent;
        }
    }

    &.c-starrate--simply {
        &.c-starrate--amazon {
            dt {
                @include skip-reset();
                @include get-sprite-common("bi_amazon_icon", 12);
                overflow: hidden;
                float: left;
                margin: 3px 2px 0 0;
                color: transparent;
            }
        }

        .c-starrate__sati {
            display: flex;
            gap: 2px;
            align-items: center;
            width: auto;
            height: 18px;
            margin: 0;
            background: none;
        }

        .c-starrate__review {
            margin: 0 0 0 2px;
            color: $color-gray-06;
            font-size: 13px;
            font-family: $font-family-lato;
            line-height: 18px;

            .value {
                font-family: $font-family-lato;
            }

            a {
                color: $color-gray-06;
                font-size: 13px;
                line-height: 18px;
            }
        }
        
        .c-qty {
            display: flex;
            line-height: 17px;

            &:not(:first-of-type) {
                &::before {
                    display: block;
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    margin: 8px 4px;
                    background-color: $color-gray-10;
                    content: "";
                }
            }

            &__value {
                color: $color-gray-06;
                font-size: 13px;
            }
        }
    }
}

.c-starrate__sati {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 2px 4px 0 0;
    color: transparent;
    font-size: 10px;
    width: 64px;
    height: 12px;
    @include get-sprite-common("ic_star", 64);

    .c-starrate__gauge {
        position: absolute;
        top: 0;
        left: 0;
        @include get-sprite-common("ic_star_on", 64);
    }

    .c-starrate__title {
        @include get-sprite-common("ic_star_single", 12);
    }

    .c-starrate__text {
        color: $color-gray-02;
        font-family: $font-family-lato;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
    }
}

.c-starrate__review {
    float: left;
    color: $color-grayscale-04;
    font-size: 12px;
    letter-spacing: 0;

    .value {
        font-family: $font-family-number-lato;
    }
}
