[data-type="PC_Title_Brand"] {
    .c-headline {
        display: flex;
        width: 100%;
        padding-bottom: 25px;
        max-width: 1240px;
        border-radius: 4px;
        align-items: center;
        margin: 0 auto;

        .c-headline__text {
            flex: 0 1 auto;
            min-width: 0;
        }

        &__title {
            color: $color-gray-02;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }
    }

    .c-ad-box {
        @include set-c-ad-box(type1);
        margin-left: 8px;
    }
}
