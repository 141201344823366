@charset "UTF-8";

@import "../../common/sprites/_sp_ad_2x";

// 광고
.ad_nwlay {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    position: relative;
    z-index: 4;
    margin-left: 4px;
    vertical-align: bottom;

    button.ad_link {
        margin-bottom: 2px;
        *margin-top: -10px;
        *padding-bottom: 0;
        background-color: #fff;
        font-size: 12px;
        font-weight: bold;
        color: #666;

        &:hover {
            text-decoration: underline;
        }

        .ico {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-left: 2px;
            background: url("/img/common/v2/sp_common_v2.png") 0 0 no-repeat;
            vertical-align: -0.2em;
            *vertical-align: middle;
        }
    }

    &.han {
        *vertical-align: -0.5em;

        button.ad_link {
            padding-bottom: 1px;
            *padding-bottom: 0;

            .ico {
                vertical-align: -0.3em;
                *vertical-align: middle;
            }
        }
    }
}

// promotion layer
.help_pop {
    display: none;
    position: absolute;
    top: 20px;
    left: 0;
    z-index: 100;
    padding: 8px 8px 10px 8px;
    border: 1px solid #dadada;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
    background: #fff;
    font: normal 12px/1.2 $font-family-default;
    color: #666;

    p {
        white-space: nowrap;
    }

    .all_close {
        display: block;
        position: absolute;
        top: 13px;
        right: 7px;
        width: 12px;
        height: 12px;
        background: url("/img/common/v2/sp_common_v2.png") -19px 0 no-repeat;
    }
}

.help_ad {
    display: inline-block;
    position: relative;
    z-index: 4;
    vertical-align: bottom;

    .help_btn {
        display: block;
        overflow: hidden;
        width: 27px;
        height: 14px;
        margin-left: 4px;
        background: url("/img/default/icon/sp_ad.png") no-repeat 0 2px;
        line-height: 200px;

        &.txt_type {
            width: 15px;
        }
        &.box_type {
            width: 24px;
            height: 16px;
            background-position: 0 -14px;
        }
        &.ico_type {
            width: 14px;
            background-position: -15px 2px;

            & + .help_tooltip {
                top: auto;
                left: auto;
                right: -2px;
                bottom: 14px;
            }
        }
    }
    .help_tooltip {
        display: none;
        position: absolute;
        top: 18px;
        left: -4px;
        z-index: 100;
        min-width: 130px;
        padding: 12px;
        border: 1px solid #ccc;
        background: #fff;
        font-family: $font-family-default;
        font-size: 11px;
        line-height: 18px;
        color: #666;
        white-space: nowrap;
        text-align: left;
        letter-spacing: -1px;

        .tit {
            font-size: 12px;
            color: #333;
        }
    }
    .btn_tooltop_close {
        overflow: hidden;
        position: absolute;
        top: 6px;
        right: 6px;
        width: 20px;
        height: 20px;
        background: url("/img/default/icon/sp_ad.png") no-repeat -30px 0;
        line-height: 200px;
    }
    &.on {
        z-index: 5;

        .help_tooltip {
            display: block;
        }
    }
}

// 광고표기
.c_adtext {
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .adtext_button {
        display: inline-block;
        overflow: hidden;
        line-height: 300px;
        vertical-align: top;
    }

    .c_layer {
        top: 16px;
        left: -4px;
    }

    &.c_adtext_style_1 {
        .adtext_button {
            @include sprite-retina($sp_ad_2x_ad_text);
        }
    }
    &.c_adtext_style_2 {
        .adtext_button {
            @include sprite-retina($sp_ad_2x_ad_text);
            box-shadow: 0px 0px 0px 1px #cad6e7;
        }
    }
    &.c_adtext_style_3 {
        .c_ad_layer {
            top: 18px;
        }
        .adtext_button {
            @include sprite-retina($sp_ad_2x_ad_text_icon);
        }
    }
    &.c_adtext_style_4 {
        .adtext_button {
            @include sprite-retina($sp_ad_2x_ad_text_gray);
        }
    }
}

// 광고표기 레이어
.c_ad_layer {
    display: none;
    top: 16px;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0px rgba(0, 0, 0, 0.36);
    background-color: #fff;
    width: 274px;
    padding: 19px 44px 21px 20px;
    box-sizing: border-box;

    &.active {
        display: block;
    }

    .c_ad_layer_body {
        font-size: 15px;
        line-height: 22px;
        color: #111;
    }

    .c_ad_layer_info {
        dt {
            padding-bottom: 12px;
            color: $color-grayscale-02;
            font-size: 16px;
            font-weight: 700;
            line-height: 23px;
        }
        dd {
            color: $color-grayscale-02;
            font-size: 15px;
            line-height: 22px;
        }
    }

    .c_ad_layer_close {
        position: absolute;
        top: 10px;
        right: 10px;
        @include ico-cancel(13px, 1px, #111, 5px);
    }
}
