.c-review-event {
    $component: &;

    display: flex;
    align-items: center;

    &__icon {
        @include get-sprite-common("reviewevent", 70);
        display: block;
        flex-shrink: 0;
        overflow: hidden;
        font-size: 1px;
        color: transparent;
    }

    &__text {
        font-size: 12px;
        font-weight: 700;
        color: $color-secondary-11st-yellow;

        #{$component}__icon + & {
            position: relative;
            padding-left: 12px;
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: $color-gray-10;
            }
        }
    }

    @at-root .c-starrate {
        &__sati + #{$component},
        &__review + #{$component} {
            float: left;
            position: relative;
            margin-top: 0;
            padding-left: 11px;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 5px;
                transform: translateY(-50%);
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background-color: $color-gray-10;
            }
        }
    }
}
