[data-type="PC_Event_Popup"] {
    position: relative;
    z-index: 800;
    width: 1240px;
    margin: 0 auto;

    .c-event-popup {
        position: absolute;
        left: 50%;
        top: 0px;
        margin-left: -500px;
        padding: 9px;
        border: 1px solid $color-gray-03;
        background: $color-gray-14;
        box-sizing: border-box;

        img {
            width: 980px;
            height: 648px;
        }

        &__checkbox {
            font-size: 12px;
            cursor: pointer;

            input[type="checkbox"] {
                margin: -2px 4px 0 0;
            }
        }

        &__function {
            position: relative;
            height: 24px;
            padding: 8px 0 0 0;
        }

        &__close {
            position: absolute;
            right: 0;
            bottom: 0px;
            width: 55px;
            height: 23px;
            line-height: 21px;
            padding: 0 0 1px 19px;
            color: $color-gray-14;
            font-size: 12px;
            background-color: $color-gray-03;
        }

        &__icon {
            position: absolute;
            top: 50%;
            left: 6px;
            transform: translateY(-50%);
            @include ico-cancel-retina(18px, 4px, #fff, 0);
        }
    }
}
