[data-type="PC_Tab_Keyword_Fill"] {
    min-height: 60px;

    .c-scrollbar-horizontal {
        padding: 10px 0;
        width: $pui-width-fixed;
        background-color: $color-gray-14;
        overflow: hidden;
        overflow-x: auto;
        scroll-behavior: smooth;
        /* firefox 스크롤바의 색상을 지정합니다. */
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        /* firefox 스크롤바의 두께를 지정합니다. */
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px; /* 세로축 스크롤바의 두께 */
            height: 6px; /* 가로축 스크롤바의 높이 */
        }
        &::-webkit-scrollbar-track {
            background-color: transparent; /* 스크롤바의 트랙 색상 */
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px; /* 스크롤바의 손잡이 모양 */
            background-color: rgba(0, 0, 0, 0.2); /* 스크롤바의 손잡이 색상 */
        }
        &::-webkit-scrollbar-button {
            display: none; /* 스크롤바의 버튼을 숨김 */
        }
    }

    .w-magnet {
    }

    .c-magnet-multiple--fixed {
        position: fixed;
        right: 0;
        left: 0;
        top: $pui-fixed-top;
        z-index: 100;
        width: $pui-width-fixed;
        margin: 0 auto;
    }

    .c-list {
        @include clearfix;
        display: flex;
        margin-left: -5px;
        height: 40px;

        &__item {
            padding: 0 5px;
        }
    }

    .c-chip {
        height: 40px;
        margin: 0 0 0 10px;
        padding: 0 15px;
        border-radius: 20px;
        font-size: 15px;
        line-height: 40px;
        vertical-align: top;

        &:first-child {
            margin-left: 0;
        }

        &--selected {
            color: $color-gray-14;
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
            border: transparent;
        }
    }

    .c-pagination-slider {
        &__page {
            position: absolute;
            top: -48px;
            right: 0;
            color: $color-gray-09;
            font-family: $font-family-lato;
            font-size: 16px;
            line-height: 32px;
            vertical-align: middle;

            strong {
                color: $color-gray-02;
                font-size: 15px;
                font-weight: bold;

                &:after {
                    display: inline-block;
                    margin: 0 5px;
                    color: $color-gray-09;
                    font-family: $font-family;
                    font-weight: normal;
                    content: "/";
                }
            }
        }
        &__button {
            button {
                overflow: hidden;
                position: absolute;
                top: 50%;
                width: 28px;
                height: 28px;
                margin-top: -14px;
                line-height: 300px;
                border-radius: 50%;
                box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.28), 0px 0px 1px 0px rgba(0, 0, 0, 0.45);
                background: $color-gray-14;
                z-index: 10;

                &::before {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    content: "";
                }
                &:disabled {
                    display: none;
                }
                &:hover {
                    box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.4), 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
                }
            }
        }
        &__previous {
            left: -14px;

            &::before {
                @include get-sprite-common("ic-arrow-b-left-nor", 7);
                margin: -6px 0 0 -4px;
            }
            &:hover {
                &::before {
                    @include get-sprite-common("ic-arrow-b-left-on", 7);
                }
            }
        }
        &__next {
            right: -14px;

            &::before {
                @include get-sprite-common("ic-arrow-b-right-nor", 7);
                margin: -6px 0 0 -2px;
            }
            &:hover {
                &::before {
                    @include get-sprite-common("ic-arrow-b-right-on", 7);
                }
            }
        }
    }
}
