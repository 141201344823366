[data-type="PC_Billboard_All"] {
    .c-main-billboard {
        position: relative;
        max-width: $pui-width-fixed;
        height: 400px;
        margin: 0 auto;
        overflow: hidden;

        &__skipnavi {
            display: table;
            position: absolute;
            z-index: 10;
            width: 100%;

            a {
                display: block;
                overflow: hidden;
                width: 1240px;
                margin: 0 auto;
                height: 1px;
                margin-bottom: -1px;
                white-space: nowrap;
                text-align: center;
                text-indent: -9999px;

                &:focus,
                &:active {
                    height: 34px;
                    padding: 1px;
                    border-bottom: 2px solid #ebe8e7;
                    background: #666;
                    color: #333;
                    font-weight: bold;
                    font-size: 14px;
                    text-indent: 0;
                    line-height: 33px;
                }

                &:focus span,
                &:active span {
                    display: block;
                    height: 32px;
                    border: 1px solid #fff;
                    background: #e0e0e0;
                    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#f7f7f7));
                }
            }
        }

        &__visual {
            height: 100%;

            .swiper-container {
                width: $pui-width-fixed;
                height: 100%;
                transform: scale(1.0005);

                img {
                    height: 100%;
                    transform: scale(1.0005);
                }
            }
            .swiper-wrapper {
                display: flex;
                height: 100%;
            }
            .swiper-slide-active {
                z-index: 1;
            }
            .swiper-slide-prev {
                opacity: 0 !important;
            }
        }
    }
}
