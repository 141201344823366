@mixin set-c-ad-box($type) {
    position: relative;

    &__button {
        overflow: hidden;
        line-height: 200px;
    }

    @if $type == type1 {
        margin-top: 4px;
        &__button {
            @include get-sprite-common("ic_ad", 34);
        }
    } @else if $type == type2 {
        &__button {
            @include sprite-retina($sp_common_2x_ad_type2);
        }
    } @else if $type == type3 {
        &__button {
            @include get-sprite-common("ic_ad_type3", 24);
        }
    } @else if $type == type4 {
        &__button {
            @include sprite-retina($sp_common_2x_ad_type4);
        }
    }
}

// 광고표기
.c-ad-box {
    //@include set-c-ad-box(type1); 사용하는 영역에서 광고표기 타입을 선택
}

// 광고표기 레이어
.c-ad-layer {
    display: none;
    top: 18px;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 6px 24px -8px rgba(0, 0, 0, 0.12), 0 0 1px 0px rgba(0, 0, 0, 0.36);
    background-color: $color-gray-14;
    width: 274px;
    box-sizing: border-box;

    &--active {
        display: block;
        z-index: 1;
    }

    &__title {
        display: flex;
        padding: 16px 40px 16px 20px;
        border-bottom: 1px solid $color-gray-11;
        color: $color-gray-02;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;

        .c-link-arrow {
            margin-left: 8px;
        }
    }

    &__body {
        padding: 16px 44px 16px 20px;
        color: $color-gray-02;
        font-size: 15px;
        line-height: 22px;
    }

    &__sentence {
        color: $color-gray-02;
        font-size: 15px;
        line-height: 22px;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        @include ico-cancel(13px, 1px, #111, 5px);
    }
}
