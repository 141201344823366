[data-type="PC_Tab_ImgTextCircle_Sticky"] {
    width: $pui-width-fixed;
    height: 184px;
    margin: 0 auto;

    .c-magnet-multiple--fixed {
        position: fixed;
        right: 0;
        left: 0;
        top: $pui-fixed-top;
        z-index: 100;
        width: $pui-width-fixed;
        margin: 0 auto;
    }

    .c-scrollbar-horizontal {
        width: calc(#{$pui-width-fixed} - 44px);
        height: 53px;
        margin: 0 22px;
        background-color: $color-gray-14;
        overflow: hidden;
        overflow-x: auto;
        scroll-behavior: smooth;
        /* firefox 스크롤바의 색상을 지정합니다. */
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
        /* firefox 스크롤바의 두께를 지정합니다. */
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 6px; /* 세로축 스크롤바의 두께 */
            height: 6px; /* 가로축 스크롤바의 높이 */
        }
        &::-webkit-scrollbar-track {
            background-color: transparent; /* 스크롤바의 트랙 색상 */
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 3px; /* 스크롤바의 손잡이 모양 */
            background-color: rgba(0, 0, 0, 0.2); /* 스크롤바의 손잡이 색상 */
        }
        &::-webkit-scrollbar-button {
            display: none; /* 스크롤바의 버튼을 숨김 */
        }
    }

    .c-list {
        position: relative;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset;
        background: $color-gray-14;
        padding: 0 12px;

        &--center {
            .c-list__container {
                justify-content: center;
            }

            .c-list__item {
                flex-grow: 0;
            }
        }

        &__container {
            display: flex;
        }

        &__item {
            min-width: 101.333px;
            height: 130px;
            box-sizing: border-box;
            flex-grow: 1;
        }

        .c-pagination-slider {
            &__page {
                @include sr-only();
            }
        }
    }

    .c-tab {
        @include clearfix;

        position: relative;
        border: 1px solid $color-gray-11;
        border-top: none;
        background: $color-gray-14;

        &__container {
            display: flex;
            gap: 24px;
        }

        &__item {
            position: relative;
            line-height: 53px;
            &:first-child {
                margin-left: 0;
            }

            &.active {
                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background: $color-11st-red;
                    content: "";
                }
                button {
                    color: $color-11st-red;
                }
            }

            button {
                position: relative;
                font-size: 14px;
                color: $color-gray-03;
                white-space: nowrap;

                &:hover {
                    text-decoration: none;
                }
            }
        }

        &__option {
            position: absolute;
            right: 0;
            top: 18px;
        }

        &__checkbox {
            display: inline-block;

            input {
                position: absolute;
                width: 0;
                height: 0;
                opacity: 0;
            }

            input:checked {
                & + .c-tab__checkbox-label:before {
                    border: 0;
                    background: $color-11st-red;
                }
                & + .c-tab__checkbox-label:after {
                    border-color: $color-gray-14;
                }
            }

            input:focus {
                & + .c-tab__checkbox-label:before {
                    box-shadow: 0 0 5px 3px rgba(5, 127, 251, 0.5);
                }
            }

            input:disabled {
                & + .c-tab__checkbox-label:before {
                    border: 0;
                    background-color: $color-gray-11;
                }
                & + .c-tab__checkbox-label:after {
                    border-color: $color-gray-14;
                }
            }
        }

        &__checkbox-label {
            position: relative;
            display: inline-block;
            height: 22px;
            padding-left: 31px;
            vertical-align: middle;
            line-height: 22px;
            font-size: 16px;
            color: $color-gray-04;

            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 22px;
                height: 22px;
                box-sizing: border-box;
                border: 1px solid $color-gray-09;
                background-color: $color-gray-14;
            }

            &:after {
                content: "";
                position: absolute;
                top: 6px;
                left: 5px;
                display: block;
                width: 10px;
                height: 6px;
                border: 1px solid $color-gray-10;
                border-width: 0 0 1px 1px;
                transform: rotate(-45deg);
            }
        }
    }

    .c-icontext {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 68px;

        &__icon {
            position: absolute;
            top: 20px;
            width: 58px;
            height: 58px;

            img {
                width: 58px;
                height: 58px;
                border-radius: 50%;
            }
        }

        &__text {
            position: relative;
            display: inline-block;
            max-width: 100%;
            height: 61px;
            padding: 0 3px;
            margin-top: 0;
            color: $color-gray-03;
            font-size: 14px;
            line-height: 61px;
            overflow: hidden;
            box-sizing: border-box;
            word-break: break-all;
        }
    }

    %c-icontext {
        .c-icontext__text {
            color: $color-11st-red;
            font-weight: 700;

            &::after {
                position: absolute;
                bottom: 0;
                display: block;
                width: 100%;
                height: 2px;
                background-color: $color-11st-red;
                content: "";
            }
        }
    }

    .c-list__item {
        &.active {
            .c-icontext {
                @extend %c-icontext;
            }
        }

        .c-icontext {
            &:hover,
            &:focus {
                @extend %c-icontext;
            }
        }
    }
}
