[data-type="PC_ProductCard_Swipe"] {
    width: 1240px;
    min-height: 405px;
    margin: 0 auto;

    .c-list {
        .swiper-container {
            padding: 10px 25px 25px;
            margin: -10px -25px -25px;

            &.swiper_doing {
                margin-left: -3px;
                padding-right: 3px;
                padding-left: 3px;
            }

            //fe 개발 상용에 추가 되는 소스에 스타일링 추가
            & > div {
                display: flex;

                .swiper-slide,
                .slider-slide {
                    vertical-align: top;

                    > ul {
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .swiper-wrapper {
            display: flex;

            .swiper-slide,
            .slider-slide {
                vertical-align: top;

                > ul {
                    height: 100%;
                    box-sizing: border-box;
                }
            }
        }

        &--col3 {
            //각 PUI 블럭에서 셋팅: PC_ProductBox_Deal이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(394px, 28px, 3);
            .c-list__item {
                &:nth-of-type(2) {
                    padding-right: 15px;
                    padding-left: 15px;
                    width: 424px;
                }
            }

            .c-card-item {
                width: 394px;
            }
        }

        &--col2 {
            //각 PUI 블럭에서 셋팅: PC_ProductBox_Deal이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(605px, 30px, 2);
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }

    @extend %pui-deal-card;
}
