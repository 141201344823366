@import "../../common/helpers/variables";
@import "../../common/mixins";

.c-chip {
    display: inline-block;
    position: relative;
    height: 32px;
    margin: 0 6px 8px 0;
    padding: 0 12px;
    border: 1px solid $color-grayscale-08;
    border-radius: 16px;
    background: $color-grayscale-13;
    font-size: 14px;
    font-weight: normal;
    color: $color-grayscale-03;
    line-height: 30px;
    white-space: nowrap;
    box-sizing: border-box;
    outline: none;

    &--selected {
        border-color: rgba(244, 49, 66, 0.2);
        background-color: #fffbfb;
        font-weight: bold;
        color: $color-11st-red;
    }

    &[disabled],
    &--disabled {
        border-color: $color-grayscale-09;
        color: $color-grayscale-07;

        .delete {
            &:before,
            &:after {
                background-color: $color-grayscale-05;
                opacity: 0.2;
            }
        }
    }

    &-shape {
        .c-chip {
            border-radius: 0;
        }
    }

    &-input {
        a {
            display: block;
        }
        .c-chip {
            padding-right: 30px;
        }
        .delete {
            position: absolute;
            top: 0;
            right: 0;
            width: 28px;
            height: 30px;
            border: 0;
            border-radius: 50%;
            background-color: transparent;
            vertical-align: top;
            outline: none;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                right: 58%;
                left: auto;
                width: 1px;
                height: 12px;
                margin: -6px 0 0 -1px;
                background: $color-grayscale-05;
                transform: rotate(45deg);
                content: "";
            }

            &:after {
                transform: rotate(-45deg);
            }
        }
    }

    &-filter {
        .c-chip--selected {
            &:before {
                display: inline-block;
                width: 12px;
                height: 20px;
                margin: -3px 4px 3px 0;
                border: 1px solid $color-11st-red;
                border-width: 0 3px 3px 0;
                vertical-align: middle;
                transform: rotate(45deg) scale(0.5);
                content: "";
            }
        }
    }
}
