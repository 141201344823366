@import "../../common/mixins";

.c-layer-cart {
    display: none;
    position: absolute;
    right: -198px;
    bottom: 56px;
    min-width: 258px;
    padding: 16px 40px 20px 20px;
    box-shadow: 0px 6px 24px -8px rgba(0, 0, 0, 0.12), 0px 0px 1px 0px rgba(0, 0, 0, 0.36);
    background: $color-gray-14;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 10;

    &.active {
        display: block;
    }

    .c-button {
        //인수정보 너비 100%일 경우: font-size, padding, min-width, height, width 인자 없을 시 auto, border-radius
        @include c-button-set(14px, 0 2px, 44px, 36px, 100%, 0);
    }

    &::before {
        position: absolute;
        left: 15px;
        bottom: -9px;
        background-color: rgb(255, 255, 255);
        border-style: solid;
        border-color: #ddd;
        border-image: initial;
        border-width: 0 1px 1px 0;
        border-radius: 2px;
        width: 15px;
        height: 15px;
        transform: rotate(57deg) skew(20deg);
        content: "";
    }

    &__text {
        padding-bottom: 3px;
        color: $color-gray-02;
        font-size: 15px;
        line-height: 23px;
    }

    &__subtext {
        min-width: 210px;
        margin-top: -3px;
        color: $color-11st-blue;
        font-size: 13px;
        line-height: 23px;
    }

    &__button {
        width: 192px;
        margin-top: 10px;
    }

    &__close {
        button {
            position: absolute;
            top: 16px;
            right: 10px;
            @include ico-cancel(12px, 1px, $color-gray-02, 6px);
        }
    }
}
