[data-type="PC_ProductCard_Grid_PageSwipe"] {
    width: $pui-width-fixed;
    min-height: 930px;
    margin: 0 auto;

    .c-list {
        .swiper-container {
            padding: 10px 25px 25px;
            margin: -10px -25px -25px;

            &.swiper_doing {
                margin-left: -3px;
                padding-right: 3px;
                padding-left: 3px;
            }

            //fe 개발 상용에 추가 되는 소스에 스타일링 추가
            & > div {
                display: flex;

                .swiper-slide,
                .slider-slide {
                    vertical-align: top;

                    > ul {
                        //height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .swiper-wrapper {
            display: flex;

            .swiper-slide,
            .slider-slide {
                vertical-align: top;

                > ul {
                    box-sizing: border-box;
                }
            }
        }

        &--col3 {
            //각 PUI 블럭에서 셋팅: PC_ProductBox_Deal이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(394px, 28px, 3);

            .c-list__container {
                flex-wrap: wrap;
                width: 1268px;
                max-width: 1268px;
            }

            .c-list__item {
                //1page, 2page의 동일한 그리드 유지를 위해 카드 높이값이 고정되어야함.
                height: 463px;
                &:nth-of-type(n + 1):nth-of-type(-n + 3) {
                    height: 433px;
                }

                &:nth-of-type(3n-1) {
                    padding-right: 15px;
                    padding-left: 15px;
                    width: 424px;
                }
            }

            .c-card-item {
                width: 394px;
            }
        }
    }

    .c-pagination-indicator {
        margin-top: 30px;
    }

    //ie를 고려하여 :has(:not()) 으로 설정함.
    /*&:has(:not(.c-starrate)) {
        .c-list {
            &--col3 {
                .c-list__item {
                    height: 440px;
                    &:nth-of-type(n + 1):nth-of-type(-n + 3) {
                        height: 430px;
                    }
                }
            }
        }
    }*/

    @extend %pui-deal-card;
}
