[data-type="PC_ProductGrid_Swipe_Cap"] {
    width: 1240px;
    min-height: 292px;
    margin: 0 auto;

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-headline {
        position: relative;
        display: flex;
        width: 100%;
        max-width: $pui-width-fixed;
        border-radius: 4px;
        align-items: center;
        margin: 50px auto 25px;

        &:first-child {
            margin-top: 0;
        }

        &__text {
            flex: 0 1 auto;
            min-width: 0;
        }

        &__title {
            color: $color-gray-02;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }

        &__point {
            color: $color-11st-blue;
            font-weight: 700;
        }
    }

    .c-link-arrow {
        @include set-c-link-arrow(15px, $color-gray-04, 0 13px 0 0);
        position: absolute;
        right: 1px;
        top: 3px;
        z-index: 30;
    }

    .c-list {
        .swiper-container {
            padding: 10px 25px 25px;
            margin: -10px -25px -25px -25px;

            &.swiper_doing {
                margin-left: 0px;
                padding-right: 3px;
                padding-left: 3px;
            }

            //fe 개발 상용에 추가 되는 소스에 스타일링 추가
            & > div {
                display: flex;

                .swiper-slide,
                .slider-slide {
                    vertical-align: top;

                    > ul {
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .swiper-wrapper {
            display: flex;

            .swiper-slide,
            .slider-slide {
                vertical-align: top;

                > ul {
                    height: 100%;
                    box-sizing: border-box;
                }
            }
        }

        &--col6 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(182px, 30px, 6);
            .c-list__item {
                &:nth-of-type(6n-5) {
                    width: 211px;
                    padding-right: 14px;
                }
                &:nth-of-type(6n) {
                    width: 211px;
                    padding-left: 14px;
                }
            }
        }

        &--col5 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(224px, 30px, 5);
        }

        &--col4 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
            @include pui-card-list-swipe(287px, 30px, 4);
            .c-list__item {
                &:nth-of-type(4n-3) {
                    width: 318px;
                    padding-right: 16px;
                }
                &:nth-of-type(4n) {
                    width: 318px;
                    padding-left: 16px;
                }
            }
        }
    }

    .c-more {
        margin-top: 30px;
    }

    .slider-slide {
        vertical-align: top;
    }

    @extend %pui-card;

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }
}
