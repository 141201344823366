[data-type="PC_LNB_Category"] {
    width: 224px;
    margin: 0 auto;

    .c-headline {
        position: relative;
        padding-bottom: 38px;
        box-sizing: border-box;

        &__title {
            width: 100%;
            font-size: 20px;
            color: $color-gray-02;
        }
    }

    .c-filter {
        position: relative;
        padding: 15px 0;
        border-top: 1px solid $color-gray-11;

        &--active {
            .c-filter__button {
                &::before {
                    top: 10px;
                    @include ico-angle(up, 7px, 1px, $color-gray-02, static);
                }
            }
            .c-filter__content {
                display: block;
            }
        }

        &__title {
            position: relative;
        }

        &__text {
            font-size: 14px;
            line-height: 24px;
            color: $color-gray-02;
        }

        &__button {
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            line-height: 200px;

            &::before {
                position: absolute;
                right: 8px;
                top: 4px;
                @include ico-angle(down, 8px, 1px, $color-gray-02, static);
            }
        }

        &__content {
            display: none;
        }

        &__noti {
            padding: 9px 0 0 0;
            font-size: 13px;
            color: #222;
        }

        &__all {
            position: relative;
            display: block;
            margin-top: 12px;
            padding: 0 12px 0 0;
            font-size: 12px;
            color: $color-gray-07;

            &:after {
                position: absolute;
                top: 50%;
                right: 2px;
                width: 5px;
                height: 5px;
                margin-top: -3px;
                border: solid $color-gray-07;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }

        &__more {
            position: relative;
            display: block;
            margin-top: 12px;
            padding: 0 12px 0 0;
            font-size: 12px;
            color: $color-gray-07;

            &:after {
                position: absolute;
                top: 50%;
                right: 2px;
                width: 5px;
                height: 5px;
                margin-top: -4px;
                border: solid #666;
                border-width: 1px 1px 0 0;

                transform: rotate(135deg);
                content: "";
            }
        }
    }

    .c-category {
        margin-top: 12px;

        &__depth2 {
            margin: 5px 0 0 15px;
        }

        &__depth3 {
            margin: 5px 0 0 15px;
        }

        .c-list {
            &__item {
                &:not(:first-of-type) {
                    margin-top: 5px;
                }
            }

            .c_radiobox {
                display: inline-block;
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                vertical-align: top;
                white-space: nowrap;
                cursor: pointer;

                .text {
                    display: inline;
                    height: 23px;
                    padding-left: 0;
                    color: $color-gray-03;
                    font-size: 14px;
                    line-height: 23px;
                    vertical-align: top;

                    &:before {
                        display: none;
                    }
                }

                input:checked + .text {
                    color: $color-11st-red;
                    font-weight: bold;
                }
            }
        }
    }

    .c-filter-all {
        display: none;
        position: absolute;
        top: 48px;
        z-index: 50;
        width: 862px;
        padding: 0;
        background-color: $color-gray-14;
        border: solid 1px $color-gray-11;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        &--active {
            display: block;
        }

        &__title {
            border-bottom: 1px solid $color-gray-11;
        }

        &__text {
            padding: 30px 70px 29px;
            text-align: center;
            color: $color-gray-02;
            font-size: 20px;
        }

        &__close {
            position: absolute;
            top: 28px;
            right: 32px;

            @include ico-cancel(18px, 1px, $color-gray-07, 7px);
        }

        &__content {
            padding: 30px;
            box-sizing: border-box;
            overflow-y: auto;
            min-height: 190px;
            max-height: 450px;
        }

        &__bottom {
            padding: 16px 0;
            text-align: center;
            border-top: 1px solid $color-gray-11;
        }

        &__confirm {
            min-width: 91px;
            height: 48px;
            color: $color-gray-14;
            line-height: 40px;
            font-weight: 400;
            font-size: 15px;
            background-color: $color-11st-red;
            border-color: $color-11st-red;
        }

        .c-list {
            display: flex;
            flex-wrap: wrap;
            margin-top: -9px;

            .c_radiobox {
                cursor: pointer;

                > span:nth-of-type(1) {
                    font-size: 14px;
                    height: auto;
                    color: #222;
                    vertical-align: top;
                }
                .text {
                    overflow: hidden;
                    max-width: 140px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-left: 0;
                    color: #222;

                    &:before {
                        display: none;
                    }
                }
                .count {
                    display: inline-block;
                    color: #aaa;
                    vertical-align: middle;
                    font-family: $font-family-number-lato;
                    font-size: 11px;
                }
                &:hover {
                    .text,
                    .count {
                        text-decoration: underline;
                    }
                }
            }

            .c_checkbox {
                cursor: pointer;

                > span:nth-of-type(1) {
                    overflow: hidden;
                    max-width: 157px;
                    height: 18px;
                    padding-left: 23px;
                    font-size: 13px;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:before {
                        width: 18px;
                        height: 18px;
                    }
                    &:after {
                        top: 5px;
                        width: 8px;
                        height: 4px;
                    }
                }

                .text {
                    color: $color-gray-04;
                }
            }

            &__item {
                position: relative;
                width: 25%;
                height: 40px;
                padding: 9px 0;
                box-sizing: border-box;
            }
        }
    }

    .c-choice {
        &__item {
            margin-top: 7px;

            & > .c_checkbox {
                cursor: pointer;

                > span:nth-of-type(1) {
                    overflow: hidden;
                    max-width: 201px;
                    height: 18px;
                    padding-left: 23px;
                    font-size: 13px;
                    line-height: 18px;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:before {
                        width: 18px;
                        height: 18px;
                    }
                    &:after {
                        top: 5px;
                        width: 8px;
                        height: 4px;
                    }
                }

                .text {
                    color: #222;
                }
            }
        }
    }

    .c-choice-price {
        @include clearfix;
        margin: 10px 0 0 0;

        .c_input_item {
            float: left;
            width: 90px;
            height: 30px;
            padding: 0 10px;
            line-height: 28px;
            font-size: 12px;
            border: 1px solid #ddd;
            outline: none;

            &:focus {
                border: 1px solid $color-11st-red;
            }
        }

        &__item {
            float: left;

            &:not(:first-of-type) {
                &::before {
                    float: left;
                    width: 14px;
                    color: #222;
                    text-align: center;
                    line-height: 30px;
                    content: "~";
                }
            }
        }

        &__button {
            float: left;
            position: relative;
            width: 30px;
            height: 30px;
            margin: 0 0 0 0;
            border: 1px solid #ddd;
            border-left: none;
            overflow: hidden;
            line-height: 200px;

            &:after {
                @include get-sprite-common("ic_search_gray_24", 24);
                position: absolute;
                left: 3px;
                top: 2px;
                content: "";
            }

            &:focus,
            &.active {
                border-color: $color-11st-red;
                background-color: $color-11st-red;

                &:after {
                    @include get-sprite-common("ic_search_white_24", 24);
                }
            }
        }
    }

    .c-search {
        .c_radiobox {
            cursor: pointer;

            > span:nth-of-type(1) {
                overflow: hidden;
                max-width: 157px;
                height: 18px;
                padding-left: 23px;
                font-size: 13px;
                line-height: 18px;
                text-overflow: ellipsis;
                white-space: nowrap;

                &:before {
                    width: 18px;
                    height: 18px;
                }
                &:after {
                    top: 5px;
                    width: 8px;
                    height: 4px;
                }
            }

            .text {
                color: $color-gray-04;
            }
        }

        &__filler {
            display: flex;
            margin: 10px 0;
        }

        &__item {
            margin-right: 10px;
        }
        &__form {
            position: relative;
            margin-top: 20px;
            height: 30px;
            border: none;
        }
        &__input {
            width: 100%;
            height: 100%;
            padding: 0 30px 0 10px;
            font-size: 12px;
            border: 1px solid $color-gray-10;
            box-sizing: border-box;
            outline: 0;

            &:focus {
                border: 1px solid $color-11st-red;
            }
        }

        &__button {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 30px;
            height: 30px;
            overflow: hidden;
            line-height: 200px;
            border-left: 1px solid $color-gray-10;

            &:after {
                @include get-sprite-common("ic_search_gray_24", 24);
                position: absolute;
                top: 3px;
                left: 2px;
                content: "";
            }

            &:focus,
            &.active {
                background-color: $color-11st-red;

                &:after {
                    @include get-sprite-common("ic_search_white_24", 24);
                }
            }
        }
    }
}
