@import "../svg/sp_common.scss";
.c-nation {
    margin-bottom: 10px;

    dt {
        @include sr-only;
    }
    dd {
        min-height: 18px;
    }
    .amazon {
        @include sr-only-reset;
        @include get-sprite-common("bi_amazon", 54);
        display: inline-block;
        overflow: hidden;
        margin: 3px 0px -6px 0;
        color: transparent;
    }
    .nation {
        position: relative;
        display: inline-block;
        padding-left: 9px;
        font-size: 13px;
        color: #333;

        &:before {
            position: absolute;
            top: 50%;
            left: 4px;
            margin-top: -1px;
            width: 2px;
            height: 2px;
            background: #ddd;
            border-radius: 100%;
            content: "";
        }
    }
}
