@import "../../common/mixins"; //믹스인모음

%pui-card {
    .c-ad-box {
        @include set-c-ad-box(type3);
        position: absolute;
        left: 0;
        z-index: 3;

        .c-ad-layer {
            top: 16px;
            left: 0;
            width: 274px;

            &__body {
                font-size: 15px;
            }
        }
    }

    .c-card-item {
        display: flex;
        flex-direction: column;

        &__info {
            flex-grow: 1;
        }

        &__cart {
            align-self: flex-end;
        }

        &__thumb-emblem {
            top: 4px;
            left: 4px;

            img {
                width: 80px;
                height: 80px;
            }
        }

        &__rank {
            padding: 0 6px;
            border: none !important;

            &--impact {
                padding: 0 11px;
            }
        }

        &__soldout-text {
            z-index: 1;
        }

        &__anchor {
            z-index: 2;
        }

        &__price-info {
            @include clearfix();
            margin-top: 8px;
        }

        &__rate {
            float: left;
            margin: 0 8px 0 0;
            font-family: $font-family-lato;
            .value {
                font-size: 22px;
                line-height: 22px;
            }
        }

        &__price {
            display: inline-block;
            font-size: 15px;

            .value {
                font-family: $font-family-lato;
                font-weight: 900;
                vertical-align: baseline;
                font-size: 22px;
                line-height: 22px;
            }
        }

        &__price-per {
            clear: both;
            display: block;
            margin-top: 6px;
            font-size: 13px;
            line-height: 19px;
            font-family: $font-family-lato;
        }

        &__special {
            font-size: 14px;
        }

        &__price-special {
            & + .c-card-item__rate {
                clear: both;
            }
        }

        &__lowest {
            margin-bottom: 1px;
            font-size: 14px;
            line-height: 21px;
            font-family: $font-family-lato;

            &-value {
                margin: 0 4px 0 0;
                font-size: 14px;
            }

            .c-iconinfo--help {
                position: relative;
                z-index: 2;
                margin-left: -3px;

                @include size(17px);

                &:after {
                    @include get-sprite-common("ic_help", 17);
                }
            }
        }

        &__discount {
            display: flex;

            &--point {
                clear: both;
                padding: 3px 0 2px;
                line-height: 17px;

                .value {
                    margin-left: 2px;
                }
            }
        }

        &__delivery {
            .sktpass {
                display: flex;
                strong {
                    @include sr-only();
                }
                em {
                    margin-right: 4px;
                }
            }
            .delivery {
                @include clearfix();

                em {
                    float: left;
                    margin-right: 4px;
                }

                .shooting-delivery__text,
                .delivery-text {
                    clear: none;
                    float: left;
                }
            }
        }

        &.c-card-item--collection {
            .c-card-item__name {
                padding-right: 0;
            }

            .c-card-item__info {
                .c-card-item__price-info {
                    .c-card-item__price > .value {
                        font-weight: 900;
                    }
                }
            }

            .c-card-item__soldout-text {
                left: 10px;
                top: 10px;
                width: calc(100% - 20px);
                height: calc(100% - 10px);
            }

            .c-card-item__rank {
                top: 10px;
                left: 10px;
            }

            .c-ad-box {
                left: 10px;
            }
        }
    }

    .c-card-item__cart {
        position: relative;
        width: 100%;
        padding: 12px 20px 0;
        box-sizing: border-box;
        background-color: $color-gray-14;

        .c-card-item__amount {
            z-index: 2;
            width: calc(100% - 36px);
            height: 32px;
            border-radius: 4px;

            .c-card-item__quantity {
                padding: 0 32px;
                border-radius: 4px;
                line-height: 30px;
            }

            .c-card-item__remove {
                width: 32px;
                border-radius: 4px 0 0 4px;
                &:after {
                    margin-left: -7px;
                    width: 14px;
                    background: $color-gray-07;
                    transform: scaley(0.75);
                }
            }

            .c-card-item__add {
                width: 32px;
                border-radius: 0 4px 4px 0;
                &:before,
                &:after {
                    background: $color-gray-07;
                }

                &:before {
                    margin-top: -6px;
                    margin-left: -1px;
                    height: 12px;
                    transform: scalex(0.75);
                }

                &:after {
                    margin-left: -6px;
                    width: 12px;
                    transform: scaley(0.75);
                }
            }
        }

        .c-card-item__save {
            float: right;
            overflow: hidden;
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            line-height: 200px;
            border-left: none;
            border-left: 1px solid #eee;
            border-radius: 4px;

            &::before {
                display: none;
            }
        }

        .c-card-item__link {
            z-index: 1;
            height: 32px;
            line-height: 30px;

            &::after {
                top: 11px;
            }
        }
    }

    .shooting-delivery__text,
    .delivery-text {
        clear: both;
        display: block;
        line-height: 18px;
        font-family: $font-family-lato;
    }

    .c-layer-cart {
        right: auto;
        left: calc(100% - 58px);
        bottom: 48px;
    }

    .c-list--col6 {
        li {
            &:nth-of-type(6n) {
                .c-ad-box {
                    .c-ad-layer {
                        left: -92px;
                    }
                }

                .c-card-item--collection {
                    .c-ad-box {
                        .c-ad-layer {
                            left: -112px;
                        }
                    }
                }
            }
        }

        .c-ad-box {
            top: calc(182px - 16px);
        }

        .c-nation {
            margin-bottom: 10px;
        }

        .c-card-item {
            width: 182px;
            height: 100%;

            &:hover {
                .c-card-item__thumb {
                    .c-lazyload {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }

            &--box {
                padding: 0 0 20px 0;
            }

            &--collection {
                .c-ad-box {
                    top: calc(182px - 26px);
                }

                .c-card-item__soldout-text {
                    &::before {
                        width: 140px;
                        height: 140px;
                        background-size: 140px 140px;
                    }
                }

                .c-card-item__info {
                    padding: 18px 20px 0 20px;
                }
            }

            &__info {
                @include clearfix;
                padding: 18px 20px 0 20px;
            }

            &__soldout-text {
                &::before {
                    width: 160px;
                    height: 160px;
                    background-size: 160px 160px;
                }
            }

            &__thumb {
                border-radius: 4px 4px 0 0;
                overflow: hidden;

                .c-lazyload {
                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            &__title {
                overflow: hidden;
                height: 22px;
                font-size: 15px;
                color: $color-11st-blue;
                text-align: center;
            }

            &__tag {
                overflow: hidden;
                height: 22px;
                font-size: 15px;
                margin-top: 3px;
                text-align: center;
            }

            &__name {
                max-height: 3em;
                margin: 0 0 8px 0;
                line-height: 22px;
            }

            &__counsel {
                margin-top: 10px;
                font-weight: normal;
                font-size: 15px;
                color: $color-11st-blue;
            }

            &__price-info {
                margin: 0 0 11px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .c-card-item__price-special {
                    float: left;
                    width: auto;
                    margin-right: 5px;
                    font-size: 20px;
                }
            }

            &__delivery {
                .delivery {
                    //overflow: hidden;
                    //max-height: 18px;
                    &::before {
                        display: none;
                    }
                }
            }

            .c-flag-box {
                margin-bottom: 4px;
                max-height: 25px;
            }

            .c-time-flag {
                margin: 0 0 8px 0;
            }

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-starrate {
                line-height: 18px;
                margin: 0 0 4px 0;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            &__rank--impact {
                @include get-sprite-common("flag_best", 59);
            }
        }
    }

    .c-list--col5 {
        li {
            &:nth-of-type(5n) {
                .c-ad-box {
                    .c-ad-layer {
                        left: -50px;
                    }
                }

                .c-layer-cart {
                    right: 15px;
                    left: auto;

                    &::before {
                        left: auto;
                        right: 12px;
                    }
                }

                .c-card-item--collection {
                    .c-ad-box {
                        .c-ad-layer {
                            left: -70px;
                        }
                    }
                }
            }
        }

        .c-ad-box {
            top: calc(224px - 16px);
        }

        .c-nation {
            margin-bottom: 10px;
        }

        .c-card-item {
            width: 224px;
            height: 100%;

            &:hover {
                .c-card-item__thumb {
                    .c-lazyload {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }

            &--box {
                padding: 0 0 20px 0;
            }

            &--collection {
                .c-ad-box {
                    top: calc(224px - 26px);
                }

                .c-card-item__info {
                    padding: 16px 20px 0 20px;
                }
            }

            &__info {
                @include clearfix;
                padding: 20px 20px 0 20px;
            }

            &__thumb {
                border-radius: 4px 4px 0 0;
                overflow: hidden;

                .c-lazyload {
                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            &__name {
                max-height: 3em;
                margin: 0 0 8px 0;
                line-height: 22px;
            }

            &__counsel {
                margin-top: 10px;
                font-weight: normal;
                font-size: 15px;
                color: $color-11st-blue;
            }

            &__price-info {
                margin: 0 0 11px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .c-card-item__price-special {
                    float: left;
                    width: auto;
                    margin-right: 5px;
                    font-size: 20px;
                }
            }

            &__delivery {
                .delivery {
                    //overflow: hidden;
                    //max-height: 18px;
                    &::before {
                        display: none;
                    }
                }
            }

            .c-flag-box {
                margin-bottom: 4px;
                max-height: 25px;
            }

            .c-time-flag {
                margin: 0 0 8px 0;
            }

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-starrate {
                line-height: 18px;
                margin: 0 0 4px 0;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            &__rank--impact {
                @include get-sprite-common("flag_best", 59);
            }
        }
    }

    .c-list--col4 {
        li {
            &:nth-of-type(4n) {
                .c-layer-cart {
                    right: 15px;
                    left: auto;

                    &::before {
                        left: auto;
                        right: 12px;
                    }
                }
            }
        }

        .c-ad-box {
            top: calc(287px - 16px);
        }

        .c-nation {
            margin-bottom: 10px;
        }

        .c-card-item {
            width: 287px;
            height: 100%;

            &:hover {
                .c-card-item__thumb {
                    .c-lazyload {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }

            &--box {
                padding: 0 0 20px 0;
            }

            &--collection {
                .c-ad-box {
                    top: calc(287px - 26px);
                }
                .c-card-item__info {
                    padding: 16px 20px 0 20px;
                }
            }

            &__info {
                @include clearfix;
                padding: 20px 20px 0 20px;
            }

            &__thumb {
                border-radius: 4px 4px 0 0;
                overflow: hidden;

                .c-lazyload {
                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            &__name {
                max-height: 3em;
                margin: 0 0 8px 0;
                line-height: 22px;
            }

            &__counsel {
                margin-top: 10px;
                font-weight: normal;
                font-size: 15px;
                color: $color-11st-blue;
            }

            &__price-info {
                margin: 0 0 11px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .c-card-item__price-special {
                    float: left;
                    width: auto;
                    margin-right: 5px;
                    font-size: 20px;
                }
            }

            &__delivery {
                .delivery {
                    //overflow: hidden;
                    //max-height: 18px;
                    &::before {
                        display: none;
                    }
                }
            }

            .c-flag-box {
                margin-bottom: 4px;
                max-height: 25px;
            }

            .c-time-flag {
                margin: 0 0 8px 0;
            }

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-starrate {
                line-height: 18px;
                margin: 0 0 4px 0;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            &__rank--impact {
                @include get-sprite-common("flag_best", 59);
            }
        }
    }
}
