@charset "UTF-8";

// skipnavi
@if ($pageStatus == 'main') {

.skip_nav {
    @include skipNavi;
}

} @else {

#skipNavi {
    @include skipNavi;
}

}
