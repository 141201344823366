@charset "UTF-8";

$pageStatus: "general";
// Layout & Grid - 최상위에 위치해야함 clearfix 믹스인 이슈
@import "foundation";
@import "../common/helpers/settings";
@import "../common/helpers/grid";
@import "../common/helpers/grid_mixin";

@import "../common/mixins"; //믹스인모음
@import "../common/keyframes"; //믹스인모음
@import "../common/helpers/variables"; //변수모음
@import "../common/helpers/copyright"; //copyright
@import "../common/helpers/reset"; //스타일 초기화

// default.css와 같이 사용
@import "../common/partial/ie_end_support"; //IE 지원 종료
@import "../common/partial/skipnavi"; //스킵네비
@import "../common/partial/common"; //공통기능클래스
@import "../common/partial/button"; //공통버튼
@import "../common/partial/adlay"; //광고+도움말레이어
@import "../common/partial/header"; //공통헤더
@import "../common/partial/footer"; //공통푸터
@import "../common/partial/banner"; //공통배너, 윙배너
@import "../common/partial/layer_collection"; //공통레이어
@import "../common/partial/listing"; //공통 상품정보, 리스팅
@import "../common/partial/myhistory"; //공통 마이히스토리
@import "../common/partial/c-netfunnel"; //공통 넷퍼넬 팝업
@import "../common/partial/lazyload";
// -- default.css와 같이 사용

@import "../common/partial/system_notice";
@import "../common/partial/main_event_layer";

@import "../common/animation";
@import "../common/partial/component/list";
@import "../common/partial/component/common_spinner";
@import "../common/partial/component/c-link";
@import "../common/partial/component/c-list-card";
@import "../common/partial/component/c-pagination";
@import "../common/partial/component/c-time-count";
@import "../common/partial/component/c-iconnew";
@import "../common/partial/component/c-timeline";
@import "../common/partial/component/form";

@import "../eui/component/c-button";
@import "../eui/component/c-flag";
@import "../eui/component/c-starrate";
@import "../eui/component/c-card-item";
@import "../eui/component/c-ad-box";
@import "../eui/component/c-layer";
@import "../eui/component/c-nation";
@import "../eui/component/c-icontext";
@import "../eui/component/c-more";
@import "../eui/component/c-chip";
@import "../eui/component/c-checkbox";
@import "../eui/component/c-addition";
@import "../eui/component/c-refresh";
@import "../eui/component/c-selectbox";
@import "../eui/component/c-iconinfo";
@import "../eui/component/c-review-event";

@import "../pui/partial/pui-layout";
@import "../pui/partial/pui-deal-card";
@import "../pui/partial/pui-card";

@import "../pui/component/c-header";

@import "../pui/block/PC_Banner_BillboardAll";
@import "../pui/block/PC_Banner_Brand";
@import "../pui/block/PC_Banner_Col2";
@import "../pui/block/PC_Banner_Iframe_Col2";
@import "../pui/block/PC_Banner_Promotion_Default";
@import "../pui/block/PC_Banner_Promotion";
@import "../pui/block/PC_Billboard_All";
@import "../pui/block/PC_Content_TimeLine";
@import "../pui/block/PC_Event_Popup";
@import "../pui/block/PC_GridList_AllServices";
@import "../pui/block/PC_GridList_ImgText_CircleBox";
@import "../pui/block/PC_GridList_Partners";
@import "../pui/block/PC_LNB_Category";
@import "../pui/block/PC_MenuGoto";
@import "../pui/block/PC_Notice";
@import "../pui/block/PC_Realtime_Sorting";
@import "../pui/block/PC_ProductBox_Category_Col4";
@import "../pui/block/PC_ProductBox_Col5";
@import "../pui/block/PC_ProductBox_No_Result";
@import "../pui/block/PC_ProductCard_Grid_PageSwipe";
@import "../pui/block/PC_ProductCard_Grid";
@import "../pui/block/PC_ProductCard_NewDealBox";
@import "../pui/block/PC_ProductCard_Swipe";
@import "../pui/block/PC_ProductGrid_Basic";
@import "../pui/block/PC_ProductGrid_Swipe";
@import "../pui/block/PC_ProductGrid_Swipe_Cap";
@import "../pui/block/PC_ProductList_Round_Col4";
@import "../pui/block/PC_ProductList_Time_Swipe";
@import "../pui/block/PC_RoundButton_Center_More";
@import "../pui/block/PC_Sub_Sorting";
@import "../pui/block/PC_Tab_BillboardAll";
@import "../pui/block/PC_Tab_ImgText";
@import "../pui/block/PC_Tab_ImgTextCircle_Sticky";
@import "../pui/block/PC_Tab_Keyword";
@import "../pui/block/PC_Tab_Keyword_Fill";
@import "../pui/block/PC_Tab_Meta";
@import "../pui/block/PC_Title_Brand";
@import "../pui/block/PC_Title_Content_Default";
@import "../pui/block/PC_Title_Content_Img";
@import "../pui/block/PC_Title_Content_Text";
@import "../pui/block/PC_Title_Promotion";
@import "../pui/block/PC_Title_RecoContent_Text";
@import "../pui/block/PC_Title_SubTop";

// Layout
#wrapBody {
    min-width: 1280px;
}
.l_body {
    #layBodyWrap {
        //layBodyWrap 다른 개편 안된 페이지와 동일한 스타일
        position: relative;
        z-index: 20;
        width: 100%;
        outline: none;
    }
}

.l_content {
    position: relative;
    min-height: 300px;
}

.l-carrier {
    width: 1240px;
    margin: 0 auto;

    &--wide {
        width: 100%;
        margin: 0;
    }
}
