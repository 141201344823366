[data-type="PC_Notice"] {
    max-width: 1240px;
    min-height: 26px;
    margin: 0 auto;

    .c-notice {
        display: flex;
        justify-content: center;
        align-items: center;

        &__item {
            height: 26px;
        }

        &__title {
            position: relative;
            width: 73px;
            padding-right: 10px;
            margin-right: 25px;
            color: $color-gray-02;
            font-size: 17px;
            font-weight: 700;

            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                width: 5px;
                height: 5px;
                margin-top: -2px;
                border: solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }
        &__notice {
            color: $color-gray-04;
            font-size: 15px;
            text-align: left;
            line-height: 26px;
        }
        &__date {
            margin-left: 20px;
            font-size: 15px;
            color: $color-gray-07;
            font-family: $font-family-lato;
            line-height: 26px;
        }
    }

    .c_main_notice {
        position: relative;
        margin: 0 0 25px 0;

        .content {
            margin: 0 0 0 97px;
            font-size: 15px;

            .date {
                margin-left: 20px;
                font-size: 15px;
                color: #999;
                font-family: $font-family-number-lato;
            }
        }
    }
}
