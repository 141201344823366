[data-type="PC_ProductCard_Grid"] {
    width: 1240px;
    min-height: 848px;
    margin: 0 auto;

    .c-list {
        &--col3 {
            //각 PUI 블럭에서 셋팅: PC_ProductBox_Deal 아이템템너비, 아이템간 상하좌우간격, 컬럼수
            @include pui-card-list(394px, 28px, 3);

            .c-list__container {
                flex-wrap: wrap;
                width: 1268px;
                max-width: 1268px;
            }

            .c-list__item {
                &:nth-of-type(3n-1) {
                    padding-right: 15px;
                    padding-left: 15px;
                    width: 424px;
                }
            }

            .c-card-item {
                width: 394px;
            }
        }
    }

    .c-more {
        margin-top: 30px;
    }

    @extend %pui-deal-card;
}
