[data-type="PC_ProductBox_Category_Col4"] {
    width: 1240px;
    margin: 0 auto;

    .c-flag-box {
        margin-top: 4px;
    }

    .c-card-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__delivery {
            .delivery {
                em {
                    display: block;
                }
            }

            [class^="shooting-"] {
                em {
                    line-height: 18px;
                    margin-left: 4px;
                    margin-top: 0.5px;
                }

                .c_card_text {
                    clear: both;
                    display: block;
                    line-height: 18px;
                }
            }

            @include clearfix();
        }

        &__benefit {
            margin-top: 2px;
        }

        &__advertise {
            margin-top: 2px;
        }
    }

    .c-card-item__cart {
        position: relative;
        width: 100%;
        padding: 20px 20px 0;
        box-sizing: border-box;
        background-color: $color-gray-14;

        .c-card-item__amount {
            width: calc(100% - 50px);

            .c-card-item__quantity {
                padding: 0 40px;
            }

            .c-card-item__remove {
                &:after {
                    margin-left: -7px;
                    width: 13px;
                    background: $color-gray-07;
                }
            }

            .c-card-item__add {
                &:before,
                &:after {
                    background: $color-gray-07;
                }

                &:before {
                    margin-top: -7px;
                    height: 14px;
                }

                &:after {
                    margin-left: -6px;
                    width: 14px;
                }
            }
        }

        .c-card-item__save {
            border-left: 1px solid #eee;
        }
    }

    .c_list_card {
        &.c_list_card_gallery {
            &.col_4 {
                .c-card-item {
                    &--box {
                        padding: 0 0 20px 0;
                    }

                    &__thumb-img {
                        width: 287px;
                        height: 287px;
                        border-radius: 4px 4px 0 0;
                    }

                    &__info {
                        padding: 16px 20px 0px;

                        .c-card-item__name {
                            margin-bottom: 6px;
                        }

                        .c-card-item__price-info {
                            margin-bottom: 7px;
                        }

                        .c-flag-box {
                            margin-bottom: 4px;
                        }
                    }

                    &__rate {
                        line-height: 22px;
                        margin-right: 4px;
                        .value {
                            line-height: 22px;
                        }
                    }
                }

                li:nth-child(4n) {
                    .c-layer-cart {
                        right: 15px;

                        &::before {
                            left: auto;
                            right: 15px;
                        }
                    }
                }
            }
        }
    }
}
