[data-type="PC_Title_SubTop"] {
    .c-headline {
        display: flex;
        width: 100%;
        padding: 30px 0;
        max-width: 1240px;
        border-radius: 4px;
        align-items: center;
        margin: 0 auto;

        .c-headline__text {
            display: flex;
            flex: 0 1 auto;
            min-width: 0;
            flex-grow: 1;
        }

        &__subtext {
            margin-left: 10px;
            line-height: 30px;
            font-size: 17px;
        }

        &__title {
            color: $color-gray-02;
            font-size: 28px;
            font-weight: 700;
            line-height: 30px;
        }

        &--line {
            border-bottom: 1px solid $color-gray-11;
        }
    }
}
