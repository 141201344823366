@charset "UTF-8";

@import "../../common/sprites/sp_footer_2x";

// MPSR-59739 런칭 후 삭제예정 시작
// [FOOTER]
.footer_wrap3 {
    background: #fafafa;
    color: #333;
    font: 14px/1 $font-family-default;
    letter-spacing: -1px;

    &.no_toolbar {
        padding-bottom: 0;
    }

    button {
        color: #333;
    }

    .f_menu {
        padding: 12px 0;
        background: #f2f2f2;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
        line-height: 22px;
    }

    .btn_small {
        display: inline-block;
        padding: 0 8px;
        border: 1px solid #dadada;
        background-color: #fafafa;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        -o-border-radius: 1px;
        -ms-border-radius: 1px;
        border-radius: 1px;
        font-size: 11px;
        color: #666;
        line-height: 18px;
        vertical-align: middle;
    }

    a.btn_small:hover {
        text-decoration: none;
    }

    .info_conts,
    .add_conts,
    .award_conts {
        width: 1200px;
        margin: 0 auto;
    }

    .f_info {
        position: relative;
        width: 1200px;
        min-height: 220px;
        margin: 0 auto;
        padding-top: 30px;

        .ico {
            position: absolute;
            right: 5px;
            top: 6px;
            width: 3px;
            height: 5px;
            background: url("/img/common/v2/sp_footer.png") -205px -44px no-repeat;
        }

        a {
            &.btn_small.arr {
                position: relative;
                margin: -1px 0 -1px 10px;
                padding-right: 14px;
                line-height: 17px;
                vertical-align: top;
            }

            &.btn_f_arr {
                display: inline-block;
                position: relative;
                margin-left: 6px;
                padding-right: 13px;
                font-size: 12px;
                line-height: 17px;
                color: #999;
                vertical-align: top;

                .ico {
                    background-position: -218px -44px;
                }
            }
        }

        .add_sk {
            position: relative;
            float: left;
            width: 468px;
            height: 90px;
            margin-bottom: 22px;
            padding-left: 104px;

            dl {
                padding-left: 20px;
                border-left: 1px solid #dadada;
            }
        }

        .add_center {
            float: left;
            width: 311px;
            height: 90px;
            margin-bottom: 22px;
            padding: 0 20px;
            border-left: 1px solid #dadada;
        }

        .add_ele {
            float: left;
            width: 248px;
            height: 90px;
            margin-bottom: 22px;
            padding-left: 20px;
            border-left: 1px solid #dadada;
        }

        dt {
            margin-bottom: 4px;
            font-weight: bold;
            color: #333;
            line-height: 18px;
        }

        dd {
            color: #666;
            font-size: 13px;
            line-height: 17px;
            zoom: 1;

            a {
                color: #666;
            }

            span.pd {
                padding-left: 12px;
            }
        }

        .logo {
            position: absolute;
            left: 0;
            top: 27px;
            width: 84px;
            height: 36px;
            background: url("/img/common/v2/sp_footer.png") 0 0 no-repeat;
        }
    }

    .info_conts {
        position: relative;
        clear: both;
        border-top: 1px solid #e8e8e8;
        margin-top: 20px;
        padding: 20px 0;

        .btnwrap {
            padding-bottom: 20px;
        }

        .txt {
            font-size: 12px;
            font-weight: bold;
            color: #868ba8;
        }

        .copy {
            margin-top: 7px;
            color: #666;
            font-size: 12px;
            letter-spacing: 0;
        }

        .fr {
            position: absolute;
            right: 0;
            top: 20px;
            width: 220px;
            text-align: right;

            button.btn_small {
                width: 80px;
            }
        }

        .fam_wrap {
            display: inline-block;
            *display: inline;
            zoom: 1;
            position: relative;
            width: 123px;
            height: 18px;
            border: 1px solid #dadada;
            background: #fff;
            -webkit-border-radius: 1px;
            -moz-border-radius: 1px;
            -o-border-radius: 1px;
            -ms-border-radius: 1px;
            border-radius: 1px;
            text-align: left;
            vertical-align: middle;

            button {
                position: relative;
                width: 100%;
                height: 18px;
                padding: 0 8px;
                background: #fff;
                font-size: 11px;
                line-height: 18px;
                color: #666;
                text-align: left;
                letter-spacing: 0;

                .ico {
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    width: 9px;
                    height: 6px;
                    margin-top: -3px;
                    background: url("/img/common/v2/sp_footer.png") -152px -40px no-repeat;
                }
            }

            &.on button .ico {
                background-position: -171px -40px;
            }

            ul {
                display: none;
                position: absolute;
                left: -1px;
                bottom: 19px;
                *bottom: 20px;
                width: 123px;
                padding: 6px 0;
                border: 1px solid #dadada;
                border-bottom: 0;
                background: #fff;
                letter-spacing: 0;
            }

            &.on ul {
                display: block;
            }

            li {
                float: left;
                width: 100%;

                a {
                    display: block;
                    padding: 0 8px;
                    font-size: 11px;
                    line-height: 20px;
                    color: #666;

                    &:hover {
                        background-color: #f2f2f2;
                        font-weight: bold;
                        color: #333;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    // 휴대폰샵 푸터
    .f_guide {
        padding: 20px 0 15px;
        background: #fff;

        .inner {
            position: relative;
            width: 1200px;
            margin: 0 auto;
        }
        .lk_guide {
            display: inline-block;
            font-size: 13px;
            color: #666;
            line-height: 18px;

            .ico_arr {
                display: inline-block;
                overflow: hidden;
                width: 10px;
                height: 16px;
                margin: -3px 0 3px 5px;
                background: url("/img/mobilephone/ico_more_right.png") no-repeat;
                vertical-align: middle;
                line-height: 200px;
            }
        }
    }

    // 푸터 바로가기
    .f_menu_list {
        width: 1200px;
        margin: 0 auto;

        &:after {
            display: block;
            clear: both;
            content: "";
        }
        li {
            position: relative;
            float: left;
            width: 52px;
            padding-left: 23px;

            &:before {
                position: absolute;
                top: 50%;
                left: 11px;
                width: 1px;
                height: 10px;
                margin-top: -5px;
                background-color: #c4c4c4;
                content: "";
            }
            &:first-child {
                padding-left: 0;

                &:before {
                    display: none;
                }
            }
            &:nth-child(4),
            &:nth-child(5) {
                width: 104px;
            }
            &:nth-child(6) {
                width: 52px;
            }
            &:nth-child(7) {
                width: 39px;
            }
            &:nth-child(8) {
                width: 74px;
            }
            &:nth-child(9) {
                width: 91px;
            }
            &:nth-child(10) {
                width: 78px;
            }
            &:nth-child(11) {
                width: 117px;
            }
            &:nth-child(12) {
                width: 57px;
            }
        }
        .lk_f_menu {
            display: block;
            color: #666;
            font-family: $font-family-default;
            text-align: center;
        }
    }
}
// MPSR-59739 런칭 후 삭제예정 끝

.l_footer {
    min-height: 317px;
    padding: 0 0 30px 0;
    letter-spacing: 0;
    font-family: $font-family;
    line-height: 1.5;
    background: #fff;
}

.b_footer_menu {
    height: 70px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #f4f4f4;
    box-sizing: border-box;

    > .inner {
        @include clearfix;
        width: 1240px;
        margin: 0 auto;
    }
}

.c_footer_menu_list {
    float: left;
    padding: 25px 0 0 0;

    li {
        float: left;
        margin: 0 0 0 20px;

        &:first-child {
            margin-left: 0;
        }
    }
    a {
        position: relative;
        font-size: 14px;
        color: #333;
    }
    .new {
        position: absolute;
        right: -13px;
        top: -9px;
        width: 14px;
        height: 14px;
        overflow: hidden;
        line-height: 200px;
        background: url("/img/common/v3/icon_new.png") no-repeat;
        background-size: 14px 14px;
    }
}

.c_footer_selectgroup {
    float: right;
    padding: 17px 0 0 0;
}

.c_footer_select {
    float: left;
    position: relative;
    margin: 0 0 0 11px;
    z-index: 10;

    > button {
        position: relative;
        overflow: hidden;
        width: 175px;
        height: 36px;
        padding: 0 35px 0 10px;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #111;
        border: 1px solid #ccc;
        box-sizing: border-box;
        text-align: left;
        font-family: $font-family;

        &:after {
            position: absolute;
            right: 16px;
            top: 16px;
            width: 6px;
            height: 6px;
            margin: -5px 0 0 -4px;
            border: solid #111;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
            content: "";
        }
    }
    .select_layer {
        display: none;
        position: absolute;
        left: 0;
        top: 35px;
        width: 100%;
        padding: 9px 0;
        box-sizing: border-box;
        border: 1px solid #ccc;
        border-top-color: #f4f4f4;
        background: #fff;

        a {
            display: block;
            padding: 3px 10px;
            color: #111;
        }
    }
    &.active {
        .select_layer {
            display: block;
        }
    }
}

.c_footer_info {
    position: relative;
    width: 1240px;
    margin: 0 auto;

    dl {
        position: relative;
        padding: 30px 430px 0 0;

        dt {
            display: block;
            font-weight: bold;
            color: #111;
        }
        dd {
            font-size: 12px;
            line-height: 18px;
            color: #666;
        }
        address {
            display: inline;
        }

        .link {
            display: inline-block;
            position: relative;
            padding: 0 8px 0 0;
            color: #111;

            &:after {
                position: absolute;
                right: 0;
                top: 6px;
                width: 5px;
                height: 5px;
                border: solid #111;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }
        .about {
            dt {
                margin: 0 0 10px 0;
                font-size: 14px;
            }
            dd {
                margin: 0;
            }
            .point {
                color: #111;
                font-size: 10px;
            }
            .link {
                margin-left: 5px;
            }
            .add_link {
                margin: 20px 0 20px 0;

                a,
                button {
                    display: inline-block;
                    height: 24px;
                    margin: 0 7px 0 0;
                    line-height: 22px;
                    padding: 0 6px;
                    box-sizing: border-box;
                    border: 1px solid #eee;
                    text-align: center;
                    font-size: 12px;
                    vertical-align: top;
                    color: #111;
                }
                a {
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        //S: MPSR-125939 반영 후 제거
        .contact {
            position: absolute;
            right: 0;
            top: 0;
            width: 361px;
            padding: 30px 0 0 0;

            dt {
                margin: 0 0 10px 0;
                font-size: 14px;

                .call {
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-size: 14px;
                    font-weight: bold;
                    color: $color-11st-red;
                    font-family: $font-family-number-lato;
                    vertical-align: top;
                }
                &.space {
                    margin-top: 34px;
                    margin-bottom: 9px;
                }
            }
            .link {
                margin-right: 39px;
            }
            .relative_link {
                margin-top: 8px;
            }
        }
        //E: MPSR-125939 반영 후 제거
    }

    .copyright {
        margin: -3px 0 0 0;
        color: #999;
        font-size: 10px;
    }
}

.c-footer-contact {
    position: absolute !important;
    right: 0;
    top: 0;
    width: 361px;
    padding: 30px 0 0 0 !important;

    &--active {
        .c-footer-contact__detail {
            display: block;
        }
        .c-footer-contact__button {
            &:after {
                margin: 0 0 0 7px;
                transform: rotate(-45deg);
            }
        }
    }

    &__item {
        &:not(:first-of-type) {
            margin-top: 10px;
        }
    }

    &__title {
        color: $color-gray-02;
        font-weight: 400;
    }

    &__button {
        color: $color-gray-02;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;

        &:after {
            display: inline-block;
            width: 6px;
            height: 6px;
            margin: -5px 0 0 7px;
            border: solid $color-gray-02;
            border-width: 1px 1px 0 0;
            transform: rotate(135deg);
            vertical-align: middle;
            content: "";
        }

        .normal {
            font-weight: normal;
        }
    }

    &__detail {
        display: none;
        margin: 10px 0 5px;
        color: $color-gray-04;
        font-size: 12px;
        line-height: 18px;
    }

    &__relative {
        display: flex;
        gap: 20px;
        margin-top: 8px;
    }

    &__link {
        display: inline-block;
        position: relative;
        padding: 0 8px 0 0;
        color: $color-gray-02;

        &:after {
            position: absolute;
            right: 0;
            top: 7px;
            width: 5px;
            height: 5px;
            border: solid $color-gray-02;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            content: "";
        }
    }
}

.c_footer_award {
    @include clearfix;
    width: 1240px;
    margin: 20px auto 0 auto;

    li {
        float: left;
        margin: 0 10px 0 0;

        a {
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            line-height: 200px;
        }
        &.isms {
            @include sprite-retina($sp_footer_2x_award_isms);
        }
        &.kssqi {
            @include sprite-retina($sp_footer_2x_award_kssqi);
        }
        &.kcsi {
            @include sprite-retina($sp_footer_2x_award_kcsi);
        }
        &.kolsa {
            @include sprite-retina($sp_footer_2x_award_kolsa);
        }
        &.openmarket {
            @include sprite-retina($sp_footer_2x_award_openmarket);
        }
        &.kats {
            @include sprite-retina($sp_footer_2x_award_kats);
        }
        &.system {
            @include sprite-retina($sp_footer_2x_award_system);
        }
        &.ccm {
            @include sprite-retina($sp_footer_2x_award_ccm);
        }
        &.pcr {
            @include sprite-retina($sp_footer_2x_award_pcr);
        }
    }
}
