@charset "UTF-8";

@import "../../common/helpers/variables"; //변수모음
@import "../../common/partial/ie9_hack"; //ie9 대응

@font-face {
    font-family: "Helvetica Neue";
    src: local("Helvetica Neue");
    unicode-range: U+0020;
}

@font-face {
    font-family: "Arial";
    src: local("Arial");
    unicode-range: U+0020;
}

/* noto-sans-kr-300 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v13-latin_korean-300.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v13-latin_korean-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans KR Regular"), local("NotoSansKR-Regular"), url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans KR Medium"), local("NotoSansKR-Medium"), url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
    font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans KR Bold"), local("NotoSansKR-Bold"), url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/noto-sans-kr-v12-latin_korean-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-regular - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v16-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v16-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* lato-900 - latin */
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v16-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v16-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* new lato-regular - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* new lato-700 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* new lato-900 - latin */
@font-face {
    font-family: "Lato New";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E, U+0041-005A, U+0061-007A; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자)
}

/* all lato-regular - latin */
@font-face {
    font-family: "Lato all";
    font-style: normal;
    font-weight: 400;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자), 공백(U+0020)
}

/* all lato-700 - latin */
@font-face {
    font-family: "Lato all";
    font-style: normal;
    font-weight: 700;
    src: local("Lato Regular"), local("Lato-Regular"), url("/css/fonts/lato-v23-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자), 공백(U+0020)
}

/* all lato-900 - latin */
@font-face {
    font-family: "Lato all";
    font-style: normal;
    font-weight: 900;
    src: local("Lato Black"), local("Lato-Black"), url("/css/fonts/lato-v23-latin-900.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/css/fonts/lato-v23-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    unicode-range: U+0030-0039, U+0041-005A, U+0061-007A, U+0020; //숫자 : U+0030-0039,영문 : U+0041-005A(대문자), U+0061-007A(소문자), 공백(U+0020)
}

@font-face {
    font-family: "11StreetGothic";
    font-style: normal;
    font-weight: 400;
    src: url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Light_Optimizing.woff") format("woff");
}

@font-face {
    font-family: "11StreetGothic";
    src: url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff2"), url("/css/fonts/11StreetGothic_Optimizing.woff") format("woff");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "11StreetGothicBold";
    font-weight: bold;
    src: url("/css/fonts/11STREET_Gothic_bold.eot");
    src: url("/css/fonts/11STREET_Gothic_bold.woff") format("woff2"), url("/css/fonts/11STREET_Gothic_bold.woff") format("woff"), url("/css/fonts/11STREET_Gothic_bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    src: url("/css/fonts/RobotoCondensed-Bold.eot");
    src: url("/css/fonts/RobotoCondensed-Bold.woff") format("woff2"), url("/css/fonts/RobotoCondensed-Bold.woff") format("woff"), url("/css/fonts/RobotoCondensed-Bold.ttf") format("truetype");
}

.js-focus-visible {
    button,
    input[type="checkbox"],
    input[type="radio"],
    select {
        &:focus:not(.focus-visible) {
            outline: 0;
        }
    }

    select {
        &:focus:not(.focus-visible) {
            &::-ms-value {
                background-color: transparent;
                color: inherit;
            }
        }
    }

    // 폼요소 + label 형태
    input[type="checkbox"],
    input[type="radio"] {
        &:focus:not(.focus-visible) {
            & + label::before {
                box-shadow: none !important;
            }
            & + label {
                box-shadow: none !important;
            }
        }
    }

    // label 안에 폼요소를 감싼 경우
    label input[type="checkbox"],
    label input[type="radio"] {
        &:focus:not(.focus-visible) {
            & + span::before {
                box-shadow: none !important;
            }
        }
    }

    [role="listbox"] {
        &:focus:not(.focus-visible) {
            outline: 0;
        }
    }
}

input[type="radio"],
input[type="checkbox"],
select,
button {
    cursor: pointer;
}

// reset main
@if ($pageStatus == "main") {
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    textarea,
    p,
    blockquote,
    th,
    td,
    input,
    select,
    textarea,
    button {
        margin: 0;
        padding: 0;
    }

    html {
        overflow-y: scroll;
        background: #fff;
        width: 100%;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
    }

    dl,
    ul,
    ol,
    menu,
    li {
        list-style: none;
    }

    img,
    fieldset,
    iframe {
        border: 0 none;
    }

    img {
        vertical-align: top;
    }

    input,
    select,
    textarea,
    button {
        vertical-align: middle;
    }

    input::-ms-clear {
        display: none;
    }

    button {
        border: 0 none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 0;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        empty-cells: show;
    }

    caption,
    legend {
        position: absolute;
        top: -9999px;
        left: -9999px;
        font-size: 0px;
        line-height: 0;
    }

    a {
        color: #666;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
        &:active {
            background-color: transparent;
        }
    }

    body,
    h1,
    h2,
    h3,
    h4,
    th,
    td,
    input,
    select,
    textarea,
    button {
        font-size: 14px;
        line-height: 1.5;
        font-family: $font-family-default;
        color: #666;
        letter-spacing: -1px;
    }

    hr {
        display: none;
    }

    address,
    caption,
    cite,
    code,
    dfn,
    em,
    var {
        font-style: normal;
        font-weight: normal;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section,
    main {
        display: block;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote {
        &:before,
        &:after {
            content: "";
            content: none;
        }
    }

    q {
        &:before,
        &:after {
            content: "";
            content: none;
        }
    }
    // reset general
} @else if ($pageStatus == "general") {
    body,
    div,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    form,
    fieldset,
    legend,
    textarea,
    p,
    blockquote,
    th,
    td,
    input,
    select,
    textarea,
    button {
        margin: 0;
        padding: 0;
    }

    html {
        overflow-y: scroll;
        background: #fff;
        width: 100%;
        height: 100%;
    }

    body {
        width: 100%;
        height: 100%;
    }

    dl,
    ul,
    ol,
    menu,
    li {
        list-style: none;
    }

    img,
    fieldset,
    iframe {
        border: 0 none;
    }

    img {
        vertical-align: top;
    }

    input,
    select,
    textarea,
    button {
        font: 12px/1.4 $font-family;
        color: #666;
        vertical-align: middle;
    }

    input::-ms-clear {
        display: none;
    }

    button {
        border: 0 none;
        background-color: transparent;
        cursor: pointer;
        border-radius: 0;

        &:disabled {
            cursor: default;
        }
    }

    input {
        &[type="button"],
        &[type="submit"] {
            cursor: pointer;
            border: 0 none;
        }
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
        empty-cells: show;
    }

    caption,
    legend {
        position: absolute;
        top: -9999px;
        left: -9999px;
        font-size: 0px;
        line-height: 0;
    }

    a {
        color: #666;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
        &:active {
            background-color: transparent;
        }
    }

    body,
    h1,
    h2,
    h3,
    h4,
    th,
    td,
    input,
    select,
    textarea,
    button {
        font-size: 14px;
        line-height: 1.5;
        font-family: $font-family;
        color: #666;
        letter-spacing: 0;
    }

    hr {
        display: none;
    }

    address,
    caption,
    cite,
    code,
    dfn,
    em,
    var {
        font-style: normal;
        font-weight: normal;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section,
    main {
        display: block;
    }

    blockquote,
    q {
        quotes: none;

        &:before,
        &:after {
            content: "";
            content: none;
        }
    }
    // reset sub
} @else {
    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    ol,
    dl,
    dd,
    form,
    fieldset,
    pre,
    figure,
    blockquote,
    input,
    button,
    textarea {
        padding: 0;
        margin: 0;
    }

    html {
        overflow-y: scroll;
        background: #fff;
    }

    body {
        color: #4d4d4d;
        font: 12px/1.3 $font-family-asis;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    nav,
    section {
        display: block;
    }

    ul,
    ol,
    li {
        list-style: none;
    }

    img,
    fieldset,
    iframe {
        border: 0 none;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    caption {
        overflow: hidden;
        font-size: 0;
        line-height: 0;
    }

    legend {
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        *position: absolute;
        margin-left: -20px;
    }

    em,
    address {
        font-style: normal;
    }

    s {
        text-decoration: none;
    }

    a {
        color: #4d4d4d;
        text-decoration: none;

        &:hover,
        &:active {
            outline: 0;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    body,
    div,
    textarea,
    iframe {
        scrollbar-3dlight-color: #ffffff;
        scrollbar-arrow-color: #919191;
        scrollbar-track-color: #f4f4f4;
        scrollbar-darkshadow-color: #ffffff;
        scrollbar-face-color: #ffffff;
        scrollbar-highlight-color: #cdcdcd;
        scrollbar-shadow-color: #c8c8c8;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 100%;
    }

    hr {
        display: none;
    }

    button,
    input,
    select,
    textarea {
        color: #4d4d4d;
        font: 12px/1.4 $font-family-asis;
        vertical-align: middle;
    }

    button {
        background: transparent;
        border-radius: 0;
    }

    input {
        &[type="text"],
        &[type="password"] {
            padding: 1px 4px 3px;
            color: #4d4d4d;
            border: 1px solid #bbb;
        }

        &[type="button"],
        &[type="submit"] {
            cursor: pointer;
            border: 0 none;
        }

        &[type="radio"],
        &[type="checkbox"] {
            margin: 1px 2px 0 0;
            vertical-align: top;
            *width: 13px;
            *height: 13px;
            *vertical-align: 2px;
        }

        &::-ms-clear {
            display: none;
        }
    }

    button {
        cursor: pointer;
        border: 0 none;
    }

    button::-moz-focus-inner {
        margin: -1px;
        padding: 0;
    }
}
