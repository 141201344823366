[data-type="PC_ProductCard_NewDealBox"] {
    width: 1240px;
    min-height: 232px;
    margin: 0 auto;

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-list {
        .swiper-container {
            padding: 10px 25px 25px;
            margin: -10px -25px -25px -25px;

            &.swiper_doing {
                margin-left: 0px;
                padding-right: 3px;
                padding-left: 3px;
            }

            //fe 개발 상용에 추가 되는 소스에 스타일링 추가
            & > div {
                display: flex;

                .swiper-slide,
                .slider-slide {
                    vertical-align: top;

                    > ul {
                        height: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }

        .swiper-wrapper {
            display: flex;

            .swiper-slide,
            .slider-slide {
                vertical-align: top;

                > ul {
                    height: 100%;
                    box-sizing: border-box;
                }
            }
        }

        &--col1 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격, 아이템갯수
            @include pui-card-list-swipe(1240px, 0, 1);
        }

        &--col2 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격,아이템갯수
            @include pui-card-list-swipe(605px, 30px, 2);
        }
    }

    .slider-slide {
        vertical-align: top;
    }

    .c-card-item {
        &--list {
            display: flex;
        }

        &__delivery {
            .delivery {
                @include clearfix();
                em {
                    float: left;
                    padding-right: 4px;
                }

                .delivery-text {
                    clear: none;
                    float: left;
                }
            }
        }

        .c-card-item__discount {
            display: flex;
            &--point {
                .value {
                    margin-left: 2px;
                }
            }
        }
    }

    .c-card-item__cart {
        position: relative;
        width: 158px;
        padding: 12px 0 0;
        box-sizing: border-box;
        background-color: $color-gray-14;

        .c-card-item__amount {
            z-index: 2;
            width: calc(100% - 36px);
            height: 32px;
            border-radius: 4px;

            .c-card-item__quantity {
                padding: 0 32px;
                border-radius: 4px;
                line-height: 30px;
            }

            .c-card-item__remove {
                width: 32px;
                border-radius: 4px 0 0 4px;
                &:after {
                    margin-left: -7px;
                    width: 14px;
                    background: $color-gray-07;
                    transform: scaley(0.75);
                }
            }

            .c-card-item__add {
                width: 32px;
                border-radius: 0 4px 4px 0;
                &:before,
                &:after {
                    background: $color-gray-07;
                }

                &:before {
                    margin-top: -6px;
                    margin-left: -1px;
                    height: 12px;
                    transform: scalex(0.75);
                }

                &:after {
                    margin-left: -6px;
                    width: 12px;
                    transform: scaley(0.75);
                }
            }
        }

        .c-card-item__save {
            float: right;
            overflow: hidden;
            position: relative;
            z-index: 2;
            width: 32px;
            height: 32px;
            line-height: 200px;
            border-left: none;
            border-left: 1px solid #eee;
            border-radius: 4px;

            &::before {
                display: none;
            }
        }

        .c-card-item__link {
            z-index: 1;
            height: 32px;
            line-height: 30px;

            &::after {
                top: 11px;
            }
        }
    }

    .shooting-delivery__text,
    .delivery-text {
        clear: both;
        display: block;
        line-height: 18px;
    }

    .c-layer-cart {
        right: auto;
        left: calc(100% - 68px);
        bottom: 48px;

        &::before {
            left: 45px;
        }
    }

    .c-list--col1 {
        .c-nation {
            margin-bottom: 10px;
        }

        .c-card-item {
            height: 100%;

            &:hover {
                .c-card-item__thumb {
                    .c-lazyload {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }

            &--box {
                padding: 10px;
            }

            &__info {
                @include clearfix;
                padding: 10px 30px;
            }

            &__thumb {
                min-width: 212px;
                width: 212px;
                height: 212px;
                overflow: hidden;
                overflow: hidden;

                .c-lazyload {
                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            &__title {
                overflow: hidden;
                height: 22px;
                font-size: 15px;
                color: $color-11st-blue;
                text-align: center;
            }

            &__tag {
                overflow: hidden;
                height: 22px;
                font-size: 15px;
                margin-top: 3px;
                text-align: center;
            }

            &__name {
                max-height: 3em;
                margin: 0 0 8px 0;
                line-height: 22px;
            }

            &__counsel {
                margin-top: 10px;
                font-weight: normal;
                font-size: 15px;
                color: $color-11st-blue;
            }

            &__soldout-text {
                left: 10px;
                top: 10px;
                width: 204px;
                height: 204px;
            }

            &__price-info {
                margin: 0 0 10px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .c-card-item__price-special {
                    float: left;
                    width: auto;
                    margin-right: 5px;
                    font-size: 20px;
                }

                .c-card-item__rate {
                    margin-right: 8px;

                    .value {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                .c-card-item__price {
                    display: inline-block;
                    font-size: 15px;
                    vertical-align: baseline;
                    .value {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
            }

            &__delivery {
                .delivery {
                    //overflow: hidden;
                    //max-height: 18px;
                    &::before {
                        display: none;
                    }
                }
            }

            .c-flag-box {
                margin-bottom: 4px;
                max-height: 25px;
            }

            .c-time-flag {
                margin: 0 0 8px 0;
            }

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-starrate {
                line-height: 18px;
                margin: 0 0 4px 0;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            &__rank--impact {
                @include get-sprite-common("flag_best", 59);
            }
        }
    }

    .c-list--col2 {
        .c-nation {
            margin-bottom: 10px;
        }

        .c-card-item {
            height: 100%;

            &:hover {
                .c-card-item__thumb {
                    .c-lazyload {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }

            &--box {
                padding: 10px;
            }

            &__info {
                @include clearfix;
                padding: 10px 30px;
            }

            &__thumb {
                min-width: 212px;
                width: 212px;
                height: 212px;
                overflow: hidden;

                .c-lazyload {
                    img {
                        transition: transform 0.2s ease-in;
                    }
                }
            }

            &__name {
                max-height: 3em;
                margin: 0 0 8px 0;
                line-height: 22px;
            }

            &__counsel {
                margin-top: 10px;
                font-weight: normal;
                font-size: 15px;
                color: $color-11st-blue;
            }

            &__soldout-text {
                left: 10px;
                top: 10px;
                width: 204px;
                height: 204px;
            }

            &__price-info {
                margin: 0 0 10px 0;
                padding: 0;

                &:last-child {
                    margin-bottom: 0;
                }

                .c-card-item__price-special {
                    float: left;
                    width: auto;
                    margin-right: 5px;
                    font-size: 20px;
                }

                .c-card-item__rate {
                    margin-right: 8px;

                    .value {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }

                .c-card-item__price {
                    display: inline-block;
                    font-size: 15px;
                    vertical-align: baseline;
                    .value {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
            }

            &__delivery {
                .delivery {
                    overflow: hidden;
                    max-height: 18px;
                    &::before {
                        display: none;
                    }
                }
            }

            .c-flag-box {
                margin-bottom: 4px;
                max-height: 25px;
            }

            .c-time-flag {
                margin: 0 0 8px 0;
            }

            .c-flag {
                margin: 0 0 8px 0;
            }

            .c-starrate {
                line-height: 18px;
                margin: 0 0 4px 0;

                dd {
                    float: left;
                }
                .sr-only {
                    @include sr-only;
                }
            }

            &__rank--impact {
                @include get-sprite-common("flag_best", 59);
            }
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }
}
