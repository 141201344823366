[data-type="PC_Title_Content_Default"] {
    margin-bottom: 25px;

    .c-headline {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 1240px;
        border-radius: 4px;
        align-items: center;
        margin: 0 auto;

        &__text {
            flex: 0 1 auto;
            min-width: 0;
            //flex-grow: 1;
        }

        &__title {
            color: $color-gray-02;
            font-size: 24px;
            font-weight: 700;
            line-height: 28px;
        }

        &__point {
            color: $color-11st-blue;
            font-weight: 700;
        }
    }

    .c-link-arrow {
        @include set-c-link-arrow(15px, $color-gray-04, 0 13px 0 0);
        position: absolute;
        right: 1px;
        top: 3px;
        z-index: 30;
    }

    .c-ad-box {
        @include set-c-ad-box(type1);
        margin-left: 8px;
    }

    .c-tab {
        @include clearfix;
        margin-left: 50px;

        &__item {
            display: inline-block;
            position: relative;

            &:before {
                position: absolute;
                left: 0px;
                top: 7px;
                width: 1px;
                height: 16px;
                background: $color-gray-11;
                content: "";
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }

            &.active {
                .c-tab__button {
                    color: $color-11st-red;
                    font-weight: bold;
                }
            }
        }

        &__button {
            display: inline-block;
            padding: 0 15px;
            color: $color-gray-04;
            font-size: 18px;
            box-sizing: border-box;
        }
    }

    .c-headline__link {
        position: absolute;
        right: 1px;
        top: 3px;
        z-index: 30;

        .c-link-arrow {
            position: static;
        }
    }
}
