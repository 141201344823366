@import "../../common/mixins"; //믹스인모음

.pui-wrap {
    width: 100%;
    min-height: calc(100vh - 534px); //뷰포트 - (gnb  + 푸터 ) 로 스켈레톤 대응
    @include clearfix();

    &__container {
        width: 100%;
        @include clearfix();
    }

    &__header {
        position: relative;
        z-index: 200;

        &--fixed {
            position: fixed;
            top: 70px;
            left: 0;
            right: 0;
            z-index: 1100;
        }
    }

    &--category {
        .pui-wrap__body {
            display: flex;
            width: $pui-width-fixed;
            margin: 0 auto;
        }

        .pui-wrap__lnb {
            position: relative;
            z-index: 100;
            width: 254px;
            padding-top: 40px;
            padding-right: 30px;
            padding-bottom: 25px;
            box-sizing: border-box;
        }

        .pui-wrap__content {
            flex-grow: 1;
            width: $pui-width-category;
            padding-top: 35px;
            padding-bottom: 25px;
        }
    }
}
