@charset "UTF-8";

@import "../../common/helpers/variables"; //변수모음
@import "../../common/mixins/_c_card"; //믹스인모음
@import "../../common/sprites/_sp_list_2x";
@import "../../eui/sprites/_sp_card_item";
@import "../svg/sp_common.scss";
@import "../../eui/sprites/sp_card_item_2x.scss";

.c_prd_layer {
    display: none;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;

    // 상품리스팅에 사용
    &.c_prd_layer_style_1 {
        padding: 15px;
        width: 206px;

        .title {
            padding-bottom: 5px;
            border-bottom: 1px solid #ddd;
            color: #222;
            font-size: 13px;
            line-height: 19px;
        }

        .list {
            &.list_style_1 {
                margin-top: 10px;

                li {
                    color: #222;
                    font-size: 12px;
                    line-height: 18px;

                    em {
                        color: $color-11st-blue;
                    }

                    &:not(:first-child) {
                        margin-top: 5px;
                    }
                }
            }

            &.list_style_2 {
                margin-top: 10px;

                li {
                    color: #222;
                    font-size: 12px;
                    line-height: 18px;

                    em {
                        color: $color-11st-blue;
                    }

                    &:not(:first-child) {
                        margin-top: 5px;
                    }
                }

                dl {
                    width: 100%;
                    font-size: 0;
                }

                dt {
                    width: 60%;
                    display: inline-block;
                    font-size: 12px;

                    span {
                        color: #666;
                    }
                }

                dd {
                    width: 40%;
                    display: inline-block;
                    text-align: right;
                    font-size: 12px;
                    color: $color-11st-red;
                }
            }
        }

        .sentence {
            padding-top: 10px;
            color: #222;
            font-size: 12px;
            letter-spacing: 0;
        }

        .btn_close {
            top: 14px;
            right: 10px;
            @include ico-cancel(10px, 1px, #999, 5px);
        }
    }

    &.c_prd_layer_size_2 {
        width: 226px;
    }

    .btn_close {
        position: absolute;
    }
}

.c_tooltip {
    position: absolute;
    padding: 7px 12px 6px;
    border-radius: 14px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    border: 1px solid #ddd;
    font-size: 12px;
    color: #333;
    letter-spacing: 0;

    &:before {
        position: absolute;
        z-index: 2;
        top: -1px;
        left: 22px;
        @include ico-triangle("up", 12px, 6px, #fff);
    }

    &:after {
        position: absolute;
        z-index: 1;
        top: -3px;
        left: 22px;
        @include ico-triangle("up", 12px, 6px, #ddd);
    }

    &.c_tooltip_top_center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }

        &:after {
            left: 50%;
            margin-left: -6px;
        }
    }

    &.c_tooltip_top_right {
        &:before {
            right: 22px;
            left: auto;
        }

        &:after {
            right: 22px;
            left: auto;
        }
    }

    &.c_tooltip_bottom_left,
    &.c_tooltip_bottom_center,
    &.c_tooltip_bottom_right {
        &:before {
            position: absolute;
            z-index: 2;
            top: auto;
            bottom: -7px;
            border-bottom: transparent;
            border-top: 6px solid #fff;
        }

        &:after {
            position: absolute;
            z-index: 1;
            top: auto;
            bottom: -9px;
            border-bottom: transparent;
            border-top: 6px solid #ddd;
        }
    }

    &.c_tooltip_bottom_center {
        &:before {
            left: 50%;
            margin-left: -6px;
        }

        &::after {
            left: 50%;
            margin-left: -6px;
        }
    }

    &.c_tooltip_bottom_right {
        &:before {
            left: auto;
            right: 22px;
        }

        &:after {
            left: auto;
            right: 22px;
        }
    }
}

// pagination
.c_pagination {
    &.c_pagination_style_1 {
        margin-top: 20px;

        .list {
            width: 100%;
            text-align: center;

            li {
                display: inline-block;
                vertical-align: middle;
                min-width: 34px;
            }
            button,
            a,
            span,
            strong {
                display: inline-block;
                width: 36px;
                height: 36px;
                font-size: 16px;
                color: #999;
                text-align: center;
                line-height: 32px;
                font-family: $font-family-number-lato;
            }

            button {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .active {
            button,
            a {
                &:hover {
                    text-decoration: none;
                }
            }
            button,
            a,
            strong {
                font-weight: bold;
                color: #222;
            }

            button {
                line-height: 36px;
            }
        }

        .previous,
        .first,
        .next,
        .last {
            button,
            a,
            span,
            strong {
                position: relative;
                width: 30px;
                height: 30px;
                border-radius: 30px;
                color: transparent;
                box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
                background-color: #fff;
                overflow: hidden;
                line-height: 200px;

                &::after,
                &::before {
                    height: 5px;
                    width: 5px;
                    position: absolute;
                    top: 50%;
                    margin-top: -3px;
                    border: 1px solid #111;
                    border-width: 1px 1px 0 0;
                    content: "";
                }

                &.disable {
                    &::after,
                    &::before {
                        border-color: #999;
                    }
                }
            }
        }

        .first {
            button,
            a,
            span {
                &::before {
                    transform: rotate(223deg);
                    left: 50%;
                    margin-left: -4px;
                }

                &::after {
                    transform: rotate(223deg);
                    left: 50%;
                    margin-left: 0px;
                }
            }
        }

        .previous {
            button,
            a,
            span {
                &:before {
                    transform: rotate(223deg);
                    left: 50%;
                    margin-left: -2px;
                }

                &:after {
                    display: none;
                }
            }
        }

        .next {
            button,
            a,
            span {
                &::before {
                    transform: rotate(45deg);
                    right: 50%;
                    margin-right: -2px;
                }

                &::after {
                    display: none;
                }
            }
        }

        .last {
            button,
            a,
            span {
                &::before {
                    transform: rotate(45deg);
                    right: 50%;
                    margin-left: 2px;
                }

                &::after {
                    transform: rotate(45deg);
                    right: 50%;
                    margin-right: -4px;
                }
            }
        }
    }

    &.c_pagination_style_2 {
        .list {
            width: 100%;
            text-align: center;

            li {
                display: inline-block;
                vertical-align: middle;
                min-width: 14px;
                text-align: center;
            }

            a,
            button {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 4px;
                background-color: #efefef;
                overflow: hidden;
                line-height: 120px;
            }

            .active {
                a,
                button {
                    background-color: #676767;
                }
            }

            .previous,
            .next {
                position: absolute;
                top: 120px;

                a,
                span,
                button {
                    position: relative;
                    display: inline-block;
                    width: 50px;
                    height: 50px;
                    border-radius: 25px;
                    box-shadow: 0 4px 16px -4px rgba(0, 0, 0, 0.28), 0 0 1px 0 rgba(0, 0, 0, 0.45);
                    background-color: #fff;
                    line-height: 200px;
                    overflow: hidden;
                }
            }

            .previous {
                left: -25px;

                a,
                span,
                button {
                    &::before {
                        position: absolute;
                        top: 12px;
                        left: 12px;
                        @include sprite-retina($sp_list_2x_pagination_style2_previous);
                        content: "";
                    }
                }
            }

            .next {
                right: -25px;

                a,
                span,
                button {
                    &::before {
                        position: absolute;
                        top: 12px;
                        right: 12px;
                        @include sprite-retina($sp_list_2x_pagination_style2_next);
                        content: "";
                    }
                }
            }
        }
    }

    &.c_pagination_style_3 {
        position: relative;
        display: inline-block;
        padding: 0 42px;
        min-width: 84px;
        box-sizing: border-box;

        .page {
            display: inline-block;
            font-size: 16px;
            color: #ccc;
            vertical-align: middle;
            font-family: $font-family-number-lato;
            line-height: 32px;

            strong {
                color: #111;
                font-size: 15px;

                &:after {
                    display: inline-block;
                    margin: 0 5px;
                    color: #ccc;
                    font-family: $font-family;
                    content: "/";
                }
            }
        }

        .navigator {
            button {
                position: relative;
                width: 32px;
                height: 32px;
                border-radius: 15.5px;
                box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
                background-color: #fff;
                line-height: 200px;
                overflow: hidden;

                &:first-child {
                    position: absolute;
                    top: 0;
                    left: 0;

                    &:after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin: -4px 0 0 -3px;
                        @include ico-angle(left, 6px, 1px, #111, static);
                        content: "";
                    }
                }

                &:last-child {
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:after {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin: -4px 0 0 -4px;
                        @include ico-angle(right, 6px, 1px, #111, static);
                        content: "";
                    }
                }

                &:disabled {
                    &:after {
                        border-color: #ccc;
                    }
                }
            }
        }
    }
}

.c_ad_info {
    display: inline-block;

    &.active {
        .c_prd_layer {
            display: block;
            z-index: 10;
        }
    }
}

//eui 요소
.c-nation {
    a:hover {
        text-decoration: none;
    }
}

// product common
// 상품 이미지
.c_prd_thumb {
    display: block;
    width: 180px;
    height: 180px;
    text-align: center;

    > img,
    a img {
        max-width: 100%;
    }

    &.c_prd_thumb_center {
        position: relative;
        //이미지가로, 이미지세로 $c_thumb: (auto,auto,0 0 0 0);
        //@include c-thumb($c_thumb);
        $c_prd_thumb: (180px, 180px);
        @include c-prd-thumb($c_prd_thumb);
        overflow: hidden;

        a {
            display: block;
            width: 100%;
            height: inherit;

            img {
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.c_prd_swatch {
    margin: 12px 0 4px;
    font-size: 0;
    text-align: center;

    .c_prd_swatch_thumb {
        width: 26px;
        height: 26px;
        display: inline-block;
        margin-right: 5px;
        overflow: hidden;
        position: relative;
        border-radius: 100%;

        img {
            @include img-center();
        }

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 1px solid #ddd;
            border-radius: 100%;
            content: "";
        }
    }

    .c_prd_swatch_item,
    .c_prd_swatch_number {
        display: inline-block;
    }

    .c_prd_swatch_number {
        font-size: 12px;
        color: #999;
        line-height: 26px;
        vertical-align: top;

        .value {
            font-family: $font-family-number-lato;
        }
    }
}

//브랜드명
.c_prd_brand {
    @include clearfix();
    margin: 6px 0;
    color: $color-gray-02;
    font-family: $font-family-lato;
    font-size: 14px;
    font-weight: 900;
    line-height: 17px;

    dd {
        @include clearfix();
    }

    &:first-child {
        margin-top: 0;
    }
}

.c_prd_brand_flag {
    &:first-child {
        margin: 0 4px 0 0;
    }
    margin-left: 4px;
    @include get-sprite-common("ic_official", 41);
    overflow: hidden;
    line-height: 200px;
}

.c_prd_brand_name {
    @include text-ellipsis();
}

// 상품명
.c_prd_name {
    display: block;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0;
    word-break: break-all;

    @include clearfix();

    strong {
        color: #111;
        font-weight: normal;
    }

    .prd_type {
        float: left;
        margin-right: 4px;
        color: $color-11st-blue;

        &.prd_type_hp {
            padding: 0 7px;
            margin: 0 5px 0 0;
            color: #666;
            border: 1px solid #ddd;
            font-size: 12px;
            letter-spacing: -1px;
            line-height: 20px;
            vertical-align: middle;
        }
    }

    &.c_prd_name_row_1 {
        max-height: 1.5em;
        overflow: hidden;
    }

    &.c_prd_name_row_2 {
        max-height: 3em;
        overflow: hidden;
    }
}

.c_prd_option {
    margin: 6px 0;
    @include clearfix;

    dl {
        @include clearfix;
        @include text-ellipsis-multiple(2);
        margin: 6px 0;
        max-height: 34px;
        color: $color-11st-bluegray;
        line-height: 17px;
        font-family: $font-family-lato;
        font-size: 14px;

        dt {
            @include sr-only();
        }

        dd {
            display: inline;

            &:not(:first-of-type) {
                &::before {
                    display: inline;
                    content: " / ";
                }
            }
        }
    }
}

//유선상품 가격
.c_skb_price {
    position: relative;
    margin-top: 5px;
    @include clearfix();

    .c_skb_price_estimated {
        float: left;
        margin-right: 8px;
        color: $color-grayscale-02;
        font-size: 14px;
        line-height: 22px;

        dt,
        dd {
            float: left;
        }

        dd {
            margin-left: 4px;
        }

        .value {
            float: left;
            font-size: 20px;
            font-weight: bold;
            font-family: $font-family-number-lato;
            line-height: 22px;
        }

        .unit {
            float: left;
            margin-top: 1px;
            line-height: 22px;
            font-size: 13px;
        }

        @include clearfix();
    }

    .c_skb_price_charge {
        float: left;
        margin-top: 1px;
        color: $color-grayscale-04;
        font-size: 13px;
        line-height: 22px;

        dt,
        dd {
            float: left;
        }

        dd {
            margin-left: 2px;
        }

        .value {
            float: left;
            font-size: 13px;
            font-family: $font-family-number-lato;
            line-height: 22px;
            text-decoration: line-through;
        }

        .unit {
            float: left;
            line-height: 22px;
        }

        @include clearfix();
    }

    .c_skb_price_discount {
        clear: both;
        padding-top: 8px;
        color: $color-11st-red;
        font-size: 13px;
        line-height: 19px;

        dt,
        dd {
            float: left;
        }

        dd {
            margin-left: 4px;
        }

        .value {
            float: left;
            font-size: 13px;
            font-family: $font-family-number-lato;
            font-weight: bold;
            line-height: 19px;
        }

        .unit {
            float: left;
            line-height: 19px;
        }

        @include clearfix();
    }

    .c_skb_price_discount_list {
        clear: both;
        padding-top: 2px;
        color: $color-grayscale-05;
        font-size: 13px;

        .value {
            color: $color-grayscale-03;
            font-family: $font-family-number-lato;
        }

        .unit {
            color: $color-grayscale-03;
        }

        li {
            float: left;
            position: relative;
            padding-left: 6px;
            margin-right: 10px;
            font-size: 13px;
            line-height: 19px;

            &::before {
                position: absolute;
                top: 10px;
                left: 0;
                display: block;
                width: 2px;
                height: 2px;
                border-radius: 50% 50%;
                background-color: $color-grayscale-07;
                content: "";
            }
        }
    }
}

//상품 판매가격
.c_prd_price {
    vertical-align: middle;
    dl,
    dt,
    dd {
        display: inline-block;
        vertical-align: bottom;
    }

    .block {
        display: block;

        .price {
            display: inline-block;
            vertical-align: bottom;
        }

        .price_unit {
            display: inline-block;
            vertical-align: bottom;
        }
    }

    .value {
        font-family: $font-family-number-lato;
        vertical-align: bottom;
    }

    .unit {
        font-size: 13px;
        font-weight: normal;
        line-height: 18px;
        vertical-align: bottom;
    }

    .rate {
        margin-right: 5px;
        color: $color-11st-red;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0;
        font-family: $font-family-number-lato;

        dt {
            @include skip();
        }

        .unit {
            font-size: 14px;
        }
    }

    .price {
        margin-right: 4px;
        color: #111;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0;

        dt {
            @include skip();
        }
    }

    // 정상가
    .price_regular {
        color: #666;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;

        dt {
            @include skip();
        }

        dd {
            font-weight: normal;
        }

        .value {
            text-decoration: line-through;
        }

        .unit {
            font-size: 12px;
            line-height: 20px;
        }
    }

    //객단가
    .price_unit {
        color: $color-grayscale-04;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 18px;

        dt {
            @include skip();
        }
    }

    //최저가
    .price_lowest {
        color: $color-11st-red;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0;

        dt {
            margin-right: 2px;
            font-size: 15px;
            font-weight: normal;
            line-height: 18px;
        }
    }

    //최고가
    .price_highest {
        color: #666;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;

        dt {
            @include skip();
        }
    }

    //타임딜가, 쇼킹딜가
    .price_deal {
        display: inline-block;
        color: #111;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0;

        dt,
        > strong {
            margin-right: 2px;
            color: $color-11st-red;
            font-size: 15px;
            font-weight: normal;
            line-height: 20px;
        }
    }

    //11번가 최저가 안내
    .price_11st_lowest {
        color: #666;
        font-size: 14px;
        line-height: 20px;

        .text {
            float: left;
            margin-right: 2px;
            font-weight: normal;
            color: $color_11st_red;

            & + .value {
                margin-left: 4px;
            }
        }

        .value {
            float: left;
            position: relative;
            padding-left: 11px;
            margin-right: 2px;
            color: $color-11st-red;

            &::before {
                position: absolute;
                top: 11px;
                left: 0;
                @include ico-triangle("down", 8px, 5px, #ff0038, "absolute");
                content: "";
            }
        }

        .c-iconinfo {
            &--help {
                float: left;
                margin-top: 3px;
                @include size(16px);

                &:after {
                    @include get-sprite-common("ic_help", 16);
                }
            }
        }

        .c_layer_expand {
            float: left;
            height: 20px;
        }

        .c_layer_item {
            width: 294px;
            padding-right: 54px;
            color: $color-grayscale-03;
            font-size: 15px;
            line-height: 22px;
        }

        @include clearfix();
    }

    //예약금
    .price_reservation {
        color: #111;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0;

        dt {
            margin: 0 4px 0 0;
        }
    }

    @include clearfix();
}

//차량가
.c_prd_car_price {
    margin: 4px 0 0 0;
    font-size: 12px;
    color: #666;

    dt {
        display: inline-block;
        margin: 0 2px 0 0;
    }
    dd {
        display: inline-block;
    }
    strong {
        font-weight: normal;
    }
    .value {
        color: $color-11st-blue;
        line-height: 16px;
    }
    .point {
        color: $color-11st-blue;
    }
}

// 핫딜 더보기
.c_prd_deal {
    display: inline-block;
    padding: 7px 10px 6px 6px;
    border: 1px solid #eee;
    font-size: 13px;
    color: #111;
    box-sizing: border-box;

    .point {
        color: #f43142;

        .value {
            font-family: $font-family-number-lato;
        }
    }

    &::before {
        @include sprite-retina($sp_card_item_hot_deal);
        display: inline-block;
        margin: -3px 2px 1px 0;
        vertical-align: middle;
        content: "";
    }

    &::after {
        width: 7px;
        height: 7px;
        border: 1px solid #999;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        display: inline-block;
        margin: -2px 0 1px 2px;
        vertical-align: middle;
        content: "";
    }
}

//출시일
.c_prd_release {
    span.release {
        color: $color-11st-blue;
        font-size: 13px;
        line-height: 19px;
    }

    dl.release {
        dt {
            margin-bottom: 2px;
            color: $color-11st-red;
            font-size: 15px;
            letter-spacing: 0;
            line-height: 22px;
        }

        dd {
            color: #999;
            font-size: 12px;
            line-height: 19px;

            em {
                margin-left: 3px;
                color: #333;
            }

            .value {
                font-family: $font-family-number-lato;
            }
        }

        &::before {
            position: absolute;
            right: 0;
            top: 20px;
            z-index: 2;
            display: block;
            width: 1px;
            height: calc(100% - 20px);
            background-color: #fff;
            content: "";
        }
    }
}

//셀러 평점
.c_seller_grade {
    position: relative;
    display: inline-block;
    overflow: hidden;
    line-height: 200px;
    vertical-align: middle;

    &.c_seller_grade_size_1 {
        width: 64px;
        height: 12px;
        background: url("/img/svg/star.svg") no-repeat;

        &:after {
            width: 64px;
            height: 12px;
            background: url("/img/svg/star_on.svg") no-repeat;
        }
    }

    &.c_seller_grade_size_2 {
        width: 84px;
        height: 16px;
        background: url("/img/svg/star.svg") no-repeat;
        background-size: 84px 16px;

        &:after {
            width: 84px;
            height: 16px;
            background: url("/img/svg/star_on.svg") no-repeat;
            background-size: 84px 16px;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        content: "";
    }

    &.grade_0 {
        &:after {
            width: 0;
        }
    }

    &.grade_10 {
        &:after {
            width: 10%;
        }
    }

    &.grade_20 {
        &:after {
            width: 20%;
        }
    }

    &.grade_30 {
        &:after {
            width: 30%;
        }
    }

    &.grade_40 {
        &:after {
            width: 40%;
        }
    }

    &.grade_50 {
        &:after {
            width: 50%;
        }
    }

    &.grade_60 {
        &:after {
            width: 60%;
        }
    }

    &.grade_70 {
        &:after {
            width: 70%;
        }
    }

    &.grade_80 {
        &:after {
            width: 80%;
        }
    }

    &.grade_90 {
        &:after {
            width: 90%;
        }
    }
}

//리뷰
.c_review {
    display: inline-block;
    font-size: 12px;
    letter-spacing: 0;
    color: #666;
    vertical-align: middle;

    .title {
        display: inline-block;
        margin-right: 5px;
    }

    em {
        display: inline-block;
        font-family: $font-family-number-lato;
    }

    .unit {
        display: inline-block;
    }
}

//상품 사양
.c_prd_spec {
    width: 100%;
    padding-top: 6px;
    border-top: 1px solid #f4f4f4;
    background-color: #fff;
    overflow: hidden;

    .list {
        word-break: break-all;

        li {
            display: inline;

            &:not(:first-child) {
                &::before {
                    display: inline-block;
                    width: 2px;
                    height: 2px;
                    margin: 12px 5px 0 5px;
                    background-color: #ddd;
                    vertical-align: top;
                    content: "";
                }
            }
        }

        .spec {
            font-size: 12px;
            line-height: 24px;

            &:first-child {
                color: #999;
            }

            &:last-child {
                margin-left: 3px;
                color: #333;
            }
        }
    }

    &.c_prd_spec_row_1 {
        max-height: 25px;
        overflow: hidden;
    }

    &.c_prd_spec_row_2 {
        max-height: 50px;
        overflow: hidden;
        font-size: 0;
    }
}

//상품 모델별 리스트
.c_prd_model {
    position: relative;
    width: 100%;
    background-color: #fcfcfc;

    &::after {
        position: absolute;
        top: 20px;
        left: 50%;
        width: 1px;
        height: calc(100% - 40px);
        background-color: #eee;
        content: "";
    }

    .list {
        position: relative;
        padding: 17px 0;

        li {
            position: relative;
            float: left;
            width: 50%;
            padding: 3px 20px 3px 32px;
            box-sizing: border-box;

            &:before {
                position: absolute;
                top: 10px;
                left: 20px;
                width: 4px;
                height: 4px;
                border-radius: 2px;
                background-color: #bbb;
                content: "";
            }
        }
        @include clearfix();
    }

    a {
        color: #666;

        &:hover {
            text-decoration: none;

            dt {
                text-decoration: underline;
            }
        }
    }
    dt,
    dd {
        display: inline-block;
    }

    dt {
        float: left;
        width: 47%;
        color: #666;
        font-size: 13px;
        font-weight: normal;
        line-height: 20px;
        vertical-align: middle;

        @include text-ellipsis();
    }

    dd {
        float: right;
        font-size: 13px;
        line-height: 20px;
        text-align: right;

        em {
            color: $color-11st-red;
        }

        & > span {
            color: #999;

            &:before {
                padding: 0 4px;
                content: "~";
            }
        }
    }

    @include clearfix();
}

//배송정보
.c_prd_delivery {
    display: block;
    color: #666;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 19px;
    overflow: hidden;

    em {
        float: left;
        margin-right: 2px;
        color: $color-11st-blue;
    }

    .delivery {
        & > span {
            float: left;
        }

        & > span.value,
        & > span.prd_price {
            float: none;
        }

        @include clearfix();
        span:not(:first-of-type) {
            &::before {
                width: 2px;
                height: 2px;
                background-color: #ccc;
                display: inline-block;
                margin: 0 4px;
                vertical-align: middle;
                content: "";
            }
        }

        .c_card_point {
            color: $color-11st-blue;

            &::before {
                display: none !important;
            }
        }

        .value {
            font-family: $font-family-number-lato;
        }

        + .sktpass {
            margin-top: 2px;
        }

        .prd_price {
            display: inline-block;
            color: $color-grayscale-04;
            font-size: 13px;

            &::before {
                display: none !important;
            }
        }
    }

    .sktpass {
        display: block;
        font-size: 13px;
        color: #666;
        line-height: 16px;
        margin-bottom: 2px;

        .value {
            font-family: $font-family-number-lato;
        }

        .c_card_point {
            float: left;
            color: $color_11st_blue;
            font-size: 13px;
        }

        .c_card_text {
            float: left;
        }

        &:before {
            float: left;
            @include get-sprite-common("bi_sktpass", 56);
            margin: 0px 4px 1px 0;
            content: "";
        }

        @include clearfix();
    }

    $order-shooting-icon: (
        delivery: (
            55,
            bi_shooting,
            4px,
        ),
        install: (
            54,
            bi_shooting_install,
            4px,
        ),
        plus: (
            85,
            bi_shooting_plus,
            3px,
        ),
        fresh: (
            54,
            bi_shooting_fresh,
            4px,
        ),
        seller: (
            55,
            bi_shooting_seller,
            4px,
        ),
    );

    @each $iconKey, $iconValue, $iconTopGap in $order-shooting-icon {
        .shooting-#{$iconKey} {
            min-height: 19px;
            margin-left: -9px;

            &::before {
                float: left;
                @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                display: block;
                margin: #{nth($iconValue, 3)} 0 0 9px;
                content: "";
            }

            .c_card_text {
                float: left;
                position: relative;
                margin-left: 9px;
                color: $color-grayscale-04;
                font-size: 13px;
                line-height: 19px;

                &::before {
                    display: block;
                    position: absolute;
                    top: 10px;
                    left: -5px;
                    width: 2px;
                    height: 2px;
                    background: $color-grayscale-07;
                    content: "";
                }
            }

            @include clearfix();
        }
    }

    @include clearfix();
}

//혜택
.c_prd_benefit {
    color: #666;
    font-size: 0;
    line-height: 19px;

    &.active {
        //.c_prd_layer 반영 후 .ly_benefit, 삭제
        .ly_benefit,
        .c_prd_layer {
            display: block;
            z-index: 10;
        }

        .btn_more {
            transform: rotate(180deg);
        }
    }

    .benefit {
        display: inline-block;
        vertical-align: middle;
        font-size: 13px;
        color: #666;

        &:not(:first-child) {
            &::before {
                width: 2px;
                height: 2px;
                background-color: #ccc;
                display: inline-block;
                margin: 0 4px;
                vertical-align: middle;
                content: "";
            }
        }

        em {
            color: $color-11st-blue;
            font-family: $font-family-number-lato;
        }
    }

    //.c_prd_layer 반영 후 삭제
    .ly_benefit {
        display: none;
        position: absolute;
        width: 200px;
        padding: 15px;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #fff;

        .title {
            padding-bottom: 5px;
            border-bottom: 1px solid #efefef;
            color: #222;
            font-size: 13px;
            line-height: 19px;
        }

        .list {
            margin-top: 10px;

            .item {
                color: #222;
                font-size: 12px;
                line-height: 18px;

                em {
                    color: $color-11st-blue;
                }

                &:not(:first-child) {
                    margin-top: 5px;
                }
            }
        }

        .btn_close {
            position: absolute;
            top: 14px;
            right: 10px;
            @include ico-cancel(8px, 1px, #999, 5px);
        }
    }
}

//상품 속성
.c_prd_advertise {
    display: block;
    width: 100%;
    color: $color-11st-green;
    font-size: 13px;
    line-height: 19px;
    @include text-ellipsis();
}

//셀러정보
.c_prd_seller {
    font-size: 13px;
    line-height: 19px;

    .office {
        color: $color-11st-bluegray;
    }

    .name {
        color: #666;
    }
}

//플러스상품
.c_prd_plus {
    color: #999;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    vertical-align: middle;
}

//가격비교 갯수, beta
.c_price_compare {
    @include clearfix;
    vertical-align: middle;

    > a {
        float: left;
        margin-right: 4px;
    }
    &.active {
        .c_prd_layer {
            display: block;
            z-index: 10;
        }
    }

    .sub_text {
        float: left;
        margin-top: 3px;
        color: $color-grayscale-03;
        font-size: 13px;
        line-height: 18px;
    }

    .compare {
        float: left;
        color: #111;
        font-size: 13px;
        line-height: 22px;
        vertical-align: middle;
    }

    .count {
        color: $color-11st-blue;

        .value {
            font-family: $font-family-number-lato;
        }
    }

    //가격비교 버튼
    .c_btn_compare {
        position: relative;
        min-width: 108px;
        height: 29px;
        padding: 0 12px;
        border-radius: 14.5px;
        border: solid 1px #eee;
        background-color: #fff;
        color: #111;
        font-size: 11px;
        letter-spacing: 0;
        line-height: 27px;
        overflow: hidden;
        vertical-align: bottom;

        .count {
            display: inline-block;
            font-weight: bold;
            color: #111;
        }

        .beta {
            display: inline-block;
            @include sprite-retina($sp_list_2x_beta);
            line-height: 200px;
            margin: 5px 0 0 1px;
            vertical-align: top;
        }
    }

    //Beta 버튼
    .c_btn_beta {
        float: left;
        margin-bottom: 1px;
        overflow: hidden;
        line-height: 200px;
        @include sprite-retina($sp_list_2x_btn_beta);

        .info {
            display: inline-block;
            line-height: 120px;
            vertical-align: middle;
        }
    }
}

//가격비교 최저가 업체
.c_seller_price_lowest {
    .list {
        li {
            margin-top: 5px;
            font-size: 13px;
            line-height: 20px;

            a {
                position: relative;
                display: block;
                color: $color-grayscale-03;

                &:hover {
                    text-decoration: none;

                    dt,
                    i {
                        text-decoration: underline;
                    }

                    .c_tooltip {
                        display: block;
                        top: 21px;
                        z-index: 10;
                    }

                    & + .c_tooltip {
                        display: block;
                        z-index: 10;
                    }
                }

                &:focus {
                    text-decoration: none;
                }
            }

            .c_tooltip {
                display: none;
                margin-top: 5px;
            }

            &:first-child,  // MPSR-67225 오픈 후 삭제 (:first-child)
            &.item_lowest {
                a {
                    color: $color-11st-red;
                }
            }

            &.item_official {
                a {
                    color: #333;
                }

                dt {
                    width: 58%;
                    margin-right: -4px;
                    font-size: 12px;
                    letter-spacing: -1px;
                }

                dd {
                    width: 42%;
                }

                i {
                    margin-right: 1px;
                    color: #ff7e0e;
                    font-style: normal;

                    &:before {
                        @include sprite-retina($sp_list_2x_official);
                        display: inline-block;
                        vertical-align: -3px;
                        content: "";
                    }
                }
            }
        }

        dt {
            @include sr-only-reset();
            width: 46%;
            display: inline-block;
            vertical-align: top;
            @include text-ellipsis();
        }

        dd {
            width: 52%;
            display: inline-block;
            text-align: right;
            white-space: nowrap;
        }

        .value {
            font-family: $font-family-number-lato;
        }

        .lowest {
            display: inline-block;
            overflow: hidden;
            line-height: 120px;
            vertical-align: middle;
            margin-top: -2px;
            @include sprite-retina($sp_list_2x_lowest);
        }
    }
}

//찜하기 버튼
.c_btn_favorite {
    position: absolute;
    z-index: 2;
    width: 28px;
    height: 28px;
    border: 1px solid #ddd;
    border-radius: 18px;
    background-color: #fff;
    line-height: 200px;
    overflow: hidden;

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        @include sprite-retina($sp_list_2x_favorite_off);
        -webkit-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        content: "";
    }

    &.active {
        &::after {
            @include sprite-retina($sp_list_2x_favorite_on);
            content: "";
        }
    }
}

//최저가 구매하기
.c_btn_price_lowest {
    position: absolute;
    bottom: 20px;
    display: block;
    min-width: 183px;
    height: 40px;
    border-radius: 20px;
    border: solid 1px #ccc;
    background-color: #fff;
    box-sizing: border-box;
    color: #333;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 37px;
    text-align: center;
    overflow: hidden;

    &:after {
        display: inline-block;
        margin: -3px 0 0 5px;
        vertical-align: middle;
        @include ico-angle("right", 6px, 1px, $color-11st-blue, "static");
    }
}

//장바구니 담기
.c_btn_prd_cart {
    min-width: 68px;
    height: 29px;
    padding: 0 12px;
    border-radius: 17px;
    border: solid 1px #eee;
    background-color: #fff;
    font-size: 13px;
    vertical-align: bottom;

    .cart {
        display: inline-block;
        overflow: hidden;
        line-height: 200px;
        vertical-align: middle;
        @include sprite-retina($sp_list_2x_cart);
    }
}

.c_btn_more {
    &.c_btn_more_style_1 {
        margin-left: 4px;
        @include sprite-retina($sp_list_2x_benefit_more);
        line-height: 200px;
        vertical-align: middle;
        overflow: hidden;
    }
}

.c_prd_saving {
    margin: 2px 0 0 10px;
    display: inline-block;

    .saving {
        position: relative;
        display: inline-block;
        color: #999;
        font-size: 12px;
        line-height: 20px;

        &::after {
            position: absolute;
            left: 0px;
            bottom: 1px;
            height: 1px;
            width: 100%;
            background-color: #999;
            content: "";
        }

        .value {
            font-family: $font-family-number-lato;
            line-height: 18px;
        }
    }

    &.active {
        .c_prd_layer {
            display: block;
            z-index: 10;
        }
    }
}

// 남은수량
.c_prd_stock {
    display: inline-block;
    color: #666;
    font-size: 12px;
    line-height: 18px;

    .count {
        display: inline-block;
        color: $color-11st-red;
        font-family: $font-family-number-lato;
    }
}

// 구매수량
.c_prd_buy {
    display: inline-block;
    color: #666;
    font-size: 12px;
    line-height: 18px;

    .deadline {
        display: inline-block;
        color: $color-11st-blue;

        & + .count {
            &::before {
                display: inline-block;
                width: 2px;
                height: 2px;
                background-color: #ccc;
                margin: -2px 4px 0;
                vertical-align: middle;
                content: "";
            }
        }
    }

    .count {
        display: inline-block;
        font-family: $font-family-number-lato;
    }
}

.c_prd_charge {
    .charge {
        display: block;
        color: $color-grayscale-05;
        font-size: 13px;
        line-height: 18px;

        &:not(:first-of-type) {
            color: $color-grayscale-04;
        }

        em {
            color: $color-11st-blue;
        }
    }
}

.c_prd_type {
    font-size: 17px;
    font-weight: bold;
    letter-spacing: 0;
    color: #111;
}

// 쿠폰썸네일
.c_prd_coupon {
    a {
        position: relative;
        &:hover {
            text-decoration: none;

            .name {
                text-decoration: underline;
            }
        }
    }
    .sale {
        position: relative;
        display: inline-block;
        height: 20px;
        padding: 0 13px 0 5px;
        vertical-align: top;

        em {
            display: inline-block;
            white-space: nowrap;
            font-size: 11px;
            line-height: 20px;
            font-family: $font-family-lato;
            color: $color-grayscale-02;
            vertical-align: top;
            font-weight: 700;

            &:before,
            &:after {
                @include size(4px 3px);
                position: absolute;
                z-index: 1;
                right: 5px;
                content: "";
            }
            &:before {
                top: 0;
                @include sprite-retina($sp_card_item_2x_coupon_top);
            }
            &:after {
                bottom: 0;
                @include sprite-retina($sp_card_item_2x_coupon_bottom);
            }
        }

        &:before {
            @include size(8px 20px);
            position: absolute;
            right: 0;
            top: 0;
            background: $color-11st-gradient-vertical;
            content: "";
        }

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 2px;
            content: "";
        }
    }
    .name {
        position: relative;
        display: inline-block;
        margin: 0 0 0 4px;
        padding: 0 10px 0 0;
        line-height: 20px;

        &:after {
            position: absolute;
            top: 50%;
            right: 1px;
            width: 5px;
            height: 5px;
            margin-top: -3px;
            border: solid #666;
            border-width: 1px 1px 0 0;
            transform: rotate(45deg);
            content: "";
        }
    }
}

.c_prd_noti {
    color: #f43142;
    font-size: 13px;
}

// 상품정보 set
.c_card {
    .c_seller_grade {
        float: left;
        margin-right: 4px;
        margin-top: 1px;
    }

    .c_review {
        float: left;
        line-height: 15px;
    }

    .c_prd_meta {
        @include clearfix();
    }

    .c_prd_type {
        margin-top: 10px;
    }

    .c_card_info_bottom {
        font-size: 0;
    }

    .c-flag-box {
        max-height: 52px;

        & + .c_prd_brand {
            margin-top: 2px;
        }
    }

    //상품정보 set : 리스트형
    &.c_card_list {
        display: flex;
        width: 100%;
        position: relative;
        box-sizing: border-box;
        @include clearfix();

        .c_prd_thumb {
            float: left;
        }

        .c_card_info {
            float: left;
            position: relative;
            vertical-align: top;
        }

        .c_card_info_floting {
            position: relative;
        }

        .c_prd_price {
            margin-bottom: 6px;
        }

        .c_prd_meta {
            line-height: 17px;
        }

        .c_btn_favorite {
            top: -2px;
            right: 0;
        }

        .c-flag {
            &--luxury {
                margin-top: 14px;
            }
        }
    }
    //상품정보 set : 갤러리형
    &.c_card_gellery {
        height: 100%;

        .c_prd_thumb {
            $c_prd_thumb: (235px, 235px);
            @include c-prd-thumb($c_prd_thumb);
        }

        .c_card_info {
            position: relative;
            padding: 7px 10px 20px;
            box-sizing: border-box;
        }

        .c_prd_price {
            margin-top: 5px;

            .rate {
                font-size: 14px;

                .unit {
                    font-size: 11px;
                }
            }

            .price {
                display: block;
            }

            .price_deal {
                dt {
                    font-size: 14px;
                }

                dd {
                    display: block;
                }
            }
        }

        .c_prd_meta {
            margin-top: 5px;
        }

        .c_btn_favorite {
            position: absolute;
            z-index: 2;
            top: 10px;
            right: 10px;
        }
        .c_prd_type {
            margin-top: 5px;
            line-height: 20px;
        }
    }
}

.c_listing {
    min-width: 960px;

    > li {
        border-bottom: 1px solid #eee;
        box-sizing: border-box;

        &:last-child {
            border-bottom: none;
        }
    }

    .c-nation {
        .nation {
            &::before {
                top: 7px;
                left: 2px;
            }
        }
    }

    .c_prd_price {
        .price_special {
            float: left;
            margin-right: 2px;
            color: $color-11st-red;
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;

            > p {
                display: inline-block;
            }
        }
    }

    //view_type llst
    &.c_listing_view_type_list {
        > li {
            position: relative;
            min-height: 200px;
            padding: 10px 0;
            @include clearfix();
        }

        .c_card_info {
            position: relative;
            min-height: 180px;
            width: calc(100% - 403px); // 썸네일 180 + c_card_info(3) 223px = 403px
            height: inherit;
            padding: 18px 20px 20px;
            box-sizing: border-box;

            &:nth-of-type(3) {
                width: 223px;

                .c_prd_delivery {
                    position: absolute;
                    left: 20px;
                    bottom: 20px;
                }

                &::before {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 20px;
                    width: 1px;
                    height: calc(100% - 40px);
                    background-color: #eee;
                    content: "";
                }
            }
        }

        .c-flag-box {
            .c-flag {
                float: left;
                margin-bottom: 0;
            }
        }

        .c_prd_name {
            width: calc(100% - 30px);
            font-size: 15px;
        }

        .c_prd_option {
            width: calc(100% - 30px);
        }

        .c_prd_price {
            margin-top: 5px;

            .price {
                line-height: 22px;
            }

            .price_unit {
                margin-left: 5px;
            }

            .price_11st_lowest {
                float: left;

                .c_layer_expand {
                    &.active {
                        z-index: 10;
                    }
                }

                & + .rate,
                & + .price_regular {
                    margin-left: 4px;
                }

                & + .price_special {
                    &::before {
                        float: left;
                        width: 2px;
                        height: 2px;
                        margin: 9px 5px;
                        background: $color-11st-red;
                        border-radius: 50%;
                        content: "";
                    }
                }
            }

            .price_reservation {
                margin-top: 5px;
            }
            .price_reservation {
                dt {
                    font-size: 17px;
                }
            }
        }
        .c_prd_car_price {
            font-size: 13px;
        }

        .c_btn_favorite {
            position: absolute;
            z-index: 2;
            top: 18px;
            right: 20px;
        }

        .c_card_info_floting {
            position: absolute;
            right: 16px;
            bottom: 18px;

            .c_prd_plus {
                display: inline-block;
            }

            .c_price_compare {
                display: inline-block;
                margin-left: 6px;
            }
        }

        .c_prd_delivery {
            margin-top: 6px;

            .sktpass {
                float: left;
            }

            & + .c_prd_release {
                margin-top: 0;
            }
        }

        .c_prd_advertise {
            width: calc(100% - 200px); //가격비교 , 장바구니 담기 영역 제외
            margin-top: 8px;
        }

        .c_prd_seller {
            .name {
                display: block;
                height: 19px;
                overflow: hidden;
                @include text-ellipsis();
            }

            .c_tooltip {
                display: none;
                margin-top: 5px;
            }

            a:hover {
                & + .c_tooltip {
                    display: block;
                    z-index: 10;
                }
            }
        }

        .c_prd_release {
            margin-top: 6px;
        }

        .c_prd_model {
            width: calc(100% - 200px);
            margin: 10px 0 10px 200px;
        }

        .c_prd_charge {
            margin-top: 8px;
        }

        .c_prd_noti {
            margin-top: 10px;
        }

        //service_type 가격비교
        &.c_listing_service_type_compare {
            .c_card_info {
                padding-bottom: 10px;

                &:nth-of-type(2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }

                &:nth-of-type(3) {
                    padding-bottom: 62px;

                    &:before {
                        height: calc(100% - 30px);
                    }
                }
                //출시예정
                &:nth-child(2):nth-last-child(1) {
                    width: calc(100% - 180px);
                    padding-right: 0;

                    .c_prd_spec {
                        width: calc(100% - 20px);
                    }
                }
            }

            .c_card_info_top {
                min-height: 135px;
            }

            .c_card_info_bottom {
                position: relative;
                width: 100%;
                min-height: 30px;
            }

            .c_prd_spec {
                display: table;
                vertical-align: bottom;

                .list {
                    display: block;
                    max-height: 50px;
                    overflow: hidden;
                }
            }

            //가격비교 갯수
            .c_price_compare {
                position: relative;
                right: auto;
                bottom: auto;

                .compare {
                    font-weight: bold;
                    font-size: 14px;
                }

                .count {
                    font-weight: normal;
                }
            }

            .c_btn_price_lowest {
                bottom: 10px;
            }
        }

        //service_type 휴대폰샵
        &.c_listing_service_type_hp {
            .c_card_info_top {
                min-height: 108px;

                .c_prd_price {
                    margin-top: 3px;
                }

                .c_prd_charge {
                    margin-top: 12px;
                }
            }

            .c_card_info_bottom {
                margin-top: 14px;
            }

            .c_prd_release {
                display: inline-block;
                padding-top: 6px;
                vertical-align: bottom;
                line-height: 14px;

                .release {
                    color: #666;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 20px;
                }

                & + .c_prd_spec {
                    width: calc(100% - 92px);

                    .list {
                        li {
                            &:first-child {
                                &::before {
                                    display: inline-block;
                                    width: 1px;
                                    height: 10px;
                                    margin: 3px 6px 0;
                                    background-color: #efefef;
                                    content: "";
                                }
                            }
                        }
                    }
                }
            }

            .c_prd_spec {
                display: inline-block;
                width: 100%;
                border-top: none;
                vertical-align: bottom;

                .list {
                    li {
                        &:not(:first-child) {
                            &::before {
                                display: inline-block;
                                width: 1px;
                                height: 10px;
                                margin: 8px 6px 0;
                                background-color: #efefef;
                                content: "";
                            }
                        }
                    }

                    .spec {
                        font-size: 12px;
                        line-height: 26px;

                        &:first-child {
                            color: $color-grayscale-05;
                        }

                        &:last-child {
                            margin-left: 3px;
                            color: $color-grayscale-04;
                        }
                    }
                }
            }

            .c_card_info:nth-of-type(3) {
                .c_prd_delivery {
                    bottom: 22px;
                }
            }
        }

        //service_type Amazon
        &.c_listing_service_type_amazon {
            & > li {
                padding: 20px 0;

                &:first-child {
                    padding-top: 10px;
                }
            }

            .c_prd_thumb {
                height: auto;

                & > a {
                    position: relative;
                    display: block;
                    width: 180px;
                    height: 180px;
                }

                .c_prd_swatch {
                    margin-top: 10px;
                }
            }

            .c_card_info {
                padding: 0 20px;

                &:nth-of-type(3) {
                    &:before {
                        top: 0;
                        height: 100%;
                    }

                    .c_prd_delivery {
                        bottom: 0;
                    }
                }
            }

            .c_prd_name {
                margin-top: 2px;
            }

            .c_prd_price {
                margin-top: 10px;

                .price_11st_lowest {
                    position: relative;

                    .c_layer_expand {
                        position: static;

                        .c_layer_item {
                            top: 22px;
                            left: 0;
                        }
                    }
                }

                .rate {
                    font-size: 15px;

                    .unit {
                        font-size: 15px;
                    }
                }

                .price {
                    &::before {
                        display: block;
                        content: "";
                    }
                }

                .price_unit {
                    margin: 0;
                }

                .price_deal {
                    dd {
                        display: block;
                    }
                }

                .price_regular {
                    font-size: 15px;

                    & + .price {
                        margin-top: 3px;
                        display: block;
                    }
                }
            }

            .c-starrate {
                margin-top: 4px;
                line-height: 1.5;
                color: #666;
            }

            .c_prd_delivery {
                margin-top: 12px;
            }

            .c_prd_addition {
                margin-top: 10px;
            }
        }

        //service_type Luxury
        &.c_listing_service_type_luxury {
            .c_prd_price {
                .rate {
                    font-size: 20px;
                    line-height: 22px;
                }

                .price {
                    float: left;
                    font-size: 20px;
                    line-height: 22px;
                }

                .price_regular {
                    float: left;
                    font-size: 15px;
                    margin-top: 3px;
                }
            }

            .c_card_info:nth-of-type(2) {
                display: flex;
                .c_card_info_top {
                    width: 100%;
                    align-self: center;
                }
            }

            .c-flag-box {
                &--luxury {
                    margin-top: 14px;
                }
            }
        }

        //리스트 뷰타입 스타일
        &.c_listing_view_type_style_1 {
            .c_prd_price {
                .rate {
                    font-size: 15px;

                    .unit {
                        font-size: 15px;
                        line-height: 20px;
                    }
                }
                .price_regular {
                    font-size: 15px;
                }
                .price_deal {
                    dt {
                        display: block;
                    }
                }
                .price_unit {
                    display: block;
                    margin: 0;
                }
            }
        }
    }

    //view_type gallery
    &.c_listing_view_type_gallery {
        position: relative;
        margin-top: 0;
        font-size: 0;
        display: block;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 1px;
            background-color: #fff;
            content: "";
        }

        &.col_4 {
            li {
                width: 237px;
                flex: 0 1 237px;
                margin-right: 18px;

                &:nth-of-type(4n) {
                    margin-right: 0;
                    &::after {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    bottom: -1px;
                    right: -20px;
                    width: 20px;
                    height: 1px;
                    background-color: rgb(238, 238, 238);
                    content: "";
                }
            }

            .c_card {
                max-width: 237px;
                margin: 0 auto;
            }

            .c_prd_thumb {
                $c_prd_thumb: (235px, 235px);
                @include c-prd-thumb($c_prd_thumb);

                > a {
                    width: 235px;
                    height: 235px;
                }
            }
        }

        &.col_5 {
            li {
                width: 190px;
                flex: 0 1 190px;
                margin-right: 13px;

                &:nth-of-type(5n) {
                    margin-right: 0;

                    &::after {
                        display: none;
                    }
                }

                &::after {
                    position: absolute;
                    bottom: -1px;
                    right: -20px;
                    width: 20px;
                    height: 1px;
                    background-color: rgb(238, 238, 238);
                    content: "";
                }
            }

            .c_card {
                max-width: 190px;
                margin: 0 auto;
            }

            .c_prd_thumb {
                $c_prd_thumb: (188px, 188px, 0 0 0 0);
                @include c-prd-thumb($c_prd_thumb);
            }

            .c_prd_name {
                font-size: 13px;
                line-height: 1.4;

                &.c_prd_name_row_2 {
                    max-height: 2.8em;
                }
            }

            .c_prd_option {
                font-size: 12px;
            }

            .c_prd_price {
                .price_unit {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .c_prd_delivery {
                font-size: 12px;
                letter-spacing: -1px;

                .shooting-delivery {
                    font-size: 12px;

                    &::before {
                        @include get-sprite-common("bi_shooting", 51);
                        margin: 4px 0 0 0;
                    }
                    .sr-only {
                        font-size: 12px;
                        line-height: 19px;
                    }
                    .c_card_text {
                        font-size: 12px;
                        line-height: 19px;
                    }
                }

                $order-shooting-icon: (
                    delivery: (
                        51,
                        bi_shooting,
                        4px,
                    ),
                    install: (
                        50,
                        bi_shooting_install,
                        4px,
                    ),
                    plus: (
                        81,
                        bi_shooting_plus,
                        3px,
                    ),
                    fresh: (
                        50,
                        bi_shooting_fresh,
                        4px,
                    ),
                );

                @each $iconKey, $iconValue, $iconTopGap in $order-shooting-icon {
                    .shooting-#{$iconKey} {
                        font-size: 12px;
                        min-height: 19px;
                        margin-left: -9px;

                        &::before {
                            @include get-sprite-common(#{nth($iconValue, 2)}, nth($iconValue, 1));
                            margin: #{nth($iconValue, 3)} 0 0 9px;
                        }

                        .sr-only {
                            font-size: 12px;
                            line-height: 19px;
                        }
                        .c_card_text {
                            font-size: 12px;
                            line-height: 19px;

                            &::before {
                                top: 8px;
                            }
                        }

                        @include clearfix();
                    }
                }
            }

            .c_prd_benefit {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -1px;
            }

            .c_prd_advertise {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -1px;
            }

            .c_prd_seller {
                font-size: 12px;
                line-height: 18px;
                letter-spacing: -1px;
            }

            .c_btn_favorite {
                top: 8px;
            }
        }

        &.c_prd_size_180 {
            .c_card {
                max-width: 182px;
            }

            .c_prd_thumb {
                $c_prd_thumb: (180px, 180px, 0 0 0 0);
                @include c-prd-thumb($c_prd_thumb);
            }
        }

        &.c_prd_size_235 {
            .c_card {
                max-width: 237px;
            }

            .c_prd_thumb {
                $c_prd_thumb: (235px, 235px, 0 0 0 0);
                @include c-prd-thumb($c_prd_thumb);
            }
        }

        li {
            display: inline-block;
            position: relative;
            width: 100%;
            height: auto;
            flex: 0 1 100%;
            font-size: 12px;
            vertical-align: top;
            padding-top: 20px;

            .c_card {
                border: 1px solid transparent;
                height: 100%;
                box-sizing: border-box;

                &:hover {
                    border-color: $color-11st-red;
                }
            }

            &:last-child {
                .c_card {
                    height: calc(100% - 1px);
                }

                &::after {
                    display: none;
                }
            }
        }

        .c-flag-box {
            margin-top: 3px;
        }

        .c_prd_name {
            width: calc(100% - 30px);

            &--wide {
                width: 100%;
            }
        }

        .c_prd_option {
            width: calc(100% - 30px);
        }

        .c_prd_price {
            .price,
            .price_deal,
            .price_lowest {
                display: block;
            }

            .price_11st_lowest {
                position: relative;

                .text {
                    & + .value {
                        clear: both;
                        margin-left: 0;
                    }
                }

                .c_layer_expand {
                    position: static;

                    .c_layer_item {
                        top: 22px;
                        left: 0;
                    }
                }
            }

            .price_lowest {
                & + .price_unit {
                    margin: 3px 0 0 44px;
                }
            }
            .price_reservation {
                dt {
                    font-size: 17px;
                }
            }
        }

        .c_prd_car_price {
            font-size: 13px;
        }

        .c_prd_delivery {
            margin-top: 8px;
        }

        .c_prd_benefit {
            margin-top: 8px;

            & + .c_prd_advertise {
                margin-top: 0;
            }
        }

        .c_prd_advertise {
            margin-top: 8px;
        }

        .c_prd_seller {
            display: block;
            margin-top: 8px;
            height: 19px;
            overflow: hidden;
            @include text-ellipsis();

            .c_tooltip {
                display: none;
                margin-top: 5px;
            }

            a:hover {
                & + .c_tooltip {
                    display: block;
                    z-index: 10;
                }
            }
        }

        .c_prd_release {
            span.release {
                margin-top: 2px;
            }
            dl.release {
                margin-top: 8px;
            }
        }

        .c_price_compare {
            margin-top: 8px;
        }

        .c_prd_noti {
            margin-top: 10px;
        }

        //service_type 가격비교
        &.c_listing_service_type_compare {
            .c_card_info {
                height: calc(100% - 235px);
                padding-bottom: 79px;
            }

            .c_btn_price_lowest {
                bottom: 20px;
                width: calc(100% - 22px);
            }
        }

        //service_type 오늘발송
        &.c_listing_service_type_today {
            margin-top: 0;

            &:after {
                display: none;
            }

            & > li {
                padding-top: 0;
                border-bottom: none;
            }

            .c_card_info {
                padding-bottom: 48px;
                height: calc(100% - 180px);
            }

            .c_card_info_bottom {
                position: absolute;
                bottom: 20px;
                width: 100%;
                height: 28px;

                .c_prd_delivery {
                    position: absolute;
                    bottom: 0;
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .c_prd_name {
                font-size: 13px;
                line-height: 1.4;

                &.c_prd_name_row_2 {
                    max-height: 2.8em;
                }
            }

            .c_prd_price {
                .price_unit {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .c_prd_benefit {
                .benefit {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .c_prd_advertise {
                font-size: 12px;
                line-height: 18px;
            }

            .c_prd_seller {
                font-size: 12px;
                line-height: 18px;
            }

            .c_btn_favorite {
                top: 8px;
            }
        }

        //service_type Amazon 갤러리
        &.c_listing_service_type_amazon {
            &.col_4 {
                li {
                    &:nth-of-type(4n) {
                        .price_11st_lowest {
                            .c_layer_expand {
                                .c_layer_item {
                                    left: -70px;
                                }
                            }
                        }
                    }
                }
            }
            .c_prd_thumb {
                height: auto;

                & > a {
                    position: relative;
                    display: block;
                    width: 235px;
                    height: 235px;
                }

                .c_prd_swatch {
                    margin-top: 12px;
                }
            }

            .c-nation {
                margin-top: 5px;
            }

            .c-starrate {
                margin-top: 6px;
                line-height: 1.5;
            }

            .c_prd_price {
                margin-top: 10px;

                .rate {
                    font-size: 15px;

                    .unit {
                        font-size: 15px;
                    }
                }

                .price_regular {
                    font-size: 15px;

                    & + .price {
                        display: block;
                    }
                }

                .price_unit {
                    margin: 2px 0 0 0;
                }
            }

            .c_prd_addition {
                margin-top: 8px;
            }

            .c_prd_delivery {
                margin-top: 9px;

                .sktpass {
                    .c_card_point {
                        margin-right: 4px;
                    }
                }
            }

            .c_prd_benefit {
                margin-top: 0;

                .benefit {
                    display: block;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

html.ie9 {
    .c_listing_view_type_gallery {
        li {
            border-bottom: none;
            padding-bottom: 1px;
        }
    }

    .c_listing_service_type_compare {
        .c_card_info {
            &:nth-of-type(3) {
                height: 220px;
            }
        }
    }
}
