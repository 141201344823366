@charset "UTF-8";

.c-timeline {
    @include clearfix;
    position: relative;

    &:before {
        display: inline-block;
        vertical-align: top;
        background: url("/img/pui/pc_content_timeline/realtime.svg") no-repeat;
        width: 833px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 244px;
        transform: translateY(-50%);
        content: "";
    }

    &__time {
        position: relative;
        float: right;
        height: 38px;
        margin: 0 35px 0 0;
        line-height: 36px;
        padding: 0 45px 0 20px;
        border: 1px solid #eee;
        border-radius: 19px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
        background: $color-gray-14;
        color: $color-gray-03;
        font-size: 15px;
        z-index: 10;
        font-family: $font-family-lato;
    }

    &__button {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: $color-gray-03;
        font-size: 15px;
        font-family: $font-family-lato;
        line-height: 200px;

        &::before {
            @include get-sprite-common("reloading", 14);
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
        }
    }

    &__circle {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &:nth-child(1) {
            left: 167px;
            top: 14px;
        }

        &:nth-child(2) {
            left: 484px;
            top: 14px;
        }

        &:nth-child(3) {
            left: 802px;
            top: 14px;
        }
    }
}
