// Path
$svgPath: "/img/svg/logo";

// URLs
$logo-amazon: url("#{$svgPath}/amazon.svg");
$logo-amazon-allwhite: url("#{$svgPath}/amazon_allwhite.svg");
$logo-amazon-white: url("#{$svgPath}/amazon_white.svg");
$logo-amazon-black: url("#{$svgPath}/amazon_black.svg");
$logo-amazon-icon: url("#{$svgPath}/amazon_icon.svg");
$logo-allclub: url("#{$svgPath}/allclub.svg");
$logo-11st: url("#{$svgPath}/11st.svg");
$logo-olkl-icon: url("#{$svgPath}/olkl_icon.svg"); //우주 아이콘
$logo-olkl: url("#{$svgPath}/olkl.svg"); //우주
$logo-sktpass: url("#{$svgPath}/olklpass.svg"); //우주패스1
$logo-sktpass2: url("#{$svgPath}/olklpass2.svg"); //우주패스2
$logo-tolkl: url("#{$svgPath}/tolkl.svg"); //t우주
$logo-sktmembership: url("#{$svgPath}/t_membership.svg"); //T멤버십
$logo-tpluspoint: url("#{$svgPath}/t_pluspoint.svg"); //T플러스포인트
$logo-shooting-delivery: url("#{$svgPath}/shooting_delivery.svg"); //슈팅배송
$icon-help: url("#{$svgPath}/icon_help.svg");
$icon-info: url("#{$svgPath}/icon_info.svg");

@mixin logo-svg($logo-var, $width, $height) {
    width: $width;
    height: $height;
    background-image: $logo-var;
    background-size: $width $height;
}
