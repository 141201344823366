@charset "UTF-8";

@import "mixins/size";
@import "mixins/sprite";
@import "mixins/mediaquery";
@import "mixins/logo_svg";
@import "mixins/loading-spinner";
@import "mixins/loading-spinner-inner";
@mixin clearfix {
    zoom: 1;

    &:after {
        display: block;
        content: "";
        clear: both;
    }
}

@mixin skip {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

@mixin skip_reset {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    white-space: normal;
}

@mixin sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    margin: -1px;
    width: 1px;
    height: 1px;
}

@mixin sr-only-reset {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    white-space: normal;
}

@mixin text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin text-ellipsis-multiple($line) {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    white-space: initial;
}

@mixin skipNavi {
    position: absolute;
    z-index: 9999;
    width: 100%;

    a {
        display: block;
        overflow: hidden;
        width: 980px;
        margin: 0 auto;
        height: 1px;
        margin-bottom: -1px;
        white-space: nowrap;
        text-align: center;
        text-indent: -9999px;

        &:focus,
        &:active {
            height: 34px;
            padding: 1px;
            border-bottom: 2px solid #ebe8e7;
            background: #666;
            color: #333;
            font-weight: bold;
            font-size: 14px;
            text-indent: 0;
            line-height: 33px;
        }

        &:focus span,
        &:active span {
            display: block;
            height: 32px;
            border: 1px solid #fff;
            background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#fff), to(#f7f7f7));
            background: -moz-linear-gradient(top, #fff, #f7f7f7);
            background: -o-linear-gradient(top, #fff, #f7f7f7);
            background: -ms-linear-gradient(top, #fff, #f7f7f7);
        }
    }
}

//리뉴얼 안된 페이지에 리뉴얼된 콘텐츠가 들어갈 시 폰트 오버라이딩
@mixin fontset {
    font-size: 14px;
    line-height: 1.5;
    font-family: $font-family-default;
    color: #666;
    letter-spacing: -1px;
}

// 안이 채워진 삼각형
@mixin ico-triangle($direction: "down", $width: 8px, $height: 5px, $color: #000, $position: "absolute") {
    width: 0;
    height: 0;
    content: "";

    @if ($position == "absolute") {
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    @if ($direction == "up") {
        border-bottom: $height solid $color;
        border-left: round($width/2) solid transparent;
        border-right: round($width/2) solid transparent;
    } @else if ($direction == "down") {
        border-top: $height solid $color;
        border-left: round($width/2) solid transparent;
        border-right: round($width/2) solid transparent;
    } @else if ($direction == "left") {
        border-top: round($height/2) solid transparent;
        border-bottom: round($height/2) solid transparent;
        border-right: $width solid $color;
    } @else if ($direction == "right") {
        border-top: round($height/2) solid transparent;
        border-bottom: round($height/2) solid transparent;
        border-left: $width solid $color;
    }
}

// > , < 모양
@mixin ico-angle($direction: "right", $size: 50px, $thick: 1px, $color: #000, $position: "absolute") {
    width: $size;
    height: $size;
    border: 1px solid $color;
    border-width: $thick $thick 0 0;
    transform-origin: center center;
    content: "";

    @if ($position == "absolute") {
        @if ($direction == "right") {
            transform: rotate(45deg);
        } @else if ($direction == "left") {
            transform: rotate(-135deg);
        } @else if ($direction == "up") {
            transform: rotate(-45deg);
        } @else if ($direction == "down") {
            transform: rotate(-225deg);
        }
    } @else if ($position == "static") {
        vertical-align: middle;

        @if ($direction == "right") {
            transform: rotate(45deg);
        } @else if ($direction == "left") {
            transform: rotate(-135deg);
        } @else if ($direction == "up") {
            transform: rotate(-45deg);
        } @else if ($direction == "down") {
            transform: rotate(135deg);
        }
    }
}

@mixin ico-angle-retina($direction: "right", $size: 50px, $thick: 3px, $color: #000, $position: "absolute") {
    width: $size;
    height: $size;
    border: 1px solid $color;
    border-width: $thick $thick 0 0;
    transform-origin: center center;
    content: "";

    @if ($position == "absolute") {
        @if ($direction == "right") {
            transform: scale(0.5) rotate(45deg);
        } @else if ($direction == "left") {
            transform: scale(0.5) rotate(-135deg);
        } @else if ($direction == "up") {
            transform: scale(0.5) rotate(-45deg);
        } @else if ($direction == "down") {
            transform: scale(0.5) rotate(-225deg);
        }
    } @else if ($position == "static") {
        vertical-align: middle;

        @if ($direction == "right") {
            transform: scale(0.5) rotate(45deg);
        } @else if ($direction == "left") {
            transform: scale(0.5) rotate(-135deg);
        } @else if ($direction == "up") {
            transform: scale(0.5) rotate(-45deg);
        } @else if ($direction == "down") {
            transform: scale(0.5) rotate(135deg);
        }
    }
}

// x 모양
@mixin ico-cancel($size: 40px, $thick: 2px, $color: #999, $padding: 0) {
    $sqrt_2: 1.41421356237;
    width: $size + $padding * 2;
    height: $size + $padding * 2;
    overflow: hidden;
    line-height: 200px;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size * $sqrt_2 - $thick);
        height: $thick;
        background-color: $color;
        content: "";
    }
    &:before {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &:after {
        -webkit-transform: translate(-50%, -50%) rotate(135deg);
        transform: translate(-50%, -50%) rotate(135deg);
    }
}

// x 모양 2배 수
@mixin ico-cancel-retina($size: 40px, $thick: 2px, $color: #999, $padding: 0) {
    $sqrt_2: 1.41421356237;
    width: $size + $padding * 2;
    height: $size + $padding * 2;
    overflow: hidden;
    line-height: 200px;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size * $sqrt_2 - $thick);
        height: $thick;
        background-color: $color;
        content: "";
    }
    &:before {
        -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
        transform: translate(-50%, -50%) scale(0.5) rotate(45deg);
    }
    &:after {
        -webkit-transform: translate(-50%, -50%) scale(0.5) rotate(135deg);
        transform: translate(-50%, -50%) scale(0.5) rotate(135deg);
    }
}

// + 모양
@mixin ico-add($size: 40px, $thick: 2px, $color: #999, $padding: 0) {
    $sqrt_2: 1.41421356237;
    position: relative;
    width: $size + $padding * 2;
    height: $size + $padding * 2;
    overflow: hidden;
    line-height: 200px;

    &:before,
    &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: round($size * $sqrt_2 - $thick);
        height: round($thick/2);
        background-color: $color;
        margin-left: round($size/2 * -1);
        content: "";
    }
    &:before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
    &:after {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@mixin img-center {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

//background image 가 뭉개지거나 흐릿한 경우 적용
@mixin image-rendering {
    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
}

@mixin flex-list-gap-container($itemleftGap) {
    display: flex;
    width: calc(100% + #{$itemleftGap});
    margin-left: calc(#{-$itemleftGap}/ 2);
    //padding-top: calc(2rem / 2);
}
@mixin flex-list-gap-item($itemWideSize, $itemleftGap) {
    width: $itemWideSize;
    //padding-top: $itemleftGap;
    padding-right: calc(#{$itemleftGap}/ 2);
    padding-left: calc(#{$itemleftGap}/ 2);
}
