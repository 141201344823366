[data-type="PC_GridList_AllServices"] {
    max-width: $pui-width-fixed;
    min-height: 220px;
    margin: 0 auto;

    .c-list {
        width: 100%;
        border: 1px solid #eee;
        border-radius: 4px;
        background: #fcfcfc;
        @include clearfix;

        &__container {
            @include flex-list-gap-container(0);
        }

        &__item {
            @include flex-list-gap-item(25%, 0);
            padding: 28px 40px 21px;

            &:not(:first-of-type) {
                border-left: 1px solid $color-gray-11;
            }
        }

        &__title {
            margin: 0 0 16px 0;
            font-size: 17px;
            color: $color-gray-02;
            font-weight: bold;
        }

        &__service {
            color: $color-gray-04;
            font-size: 16px;
            line-height: 32px;
        }
    }

    .c-iconnew {
        &--11red-small {
            margin-top: -4px;
            vertical-align: middle;
        }
    }
}
