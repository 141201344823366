@charset "UTF-8";

.c-refresh {
    position: relative;
    width: 212px;
    height: 38px;
    line-height: 36px;
    padding: 0 36px 0 10px;
    border: 1px solid $color-gray-11;
    border-radius: 19px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    background: $color-gray-14;
    color: $color-gray-03;
    font-size: 15px;
    z-index: 10;
    font-family: $font-family-lato;
    text-align: center;

    &__button {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        color: $color-gray-03;
        font-size: 15px;
        font-family: $font-family-lato;
        line-height: 200px;

        &::before {
            @include get-sprite-common("reloading", 14);
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
        }
    }
}
