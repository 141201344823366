[data-type="PC_MenuGoto"] {
    min-height: 125.5px;

    .c-list {
        &__container {
            @include flex-list-gap-container(12px);
        }

        &__item {
            @include flex-list-gap-item(25%, 12.5px);
            min-width: 90px;
            box-sizing: border-box;
        }
    }

    .c-icontext {
        width: 100%;
        border-radius: 27px;
        padding-top: 90px;
        background-color: #fff;

        &__icon {
            width: 90px;
            height: 90px;
        }

        &::after {
            background: #f9fafd;
        }

        &:hover {
            color: $color-11st-red;
            text-decoration: underline;
            .c-icontext__text {
                color: $color-11st-red;
            }
        }
    }
}
