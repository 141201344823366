[data-type="PC_ProductList_Time_Swipe"] {
    width: $pui-width-fixed;
    height: 302px;
    margin: 0 auto;

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-list {
        .swiper-wrapper {
            display: flex;

            .swiper-slide,
            .slider-slide {
                vertical-align: top;

                > ul {
                    height: 100%;
                    box-sizing: border-box;

                    > li {
                        min-width: 1240px;
                    }
                }
            }
        }
    }

    .slider-slide {
        vertical-align: top;
    }

    .c-flag-box {
        position: absolute;
        top: 95px;
        left: 0;
        max-height: 25px;
    }

    .c-addition {
        .c-addition-link {
            display: flex;
        }

        .c-addition-coupon {
            display: flex;
            flex-grow: 0;
            max-width: 50%;

            &__text {
                max-width: 100%;
            }
        }
    }

    .c-card-item {
        $c-card-item: &;
        width: 1240px;
        height: 100%;
        overflow: hidden;

        &:hover {
            .c-card-item__thumb {
                .c-lazyload {
                    img {
                        transform: scale(1.08);
                    }
                }
            }
        }

        &.c-card-item--list {
            display: flex;

            .c-starrate {
                line-height: 18px;
                margin: 0 0 5px 0;
            }

            @at-root #{$c-card-item} {
                &__info {
                    @include clearfix;
                    display: block;
                    position: relative;
                    width: 637px;
                    height: 302px;
                    min-height: 121px;
                    padding: 32px 30px 50px 0;

                    border-top: 1px solid $color-gray-11;
                    border-bottom: 1px solid $color-gray-11;
                    box-sizing: border-box;
                }

                &__thumb-emblem {
                    top: 8px;
                    left: 12px;
                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                &__thumb {
                    display: block;
                    width: 605px;
                    min-width: 605px;
                    height: 302px;
                    margin-right: 30px;
                    overflow: hidden;

                    .c-lazyload {
                        border-radius: 4px;

                        img {
                            transition: transform 0.2s ease-in;
                        }

                        &::after {
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            border-radius: 4px;
                            box-shadow: 0px 0px 0px 1px inset rgba(0, 0, 0, 0.04);
                            background: rgba(0, 0, 0, 0.02);
                            content: "";
                        }
                    }
                }

                &__name {
                    height: 24px;
                    margin: 0 0 8px 0;
                    line-height: 22px;
                    font-size: 19px;
                }

                &__soldout-text {
                    left: 10px;
                    top: 10px;
                    width: 204px;
                    height: 204px;
                }

                &__price-info {
                    @include clearfix;
                    margin: 14px 0 6px 0;
                    padding: 0;

                    .c-card-item__rate {
                        margin-right: 8px;
                        font-size: 16px;
                        font-family: $font-family-lato;
                        line-height: 24px;

                        .value {
                            font-size: 24px;
                            vertical-align: baseline;
                        }
                    }

                    .c-card-item__price-special {
                        float: left;
                        width: auto;
                        margin-right: 8px;
                        font-size: 20px;
                        line-height: 20px;
                    }

                    .c-card-item__price {
                        float: left;
                        font-family: $font-family-lato;
                        line-height: 24px;
                        font-size: 16px;

                        .value {
                            margin-bottom: 0;
                            font-size: 24px;
                            vertical-align: baseline;
                        }
                    }

                    .c-card-item__price-del {
                        float: left;
                        margin-left: 8px;
                        color: #a9a9a9;
                        font-family: $font-family-lato;
                        font-size: 13px;
                        line-height: 24px;

                        .value {
                            font-size: 16px;
                            vertical-align: baseline;
                        }
                    }
                }

                &__delivery {
                    float: left;

                    .delivery {
                        overflow: hidden;
                        max-height: 18px;
                        &::before {
                            display: none;
                        }
                    }

                    & + .c-card-item__benefit {
                        position: relative;
                        padding-left: 12px;
                        &:before {
                            position: absolute;
                            left: 4px;
                            top: 9px;
                            width: 2px;
                            height: 2px;
                            border-radius: 50%;
                            background: $color-gray-04;
                            content: "";
                        }
                    }
                }

                &__benefit {
                    margin-top: 0;
                    font-family: $font-family-lato;

                    .benefit {
                        padding-left: 14px;

                        &:before {
                            position: absolute;
                            left: 6px;
                            top: 9px;
                            width: 2px;
                            height: 2px;
                            border-radius: 50%;
                            background: $color-gray-04;
                            content: "";
                        }

                        &:first-of-type {
                            padding-left: 0;

                            &::before {
                                display: none;
                            }
                        }
                    }
                }

                &__extra {
                    position: absolute;
                    height: 50px;
                    bottom: 0;
                    right: 0;
                    width: 605px;
                    padding: 0 30px 0 0;
                    margin: 0;
                    border-top: none;
                    z-index: 2;
                }

                &__qty {
                    bottom: 19px;
                }

                &__counsel {
                    margin-top: -5px;
                    color: $color-11st-blue;
                    font-size: 20px;
                }
            }
        }
    }

    .c-time-count {
        margin-bottom: 42px;
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }
}
