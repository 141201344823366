@charset "UTF-8";

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes drop-motion {
    0% {
        transform: translate(0, 0) rotate(0deg);
    }
    100% {
        transform: translate(0, 15px) rotate(135deg);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    33% {
        opacity: 1;
    }
    45% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes slide-toast {
    0% {
        transform: translate(-50%, 50px); /* 아래에서 시작 */
        opacity: 0;
    }
    15% {
        transform: translateX(-50%); /* 제자리로 이동 */
        opacity: 1;
    }
    85% {
        transform: translateX(-50%); /* 유지 */
        opacity: 1;
    }
    100% {
        transform: translate(-50%, 50px); /* 아래로 내려감 */
        opacity: 0;
    }
}
