@charset "UTF-8";

@mixin c-list-gallery-col-size($itemWideSize, $itemleftGap) {
    ul {
        width: calc(100% + #{$itemleftGap});
        margin-left: calc(#{-$itemleftGap}/ 2);
        padding-top: calc(2rem / 2);
    }

    li {
        //flex-basis: $itemWideSize;
        width: calc(#{$itemWideSize} + #{$itemleftGap});
        padding-top: $itemleftGap;
        padding-right: calc(#{$itemleftGap}/ 2);
        padding-left: calc(#{$itemleftGap}/ 2);
    }
}

@mixin pui-card-list($itemWideSize, $itemleftGap, $colCount) {
    $colSize: $itemWideSize + $itemleftGap;

    .c-list__container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        width: calc(#{$colSize} * #{$colCount});
        max-width: calc(#{$colSize} * #{$colCount});
        margin-left: calc(-#{$itemleftGap}/ 2);
    }

    .c-list__item {
        flex-grow: 0;
        flex-shrink: 1;
        box-sizing: border-box;
        width: $colSize;
        padding-top: $itemleftGap;
        padding-right: calc(#{$itemleftGap}/ 2);
        padding-left: calc(#{$itemleftGap}/ 2);

        &:nth-of-type(n + 1):nth-of-type(-n + #{$colCount}) {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

@mixin pui-card-list-swipe($itemWideSize, $itemleftGap, $colCount) {
    $colSize: $itemWideSize + $itemleftGap;
    position: relative;
    max-width: $pui-width-fixed;
    margin: 0 auto;

    .c-list__container {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        width: calc(#{$colSize} * #{$colCount});
        max-width: calc(#{$colSize} * #{$colCount});
        margin-left: calc(-#{$itemleftGap}/ 2);
    }

    .c-list__item {
        flex-grow: 0;
        flex-shrink: 1;
        box-sizing: border-box;
        width: $colSize;
        padding-top: $itemleftGap;
        padding-right: calc(#{$itemleftGap}/ 2);
        padding-left: calc(#{$itemleftGap}/ 2);

        &:nth-of-type(n + 1):nth-of-type(-n + #{$colCount}) {
            margin-top: 0;
            padding-top: 0;
        }
    }
}

@mixin c-list-listing-set($itemGap) {
    &__item {
        padding: $itemGap;
    }
}

.c-list-gallery {
    max-width: $pui-width-fixed;
    margin: 0 auto;

    //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
    // @include c-list-gallery-col-size(287px, 0.8rem);

    ul {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    &--col4 {
        li {
            flex-grow: 0;
            flex-shrink: 1;
            box-sizing: border-box;

            &:nth-of-type(n + 1):nth-of-type(-n + 4) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}

.c-list-listing {
    //@include c-list-listing-set(24px 0);
}

.c-list-bxslider {
    @include clearfix;
    position: relative;
    z-index: 1;

    ul {
        display: flex;
    }
}

.c-list-swiper {
    position: relative;
    max-width: $pui-width-fixed;
    margin: 0 auto;

    ul {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }

    &--col5 {
        li {
            flex-grow: 0;
            flex-shrink: 1;
            box-sizing: border-box;

            &:nth-of-type(n + 1):nth-of-type(-n + 5) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    &--col4 {
        li {
            flex-grow: 0;
            flex-shrink: 1;
            box-sizing: border-box;

            &:nth-of-type(n + 1):nth-of-type(-n + 4) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    &--col3 {
        li {
            flex-grow: 0;
            flex-shrink: 1;
            box-sizing: border-box;

            &:nth-of-type(n + 1):nth-of-type(-n + 3) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }

    &--col2 {
        li {
            flex-grow: 0;
            flex-shrink: 1;
            box-sizing: border-box;

            &:nth-of-type(n + 1):nth-of-type(-n + 2) {
                margin-top: 0;
                padding-top: 0;
            }
        }
    }
}
