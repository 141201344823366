[data-type="PC_Tab_Keyword"] {
    min-height: 40px;

    .c-chip-choice {
        @include clearfix;
        overflow: hidden;
        margin: 0 0 25px 0;
        height: 40px;
    }

    .c-chip {
        @include text-ellipsis();
        height: 40px;
        max-width: 250px;
        margin: 0 0 0 10px;
        padding: 0 15px;
        border-radius: 28px;
        font-size: 15px;
        line-height: 40px;
        vertical-align: top;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .c_main_tag {
        ul {
            float: left;
        }

        li {
            float: left;

            &.active {
                a {
                    color: $color-11st-red;
                    background: #fefbfb;
                    border-color: #f8dcdd;
                    font-weight: bold;
                }
            }
        }
        a {
            overflow: hidden;
            display: inline-block;
            max-width: 220px;
            height: 40px;
            padding: 0 15px;
            line-height: 40px;
            vertical-align: top;
            border: 1px solid #ddd;
            border-radius: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 15px;
            color: #333;
        }
    }
}
