[data-type="PC_ProductBox_Col5"] {
    width: 1240px;
    margin: 0 auto;

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-list-swiper {
        .swiper-container {
            padding: 10px 25px 25px;
            margin: -25px;

            &.swiper_doing {
                margin-left: 0px;
                padding-right: 3px;
                padding-left: 3px;
            }
        }
        //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
        @include c-list-gallery-col-size(224px, 30px);
    }

    .slider-slide {
        vertical-align: top;
    }

    .c-card-item {
        height: 427px;
        overflow: hidden;

        &:hover {
            .c-card-item__thumb {
                .c-lazyload {
                    img {
                        transform: scale(1.08);
                    }
                }
            }
        }

        &--box {
            padding: 0 0 20px 0;
        }

        &__info {
            @include clearfix;
            padding: 20px 20px 0 20px;
        }

        &__thumb {
            .c-lazyload {
                img {
                    transition: transform 0.2s ease-in;
                }
            }
        }

        &__name {
            max-height: 3em;
            margin: 0 0 8px 0;
            line-height: 22px;
        }

        &__soldout-text {
            left: 10px;
            top: 10px;
            width: 204px;
            height: 204px;
        }

        &__price-info {
            margin: 0 0 13px 0;
            padding: 0;

            .c-card-item__rate {
                margin-right: 8px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
            }

            .c-card-item__price {
                .value {
                    font-size: 22px;
                    line-height: 22px;
                }
            }
        }

        &__delivery {
            .delivery {
                overflow: hidden;
                max-height: 18px;
                &::before {
                    display: none;
                }
            }
        }

        .c-flag-box {
            margin-bottom: 4px;
            max-height: 25px;
        }

        .c-time-flag {
            margin: 0 0 8px 0;
        }

        .c-flag {
            margin: 0 0 8px 0;
        }

        .c-starrate {
            line-height: 18px;
            margin: 0 0 7px 0;

            dd {
                float: left;
            }
            .sr-only {
                @include sr-only;
            }
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }
}
