[data-type="PC_Tab_Meta"] {
    width: 1240px;
    height: 90px;
    margin: 0 auto;

    .c-magnet-multiple--fixed {
        position: fixed;
        right: 0;
        left: 0;
        top: $pui-fixed-top;
        z-index: 100;
        width: 1240px;
        margin: 0 auto;
    }

    .c-list {
        position: relative;
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.06) inset;
        background: #fff;

        &__container {
            display: flex;
        }

        &__item {
            min-width: 103.3px;
            height: 90px;
            box-sizing: border-box;
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }

    .c-icontext {
        width: 100%;
        height: 100%;
        border-radius: 0;
        padding-top: 54px;

        &__hover {
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
        }

        &__icon {
            position: absolute;
            top: 14px;
            width: 40px;
            height: 40px;

            .img-off {
                display: block;
            }
            .img-on {
                display: none;
            }
            img {
                width: 40px;
                height: 40px;
            }
        }

        &__text {
            position: absolute;
            top: 48px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            width: 100%;
            color: $color-gray-04;
            font-size: 14px;
            overflow: hidden;
        }
    }
    %c-icontext {
        .c-icontext__hover {
            display: block;
        }

        .c-icontext__icon {
            .img-off {
                display: none;
            }
            .img-on {
                display: block;
            }
        }

        .c-icontext__text {
            color: $color-gray-14;
            font-weight: 700;
        }
    }

    .c-list__item {
        &.active {
            .c-icontext {
                @extend %c-icontext;
            }
        }

        .c-icontext {
            &:hover,
            &:focus {
                @extend %c-icontext;
            }
        }
    }
}
