.c-n-flag {
    overflow: hidden;
    float: left;
    align-items: center;
    height: 18px;
    padding: 0 6px;
    margin-right: 4px;
    color: $color-gray-03;
    font-size: 12px;
    font-family: $font-family-lato;
    font-weight: 700;
    line-height: 18px;
    background-color: #f5f6f8;
    white-space: nowrap;
    text-align: center;
    vertical-align: top;

    &--fill {
        color: $color-gray-14;
        padding: 0 7px;
        font-weight: 700;
        font-size: 12px;
        background-color: $color-luxury;
        font-family: $font-family;
    }
}
