@import "../../common/helpers/variables";
@import "../../common/mixins";
@import "../sprites/sp_card_item.scss";
@import "../sprites/sp_card_item_2x.scss";
.c-addition {
    position: relative;
    font-size: 12px;
    line-height: 16px;
    color: #666;

    & + & {
        margin-top: -1px;
    }

    > div,
    > ul,
    > dl,
    > a,
    .c-addition-link {
        display: block;
        position: relative;
        height: 20px;
    }

    .c-addition-coupon {
        overflow: hidden;
        position: relative;
        height: 20px;

        &:not(:first-of-type) {
            margin-left: 12px;
        }

        &:nth-child(1):nth-last-child(1) {
            .c-addition-coupon__text {
                max-width: 75%;
            }
        }

        .c-addition-coupon__rate {
            position: relative;
            display: inline-block;
            height: 20px;
            padding: 0 13px 0 5px;
            vertical-align: top;
            font-size: 11px;
            line-height: 20px;
            font-family: $font-family-lato;
            color: $color-grayscale-02;

            em {
                display: inline-block;
                white-space: nowrap;
                vertical-align: top;
                font-weight: 700;

                &:before,
                &:after {
                    @include size(4px 3px);
                    position: absolute;
                    z-index: 1;
                    right: 5px;
                    content: "";
                }
                &:before {
                    top: 0;
                    @include sprite-retina($sp_card_item_2x_coupon_top);
                }
                &:after {
                    bottom: 0;
                    @include sprite-retina($sp_card_item_2x_coupon_bottom);
                }
            }

            &:before {
                @include size(8px 20px);
                position: absolute;
                right: 0;
                top: 0;
                background: $color-11st-gradient-vertical;
                content: "";
            }

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                content: "";
            }
        }

        .c-addition-coupon__text {
            position: relative;
            display: inline-block;
            margin: 0 0 0 4px;
            padding: 0 10px 0 0;
            line-height: 20px;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 23%;

            &:after {
                position: absolute;
                top: 50%;
                right: 1px;
                width: 5px;
                height: 5px;
                margin-top: -3px;
                border: solid #666;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                content: "";
            }
        }

        &:hover {
            text-decoration: none;
        }
    }
}
