[data-type="PC_ProductGrid_Basic"] {
    width: $pui-width-fixed;
    min-height: 292px;
    margin: 0 auto;

    .c-lazyload {
        img {
            animation: none;
        }
    }

    .c-more {
        margin-top: 30px;
    }

    .c-list {
        &--col6 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격

            @include pui-card-list(182px, 30px, 6);
            .c-list__item {
                &:nth-of-type(6n-5) {
                    width: 211px;
                    padding-right: 14px;
                }
                &:nth-of-type(6n) {
                    width: 211px;
                    padding-left: 14px;
                }
            }
        }

        &--col5 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
            @include pui-card-list(224px, 30px, 5);
        }

        &--col4 {
            //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격
            @include pui-card-list(287px, 30px, 4);

            .c-list__container {
                min-width: 1272px;
            }

            .c-list__item {
                &:nth-of-type(4n-3) {
                    width: 318px;
                    padding-right: 16px;
                }
                &:nth-of-type(4n) {
                    width: 318px;
                    padding-left: 16px;
                }
            }
        }
    }

    .slider-slide {
        vertical-align: top;
    }

    @extend %pui-card;

    .c-card-item {
        &__rate {
            margin: 0 4px 0 0;
            font-size: 14px;
            line-height: 22px;

            .value {
                font-size: 14px;
                line-height: 22px;
                margin-bottom: 0;
            }
        }

        &__price {
            clear: both;
            display: block;
        }

        &__price-special {
            & ~ .c-card-item__rate {
                clear: both;
                font-size: 14px;
                line-height: 18px;

                .value {
                    font-size: 22px;
                    line-height: 22px;
                }

                & + .c-card-item__price {
                    display: inline-block;
                }
            }
        }

        &__price-del {
            float: left;
            margin-bottom: 0;
            font-family: $font-family-lato;
            font-size: 14px;
            line-height: 22px;
        }
    }

    .c-card-item.c-card-item--collection {
        .c-card-item__price-info {
            .c-card-item {
                &__rate {
                    margin: 0 4px 0 0;
                    font-size: 14px;
                    line-height: 22px;

                    .value {
                        font-size: 14px;
                        line-height: 22px;
                    }
                }

                &__price {
                    clear: both;
                    display: block;
                }

                &__price-special {
                    & ~ .c-card-item__rate {
                        clear: both;
                        font-size: 14px;
                        line-height: 18px;

                        .value {
                            font-size: 22px;
                            line-height: 22px;
                        }

                        & + .c-card-item__price {
                            display: inline-block;
                        }
                    }
                }

                &__price-del {
                    float: left;
                    margin-bottom: 0;
                    font-family: $font-family-lato;
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }

    .c-pagination-slider {
        &__page {
            @include sr-only();
        }
    }
}

.pui-wrap--category {
    [data-type="PC_ProductGrid_Basic"] {
        width: $pui-width-category;
        max-width: $pui-width-category;

        .c-list {
            &--col4 {
                //각 PUI 블럭에서 셋팅: 아이템너비, 아이템간 상하좌우간격, 컬럼수
                @include pui-card-list(224px, 30px, 4);

                li {
                    &:nth-of-type(4n) {
                        .c-ad-box {
                            .c-ad-layer {
                                left: -50px;
                            }
                        }

                        .c-card-item--collection {
                            .c-ad-box {
                                .c-ad-layer {
                                    left: -70px;
                                }
                            }
                        }
                    }
                }

                .c-list__container {
                    min-width: auto;
                }

                .c-ad-box {
                    top: calc(224px - 16px);
                }

                .c-card-item {
                    width: 224px;
                    height: 100%;
                }

                .c-card-item--collection {
                    .c-ad-box {
                        top: calc(224px - 26px);
                    }
                }
            }
        }
    }
}
