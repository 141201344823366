@charset "UTF-8";

@mixin icon-flag-size($height, $padding, $font-size, $gap) {
    height: $height;

    dd {
        margin-right: $gap;

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.c-flag--full {
        height: $height + $gap;

        dd {
            margin: 0 $gap $gap 0;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .c-flag__item {
        position: relative;
        display: block;
        height: $height;
        padding: $padding;
        border: 0;
        font-size: $font-size;
        line-height: 1.4;
        word-break: break-all;
        box-sizing: border-box;
        overflow: hidden;
        background-color: $color-gray-14;

        &::after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            content: "";
        }
    }
}

@mixin icon-flag() {
    $flag-colors: (
        "ff0038": #ff0038,
        "f43142": #f43142,
        "ff334c": #ff334c,
        "f01b70": #f01b70,
        "f53fc6": #f53fc6,
        "6423ff": #6423ff,
        "0b83e6": #0b83e6,
        "00adf2": #00adf2,
        "249356": #249356,
        "ff8100": #ff8100,
        "b9830b": #b9830b,
        "687581": #687581,
        "426bd1": #426bd1,
        //희망소핑
        "0097a5": #0097a5,
        //즉시할인중
        "00ae6f": #00ae6f,
        //아마존핫딜진행중
        "3617ce": #3617ce,
        //우주패스 only
        "000000": #000000,
        //블랙프라이데이
        "0f0fd9": #0f0fd9,
        //명품개런티
        "5274ff": #5274ff,
        //리퍼블리
        "ff7700": #ff7700,
        //마트플러스
    );

    @each $name-hex, $color in $flag-colors {
        &.c-flag__item--#{$name-hex} {
            border: 1px solid transparent;
            border-color: rgba($color, 0.4);
            color: $color;

            &::after {
                background: rgba($color, 0.04);
            }
        }

        &.c-flag__time--#{$name-hex} {
            color: $color;
        }
    }
}
