@import "../../common/helpers/variables";
@import "../../common/mixins";

.c-more {
    &--round {
        position: relative;
        text-align: center;

        .c-more {
            &__button {
                position: relative;
                display: inline-block;
                height: 56px;
                line-height: 52px;
                padding: 0 49px 0 30px;
                font-size: 17px;
                color: $color-gray-02;
                border: 1px solid #ccc;
                border-radius: 28px;
                background: #fff;

                &:after {
                    position: absolute;
                    top: 21px;
                    right: 30px;
                    width: 6px;
                    height: 6px;
                    border: solid #111;
                    border-width: 1px 1px 0 0;
                    transform: rotate(135deg);
                    content: "";
                }
            }
        }
    }
}
