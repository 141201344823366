[data-type="PC_ProductList_Round_Col4"] {
    height: 110px;
    @include clearfix;

    .c-list {
        ul {
            @include flex-list-gap-container(12px);
        }

        li {
            @include flex-list-gap-item(25%, 12px);
            min-width: 302px;
            box-sizing: border-box;

            &.active,
            &:hover,
            &:focus {
                .c-card-item {
                    border: 1px solid $color-gray-02;

                    .c-card-item__thumb {
                        img {
                            transform: scale(1.08);
                        }
                    }
                }
            }
        }
    }

    .c-card-item {
        border: 1px solid $color-gray-11;
        border-radius: 55px;
        height: 110px;
        padding: 15px;
        box-sizing: border-box;

        > a {
            display: flex;
            width: 100%;
        }

        &__thumb {
            flex-shrink: 0;
            width: 80px;
            height: 80px;
            overflow: hidden;
            border-radius: 50%;
        }

        &__info {
            padding-left: 15px;
            flex-grow: 1;
        }

        &__name {
            width: calc(100% - 30px);
            max-height: 3em;
            line-height: 22px;
        }

        &__counsel {
            margin-top: 8px;

            em {
                font-size: 17px;
                font-weight: 700;
                letter-spacing: 0;
                color: $color-gray-02;
            }
        }

        &__price-info {
            margin-top: 10px;
        }

        &__rate {
            margin-right: 6px;
            font-family: $font-family-lato;
            font-size: 15px;
            line-height: 22px;

            .value {
                font-size: 20px;
                font-weight: 400;
                line-height: 22px;
                vertical-align: baseline;
            }
        }

        &__price {
            font-family: $font-family-lato;
            font-size: 15px;
            line-height: 22px;

            .value {
                font-size: 20px;
                font-weight: 900;
                letter-spacing: 0.5px;
                line-height: 22px;
                vertical-align: baseline;
            }
        }
    }

    .dummy {
        //로딩될때만 사용
        .c-card-item {
            border: 1px solid $color-gray-04;
        }
    }
}
