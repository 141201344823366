@mixin loading-spinner-inner {
    padding: 10px 0;
    text-align: center;

    &:before {
        @include size(60px);
        display: inline-block;
        border: solid 1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        background: #fff url("/img/common/v2/loading_04.gif") no-repeat 50% 50%;
        background-size: 30px 30px;
        color: transparent;
        content: "";
    }
    & > span {
        @include sr-only;
    }
}
