@charset "UTF-8";
@mixin set-c-link-arrow($fontSize, $fontColor, $arrowGap) {
    position: relative;
    display: inline-block;
    padding: $arrowGap;
    color: $fontColor;
    font-weight: normal;
    font-size: $fontSize;

    &:after {
        position: absolute;
        top: 50%;
        right: 2px;
        width: 5px;
        height: 5px;
        margin-top: -3px;
        border: solid $fontColor;
        border-width: 1px 1px 0 0;
        transform: rotate(45deg);
        content: "";
    }
}

.c-link-arrow {
    //@include set-c-link-arrow(13px, $color-gray-03, 0 10px 0 0 ); 사용하는 영역에서 광고표기 타입을 선택
}
