.c-icontext {
    position: relative;
    display: block;
    box-sizing: border-box;

    &__icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        z-index: 2;

        .c-lazyload {
            z-index: 2;
            img {
                z-index: 3;
            }
            &::before {
                z-index: 2;
            }
        }
    }

    &__text {
        display: block;
        margin-top: 13px;
        font-size: 15px;
        color: $color-gray-02;
        text-align: center;
    }

    &--more {
        &::before {
            position: absolute;
            top: 43px;
            left: 50%;
            z-index: 2;
            transform: translateX(-50%);
            @include get-sprite-common("icontext_more", 25);
            display: block;
            content: "";
        }
    }

    &::after {
        position: absolute;
        top: 7px;
        left: 50%;
        display: block;
        transform: translateX(-50%);
        width: 76px;
        height: 76px;
        border-radius: 30px;
        z-index: 1;
        content: "";
    }

    .c-iconnew {
        position: absolute;
        top: 4px;
        right: 0;
        z-index: 3;
    }
}
