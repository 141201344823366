[data-type="PC_Banner_Brand"] {
    min-height: 334px;
    .c-banner-list {
        width: 1240px;
        margin: 0 auto;

        &__container {
            display: flex;
            flex-wrap: wrap;
            //gap: 24px;
            width: calc(100% + 24px);
            margin-left: -12px;
            margin-top: -24px;
        }
        &__item {
            min-height: 310px;
            flex-basis: 292px;
            flex-grow: 1;
            padding: 24px 12px 0;
            box-sizing: border-box;
        }

        &__item:nth-child(1),
        &__item:nth-child(6) {
            flex-basis: 608px;

            .c-banner {
                &__img img {
                    width: 608px;
                    height: 310px;
                }
            }
        }
    }

    .c-list {
        width: 1240px;
        margin: 0 auto;

        &__container {
            display: flex;
            flex-wrap: wrap;
            //gap: 24px;
            width: calc(100% + 24px);
            margin-left: -12px;
            margin-top: -24px;
        }
        &__item {
            min-height: 310px;
            flex-basis: 292px;
            flex-grow: 1;
            padding: 24px 12px 0;
            box-sizing: border-box;
        }

        &__item:nth-child(1),
        &__item:nth-child(6) {
            flex-basis: 608px;

            .c-banner {
                &__img img {
                    width: 608px;
                    height: 310px;
                }
            }
        }
    }

    .c-banner {
        &__img img {
            width: 292px;
            height: 310px;
            border-radius: 4px;
        }
    }
}
