@charset "UTF-8";

@media screen and (min-width:0\0) and (min-resolution: .001dpcm) { 
    //selectbox 콤포넌트  대응
    select.select {
        background: none !important;
        padding-right: 0 !important;
    }
}

