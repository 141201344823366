@charset "UTF-8";

.c-time-count {
    @include clearfix;
    display: inline-block;
    transition: 0.5s; //개발요청

    &__visual {
        .hour,
        .minutes,
        .second,
        .colon {
            float: left;
        }
        .box {
            float: left;
            overflow: hidden;
            width: 20px;
            height: 40px;
        }
        .minutes,
        .hour {
            &:after {
                font-size: 32px;
                font-family: $font-family-number-lato;
                color: $color-11st-red;
                content: ":";
            }
        }
    }

    .number_group {
        display: block;
        width: 20px;

        .number {
            display: block;
            height: 40px;
            font-size: 32px;
            font-family: $font-family-lato;
            font-weight: 900;
            color: $color-11st-red;
            text-align: center;
        }
    }
}
