@charset "UTF-8";

// Font
$font-family-default: "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
$font-family-asis: "Helvetica Neue", "Apple SD Gothic Neo", "돋움", dotum, sans-serif;
$font-family-number: Tahoma, sans-serif;

$font-family: "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
//Lato(font-face 유니코드 범위 안함,bold 900, 700 없음), 레거시 영역의 숫자 영역만 별도로 적용함.
$font-family-number-lato: "Lato", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
//Lato(font-face 유니코드 범위 - 숫자, 영문대소문자, 텍스트의빈공간 및 구분자포함 , bold 700), Noto(한글) (문자 타입별 영역 구분 없이 페이지에 적용함.)
$font-family-lato: "Lato New", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;
//Lato(font-face 유니코드 범위 - 숫자, 영문대소문자, 텍스트의빈공간 , bold 700), Noto(한글) (문자 타입별 영역 구분 없이 페이지에 적용함.)
$font-family-lato-all: "Lato all", "Noto Sans KR", "Helvetica Neue", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "돋움", dotum, sans-serif;

// Color - Brand & Secondary
$color-11st-red: #ff0038;
$color-11st-blue: #0b83e6;
$color-11st-yellow: #f8b907;
$color-secondary-11st-yellow: #ffa700;
$color-11st-bluegray: #6d96c0;
$color-11st-green: #249356;
$color-luxury: #0f0fd9;
$color-amazon-bluegreen: #49a3c7;
$color-11st-gradient-orange: #ff5a2e;
$color-11st-gradient-pink: #ff00ef;
$color-11st-gradient: linear-gradient(to right, $color-11st-gradient-orange, $color-11st-red 52%, $color-11st-gradient-pink);
$color-11st-gradient-vertical: linear-gradient($color-11st-gradient-orange, $color-11st-red 52%, $color-11st-gradient-pink);
$color-amazon-gradient: linear-gradient(to right, #3c99ea, #22cab0);
$color-simpletable: #7449ca;
//Membership
$color-sktpass-01: #3617ce;
//sns
$color-naver: #03c75b;
$color-kakao: #fae100;
// Color of oneday
$color-homeplus: #e11b22;
$color-gsfresh: #32c62d;
$color-gsdawn: #32c62d;
$color-emart: #ffb100;
$color-ssg: #7741bc;
$color-hellonature: #005d5e;
$color-lottemart: #ec1f2f;
$color-mart: #ff7700;
$color-shooting-freeze: #0b99ff; // 슈팅냉동
$color-ssg-emart: #ffc300; // 쓱배송
$color-ssg-dawn: #8da3bc; // 새벽배송
// Color - Grayscale
$color-grayscale-01: #000;
$color-grayscale-02: #111;
$color-grayscale-03: #333;
$color-grayscale-04: #666;
$color-grayscale-04_2: #777;
$color-grayscale-05_1: #949494;
$color-grayscale-05: #999;
$color-grayscale-06: #bbb;
$color-grayscale-07: #ccc;
$color-grayscale-08: #ddd;
$color-grayscale-09: #eee;
$color-grayscale-10: #f4f4f4;
$color-grayscale-11: #e3e3e8;
$color-grayscale-12: #fafafa;
$color-grayscale-13: #fff;

// Color - New Grayscale (2022.11)
$color-gray-01: #000;
$color-gray-02: #111;
$color-gray-03: #333;
$color-gray-04: #666;
$color-gray-05: #777;
$color-gray-06: #949494;
$color-gray-07: #999;
$color-gray-08: #bbb;
$color-gray-09: #ccc;
$color-gray-10: #ddd;
$color-gray-11: #eee;
$color-gray-12: #f4f4f4;
$color-gray-13: #fafafa;
$color-gray-14: #fff;

$pui-width-fixed: 1240px;
$pui-width-category: 986px;
$pui-fixed-top: 69px;
