//사용 페이지 확인 후 제거예정
.c_animate_up {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.4s, opacity 0.8s;

    &.loaded {
        transform: translateY(0);
        opacity: 1;
    }
}

.c-motion-scale {
    .swiper-container,
    .swiper-container img {
        transition: opacity 1s, transform 1s;
        opacity: 0;
        transform: scale(1.05);
    }

    &.c-lazyloaded .swiper-container,
    .swiper-container img {
        opacity: 1;
        transform: scale(1.0005);
    }
}

.c-motion-up {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.4s, opacity 0.8s;

    &.c-lazyloaded {
        transform: translateY(0);
        opacity: 1;
    }
}

.c-motion-delay {
    .c-card-item {
        transition-delay: 0.1s;
    }
}

.c-lazyloaded {
    .c-motion-up {
        transform: translateY(0);
        opacity: 1;
    }

    .c-motion-up--more {
        transform: translateY(20px);
        opacity: 0;
    }
}

.c-motion-cart {
    &::before {
        animation: drop-motion 0.3s ease-in;
    }
}

