@mixin loading-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    color: transparent;

    &:before {
        @include size(60px);
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 200;
        margin: -30px 0 0 -30px;
        border: solid 1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
        background: #fff url("/img/common/v2/loading_04.gif") no-repeat 50% 50%;
        background-size: 30px 30px;
        color: transparent;
        content: "";
    }
    & > span {
        @include sr-only;
    }
}
