[data-type="PC_ProductBox_No_Result"] {
    display: table;
    width: 1240px;
    margin: 0 auto;

    .c-empty {
        display: table-cell;
        height: 234px;
        text-align: center;
        vertical-align: middle;

        &__text {
            color: #222;
            font-size: 20px;
            line-height: 29px;
        }
        &__subtext {
            display: block;
            margin-top: 2px;
            color: $color-gray-04;
            font-size: 20px;
            line-height: 29px;
        }
    }

    .l-grid__col {
        display: table;
    }
}

.pui-wrap--category {
    [data-type="PC_ProductBox_No_Result"] {
        width: $pui-width-category;
        max-width: $pui-width-category;
    }
}
